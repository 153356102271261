import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  InputAdornment,
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import TrendingAllCommunities from '../AllCommunities/TrendingAllCommunities';
import { useCommunity } from '../../../hooks/useCommunity';
import { ICommunity } from '../../../models/communities.modal';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { metaConfig } from '../../../utils/constants';
import { SearchIcon1 } from '../../../assets/icons';

const TrendingCommunitiesGird = () => {
  const { getTrendingCommunities } = useCommunity();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [trendingCommunities, setTrendingCommunities] = useState<ICommunity[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      const trending = await getTrendingCommunities();
      // Simulate a delay of 1 second before hiding the loader
      setTimeout(() => {
        setTrendingCommunities(trending?.data || []);
        setShowLoader(false);
      }, 1000);
    }
    fetchData();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const filteredUsers = trendingCommunities.filter(
    (community) =>
      community?.name?.toLowerCase().includes(searchQuery) ||
      community?.location?.toLowerCase().includes(searchQuery)
  );

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.trendingCommunities}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>

      <Stack
        direction={'row'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: -18,
          zIndex: 1,
          borderRadius: '10px',
          ml: 1,
          mr: 1,
          backgroundColor: '#ffffff', // optional: add a background color if needed
          padding: '10px 0', // optional: add padding for better appearance
        }}
      >
        <BootstrapTooltip title="Back" placement="left">
          <KeyboardBackspaceIcon
            sx={{
              color: '#50A1CA',
              cursor: 'pointer',
              ml: 2,
              fontSize: { xs: '20px', md: '40px' },
            }}
            onClick={() => navigate(`/explore-communities`)}
          />
        </BootstrapTooltip>
        <Typography
          sx={{
            fontSize: { xs: '14px', md: '18px' },
            fontWeight: 700,
            color: '#000000',
          }}
        >
          Trending Communities
        </Typography>
        <Box
          sx={{
            width: 500,
            maxWidth: '100%',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <TextField
            label="Search & Join Communities"
            variant="outlined"
            size="small"
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              display: { xs: 'none', md: 'block' },
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon1 sx={{ mt: 1, }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            color: '#B3B3B3',
            mr: 2,
          }}
        >
          {filteredUsers.length} communities
        </Typography>
      </Stack>

      {showLoader && <Loader />}

      {!showLoader && (
        <Grid container spacing={0}>
          {filteredUsers.map((item) => (
            <Grid item key={item._id} xs={6} sm={2} md={2} lg={2} xl={2}>
              <TrendingAllCommunities {...item} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default TrendingCommunitiesGird;
