import { Grid, Paper, Stack, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

export const Settings = () => {
  const [isCustomPayEnabled, setCustomPayEnabled] = useState(false);

  const handleCustomPayToggle = () => {
    setCustomPayEnabled(!isCustomPayEnabled);
  };

  return (
    <>
      <Helmet>
        <title>Settings - Communn - Build & Manage your community</title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
        Subscription effortlessly
        Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
        Subscription effortlessly
        Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
        Subscription effortlessly
        Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
      <Stack>
        <Paper elevation={3} sx={{ padding: '30px', height: '90vh' }}>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 500,
              color: '#3760A9',
              fontFamily: 'Montserrat',
            }}
          >
            Settings
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontWeight: '400',
              mt: 3,
              mb: 2,
            }}
          >
            Customize your community experience by adjusting message, post, and
            member settings.
          </Typography>
          <hr />

          <Grid
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Left side heading for Payment Settings with reduced margin-top */}
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '18px',
                fontWeight: 500,
                color: '#3760A9',
                mt: 1,
              }}
            >
              Payment Settings
            </Typography>

            {/* Right side Toggle Button for Enable Custom Pay */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={isCustomPayEnabled}
                onChange={handleCustomPayToggle}
                color="primary"
              />
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: '400',
                }}
              >
                Enable Custom Pay
              </Typography>
            </div>
          </Grid>
        </Paper>
      </Stack>
    </>
  );
};
