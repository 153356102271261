import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import './Bookings.css';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Grid,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Close, FiberManualRecord } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import GooglesIcon from '../../../assets/icons/svg-icons/googlemeet.svg';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { useAppointments } from '../../../hooks/useAppointments';
import { useSelector } from 'react-redux';
import { IEvent } from '../../../models/appointments.models';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { IpaymentRequest } from '../../../models/paymentRequest.model';
import { UsePaymentRequest } from '../../../hooks/usePaymentRequest';


const Bookings = () => {
    const singleCommunity = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );
    let community_id = singleCommunity?._id;

    const { addPaymentRequestsList, updatePaymentRequestbyId } =
        UsePaymentRequest();

    const { getAllBookings, cancelAppointmentById } = useAppointments();
    const [appointments, setAppointments] = React.useState<IEvent[]>([]);


    const [loader, setLoader] = useState(false);

    const [paymentValues, setPaymentValues] = useState<IpaymentRequest>({
        _id: '',
        user: { _id: '', firstName: '', lastName: '', avatar: '', role: '' },
        frequency: '',
        notify: 'BOTH',
        amount: '',
        status: '',
        description: '',
        createdAt: '',
    });

    const handleInputChange = (field: keyof IpaymentRequest, value: string) => {
        setPaymentValues((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const [isLoading, setIsLoading] = React.useState(false);

    const [currentDate] = useState(() => {
        const today = new Date();
        const year = today.getUTCFullYear();
        const month = String(today.getUTCMonth() + 1).padStart(2, '0');
        const day = String(today.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    });

    const fetchAppointments = async () => {
        setIsLoading(true);
        try {
            const response = await getAllBookings(community_id);
            if (Array.isArray(response)) {
                setAppointments(response);
            } else {
                setAppointments([]);
            }
        } catch (err) {
            console.error('Error fetching live classes:', err);
            setAppointments([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, []);

    const [open, setOpen] = React.useState(false);
    const [openPaymentRequest, setOpenPaymentRequest] = React.useState(false);
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps['maxWidth']>('sm');
    const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);


    const appointmentId = selectedEvent?.extendedProps?._id;
    const availabilityId = selectedEvent?.extendedProps?.availability[0]?._id;
    const timeSlotId = selectedEvent?.extendedProps?.availability[0]?.availableTimes[0]?._id;

    const handleOpen = (event: any) => {
        setSelectedEvent(event);
        setOpen(true);
    };

    const handleCloses = () => {
        setOpen(false);
        setSelectedEvent(null);
    };


    const cancelAppointment = async () => {
        try {
            setLoader(true);
            const response = await cancelAppointmentById(
                appointmentId || '',
                community_id,
                availabilityId || '',
                timeSlotId || ''
            );
            handleCloses();
            fetchAppointments();
            return response;
        } catch (err) {
            console.log(err);
        } finally {
            handleCloses();
            setLoader(false);
        }
    };

    const handleSubmit = async () => {
        const payload = {
            amount: paymentValues.amount,
            description: paymentValues.description,
            users: [selectedEvent?.extendedProps?.attendees[0].attendeeId?._id],
            frequency: 'ONE_TIME',
            notify: 'BOTH',
        };

        if (community_id) {
            const response = await addPaymentRequestsList(community_id, payload);
            if (response.message === 'Payment Requested successfully!') {
                //   await refetchData();
                handleCloseSendPaymentRequest();
            }
        }
    };

    // const mappedEvents = appointments?.flatMap((appointment) =>
    //     appointment.availability.flatMap((availability) =>
    //         availability.availableTimes.map((timeSlot) => ({
    //             title: appointment.title,
    //             start: `${availability.day}T${timeSlot.startTime}`,
    //             end: `${availability.day}T${timeSlot.endTime}`,
    //             description: appointment.description,
    //             id: timeSlot._id,
    //             location: appointment.location,
    //             attendees:
    //                 timeSlot.attendees.length > 0
    //                     ? [
    //                         {
    //                             email: timeSlot.attendees[0].email,
    //                             attendeeId: {
    //                                 _id: timeSlot.attendees[0].attendeeId._id,
    //                                 firstName: timeSlot.attendees[0].attendeeId.firstName,
    //                                 lastName: timeSlot.attendees[0].attendeeId.lastName,
    //                                 phoneNumber: timeSlot.attendees[0].attendeeId.phoneNumber,
    //                                 emailId: timeSlot.attendees[0].attendeeId.emailId,
    //                                 userName: timeSlot.attendees[0].attendeeId.userName,
    //                             },
    //                             _id: timeSlot.attendees[0]._id,
    //                         },
    //                     ]
    //                     : [],
    //             meetingLink: timeSlot.meetingLink,
    //             pricing: appointment.pricing,
    //             createdBy: {
    //                 name: appointment.createdBy.firstName,
    //                 emailId: appointment.createdBy.emailId,
    //                 phoneNumber: appointment.createdBy.phoneNumber,
    //             },
    //         }))
    //     )
    // );


    const mappedEvents = appointments?.flatMap((appointment) =>
        appointment.availability.flatMap((availability) =>
            availability.availableTimes.map((timeSlot) => {
                const formDetail = appointment.form.find(
                    (form: any) => form.timeSlotId === timeSlot._id
                );

                return {
                    _id: appointment._id,
                    title: appointment.title,
                    start: `${availability.day}T${timeSlot.startTime}`,
                    end: `${availability.day}T${timeSlot.endTime}`,
                    description: appointment.description,
                    id: timeSlot._id,
                    location: appointment.location,
                    attendees: timeSlot.attendees,
                    availability: appointment.availability,
                    meetingLink: timeSlot.meetingLink,
                    pricing: appointment.pricing,
                    createdBy: {
                        name: appointment.createdBy.firstName,
                        emailId: appointment.createdBy.emailId,
                        phoneNumber: appointment.createdBy.phoneNumber,
                    },
                    formDetails: formDetail || {},
                };
            })
        )
    );

    const handleOpenSendPaymentRequest = () => {
        setOpenPaymentRequest(true);
    };

    const handleCloseSendPaymentRequest = () => {
        setOpenPaymentRequest(false);
    };

    const convertTo12HourFormat = (time24: string): string => {
        const [hours, minutes] = time24.split(':').map(Number);
        const period = hours > 12 ? 'pm' : 'am';
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
    };

    return (
        <>
            <Helmet>
                <title>{metaConfig.title.appointments}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>

            <Box sx={{ width: '100%', height: '100%', pb: { xs: 5, md: 0 } }}>
                <FullCalendar
                    plugins={[
                        dayGridPlugin,
                        interactionPlugin,
                        timeGridPlugin,
                        listPlugin,
                    ]}
                    initialView="dayGridMonth"
                    events={mappedEvents}
                    height="auto"
                    headerToolbar={{
                        left: 'prev,next',
                        center: 'title',
                        right: 'dayGridMonth',
                    }}
                    buttonText={{
                        month: 'Calendar',
                        list: 'List',
                    }}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    eventClick={(info) => {
                        handleOpen(info.event);
                        info.jsEvent.preventDefault();

                    }}
                    eventContent={(eventInfo) => (
                        <>
                            <Stack sx={{ display: { xs: 'none', md: 'block' } }}>
                                <p
                                    style={{
                                        padding: '5px',
                                        borderRadius: '15px',
                                        fontSize: '11px',
                                        color: 'white',
                                        fontFamily: 'Montserrat',
                                    }}
                                >
                                    {eventInfo?.event?.title}
                                    <br></br>
                                    {eventInfo?.event?.start && (
                                        <>

                                            {/* {convertTo12HourFormat(
                                                eventInfo.event.start.toLocaleTimeString()
                                            )} */}

                                            {eventInfo.event.start.toLocaleTimeString()}
                                        </>
                                    )}
                                </p>
                            </Stack>
                            <Stack
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    display: { xs: 'flex', md: 'none' }, // Show only on xs screens and hide on md and above
                                    height: '100%', // Ensure the Stack takes full height of its parent
                                    width: '100%',  // Ensure the Stack takes full width of its parent
                                }}
                            >
                                <FiberManualRecord sx={{ fontSize: '12px' }} />
                            </Stack>

                        </>
                    )}
                    initialDate={currentDate}
                    weekends={true}
                    weekNumbers={false}
                    firstDay={0}
                    showNonCurrentDates={true}
                    navLinks={true}
                />

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleCloses}
                    sx={{
                        '& .MuiDialog-paper': {
                            width: '500px',
                            // maxWidth: '80%',
                        },
                    }}
                >
                    <DialogActions>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ backgroundColor: '#ffffff' }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    textDecoration: 'none',
                                    color: '#50A1CA',
                                    textTransform: 'uppercase',
                                    marginLeft: 2,
                                }}
                            ></Typography>
                            <Box
                                onClick={handleCloses}
                                sx={{
                                    backgroundColor: '#3C5AA0',
                                    padding: '4px',
                                    borderRadius: '50%',
                                    width: '20px',
                                    height: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    fontSize: '10px',
                                    mt: '0.5px',
                                    mr: '10px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#50A1CA',
                                        cursor: 'pointer',
                                        border: 'none',
                                    },
                                }}
                            >
                                <Close sx={{ fontSize: '15px' }} />
                            </Box>
                        </Grid>
                    </DialogActions>

                    <DialogContent sx={{ px: 2 }}>
                        <DialogContentText>
                            <Stack spacing={1.5}>
                                {/* Participant Name */}
                                <Stack direction="column" gap={1}>
                                    <Typography
                                        sx={{
                                            fontSize: '15px',
                                            fontWeight: '600',
                                            fontFamily: 'Montserrat',
                                        }}
                                    >
                                        {selectedEvent?.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            fontFamily: 'Montserrat',
                                        }}
                                    >
                                        {selectedEvent?.extendedProps?.attendees
                                            ?.map((attendee) => attendee?.attendeeId?.emailId)
                                            .join(', ')}
                                    </Typography>
                                </Stack>

                                {/* Event Date & Time */}
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <CalendarMonthOutlinedIcon
                                        sx={{ fontSize: '20px', color: 'gray' }}
                                    />
                                    <>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                fontFamily: 'Montserrat',
                                            }}
                                        >
                                            {selectedEvent?.start.toString()}
                                        </Typography>
                                    </>
                                </Stack>

                                {/* Event Price */}
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <CurrencyRupeeOutlinedIcon
                                        sx={{ fontSize: '20px', color: 'gray' }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            fontFamily: 'Montserrat',
                                        }}
                                    >
                                        {selectedEvent?.extendedProps?.pricing || 'Free'}
                                    </Typography>
                                </Stack>

                                {/* Event Host */}
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Person2OutlinedIcon
                                        sx={{ fontSize: '20px', color: 'gray' }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            fontFamily: 'Montserrat',
                                        }}
                                    >
                                        With{' '}
                                        {selectedEvent?.extendedProps?.attendees
                                            ?.map((attendee) => attendee?.attendeeId?.firstName)
                                            .join(', ')}
                                    </Typography>
                                </Stack>

                                {/* Contact Information */}
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <PhoneOutlinedIcon sx={{ fontSize: '20px', color: 'gray' }} />
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            fontFamily: 'Montserrat',
                                        }}
                                    >
                                        {selectedEvent?.extendedProps?.attendees
                                            ?.map((attendee) => attendee?.attendeeId?.phoneNumber)
                                            .join(', ')}
                                    </Typography>
                                </Stack>

                                <Stack direction='row' alignItems='center' gap={1}>
                                    {selectedEvent?.extendedProps?.location && selectedEvent?.extendedProps?.location !== "ONLINE" ? (
                                        <>
                                            <PinDropOutlinedIcon />
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    fontFamily: 'Montserrat',
                                                }}
                                            >

                                                {selectedEvent?.extendedProps?.location}
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Box
                                                component={'img'}
                                                src={GooglesIcon}
                                                width={'18px'}
                                                height={'18px'}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    fontFamily: 'Montserrat',
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                    color: '#3C5AA0'
                                                }}
                                                onClick={() => {
                                                    const meetingLink =
                                                        selectedEvent?.extendedProps?.meetingLink;
                                                    if (meetingLink) {
                                                        window.open(meetingLink, '_blank');
                                                    }
                                                }}
                                            >
                                                Join the conference on Google Meet
                                            </Typography>
                                        </>
                                    )}
                                </Stack>

                                <Stack>
                                    <Stack>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontFamily: 'Montserrat',
                                                color: '#000000',
                                            }}
                                        >
                                            Phone
                                        </Typography>
                                        <TextField
                                            value={
                                                selectedEvent?.extendedProps?.formDetails
                                                    ?.phoneNumber || ''
                                            }
                                            size="small"
                                            disabled
                                            sx={{
                                                backgroundColor: '#F9F9F9',
                                                fontFamily: 'Montserrat',
                                                mt: 1,
                                            }}
                                            InputProps={{
                                                sx: {
                                                    fontSize: '12px',
                                                    color: '#646464',
                                                    textAlign: 'center',
                                                    fontFamily: 'Montserrat',
                                                },
                                            }}
                                        />
                                    </Stack>
                                    <Stack sx={{ mt: 1 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                fontFamily: 'Montserrat',
                                                color: '#000000',

                                            }}
                                        >
                                            Email ID
                                        </Typography>
                                        <TextField
                                            value={
                                                selectedEvent?.extendedProps?.formDetails
                                                    ?.email || ''
                                            }
                                            size="small"
                                            disabled
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                backgroundColor: '#F9F9F9',
                                                mt: 1,
                                            }}
                                            InputProps={{
                                                sx: {
                                                    fontSize: '12px',
                                                    color: '#646464',
                                                    textAlign: 'center',
                                                    fontFamily: 'Montserrat',
                                                },
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </DialogContentText>
                    </DialogContent>
                    <Stack direction="row" spacing={1} gap={2} mx={2}>
                        <Button
                            fullWidth
                            size="large"
                            variant="outlined"
                            sx={{
                                textTransform: 'capitalize',
                                border: '1px solid #E7EBF1',
                                borderRadius: '8px',
                                boxShadow: 'none',
                                color: '#000000',
                                backgroundColor: '#ffffff',
                                fontFamily: 'Montserrat',
                            }}
                            onClick={cancelAppointment}
                        >
                            {loader ? (
                                <CircularProgress size={20} sx={{ color: '#3C5AA0' }} />
                            ) : (
                                'Cancel '
                            )}
                        </Button>

                        <Button
                            disabled
                            fullWidth
                            size="large"
                            variant="contained"
                            sx={{
                                backgroundColor: '#3C5AA0',
                                textTransform: 'capitalize',
                                border: 'none',
                                borderRadius: '8px',
                                boxShadow: 'none',
                                color: 'white',
                                fontFamily: 'Montserrat',
                                '&:hover': {
                                    backgroundColor: '#3C5AA0',
                                    color: 'white',
                                    border: 'none',
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            Reschedule
                        </Button>
                    </Stack>
                    <Stack
                        sx={{ p: 2, cursor: 'pointer' }}
                        component={'a'}
                        onClick={handleOpenSendPaymentRequest}
                    >
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '500',
                                fontFamily: 'Montserrat',
                                textAlign: 'center',
                                textDecoration: 'underline',
                                color: '#3C5AA0',
                            }}
                        >
                            Send Payment Request
                        </Typography>
                    </Stack>
                </Dialog>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={openPaymentRequest}
                    onClose={handleCloseSendPaymentRequest}
                    sx={{
                        '& .MuiDialog-paper': {
                            width: '450px',
                            // maxWidth: '80%',
                        },
                    }}
                >
                    <DialogTitle sx={{ padding: '0px 5px 10px 0px' }}>
                        <Grid container sx={{ backgroundColor: '#F0F9FF', p: '4px' }} alignItems={'center'}>
                            <Grid item xs={6} md={6} lg={6} xl={6} sm={6}>
                                <Stack
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'flex-start'}
                                    direction={'row'}
                                >
                                    <Typography
                                        sx={{
                                            color: '#3C5AA0',
                                            fontSize: { xs: '12px', md: '16px' },
                                            fontWeight: 500,
                                            fontFamily: 'Montserrat',
                                            ml: 1
                                        }}
                                    >
                                        {' '}
                                        Send Payment Request
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={6} md={6} lg={6} xl={6} sm={6}>
                                <Stack display={'flex'} alignItems={'end'}>
                                    <Box
                                        onClick={handleCloseSendPaymentRequest}
                                        sx={{
                                            backgroundColor: '#3C5AA0',
                                            borderRadius: '50%',
                                            width: '20px',
                                            height: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'white',
                                            fontSize: '10px',
                                            mt: '0.5px',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                border: 'none',
                                            },
                                        }}
                                    >
                                        <Close sx={{ fontSize: '15px' }} />
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </DialogTitle>

                    <DialogContent>
                        <Grid container>
                            <Grid container>
                                <Grid
                                    item
                                    xs={3.5}
                                    md={4}
                                    lg={4}
                                    xl={3.5}
                                    sm={6}
                                    display={'flex'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            color: 'gray',
                                            fontSize: { xs: '14px', md: '17px' },
                                            fontWeight: '500',
                                            fontFamily: 'Montserrat',
                                        }}
                                    >
                                        Amount:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8.5} md={8} lg={8} xl={8.5} sm={6}>
                                    <TextField
                                        type="number"
                                        placeholder="Min 1 rupee required"
                                        size="small"
                                        fullWidth
                                        value={paymentValues.amount}
                                        onChange={(e) =>
                                            handleInputChange('amount', e.target.value)
                                        }
                                        InputProps={{
                                            inputProps: {
                                                min: 1,
                                                onInput: (e: any) => {
                                                    e.target.value = e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    );
                                                },
                                            },
                                            sx: {
                                                fontSize: { xs: '13px', md: '17px', fontFamily: 'Montserrat', },
                                            },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CurrencyRupeeIcon
                                                        sx={{ fontSize: { xs: '15px', md: '20px' } }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            sx: {
                                                '&::placeholder': {
                                                    fontSize: { xs: '13px', md: '16px' },
                                                    fontFamily: 'Montserrat',
                                                },
                                            },
                                        }}
                                        sx={{
                                            '& input::placeholder': {
                                                fontSize: { xs: '13px', md: '16px' },
                                                fontFamily: 'Montserrat',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item sx={{ mt: 2 }} xs={12} md={12} lg={12} xl={12}>
                                <TextField
                                    label="Reason for the Pay"
                                    multiline
                                    rows={4}

                                    size="small"
                                    fullWidth
                                    value={paymentValues.description}
                                    onChange={(e) =>
                                        handleInputChange('description', e.target.value)
                                    }
                                    InputProps={{
                                        sx: {
                                            fontSize: { xs: '13px', md: '17px', fontFamily: 'Montserrat', },
                                        },

                                    }}
                                    sx={{
                                        fontFamily: 'Montserrat',
                                        '& input::placeholder': {
                                            fontSize: { xs: '13px', md: '16px' },
                                            fontFamily: 'Montserrat',
                                        },
                                    }}

                                />
                                <Button
                                    onClick={handleSubmit}
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    sx={{
                                        mt: 2,
                                        textTransform: 'capitalize',
                                        backgroundColor: '#3C5AA0',
                                        border: 'none',
                                        borderRadius: '8px',
                                        boxShadow: 'none',
                                        color: 'white',
                                        fontFamily: 'Montserrat',
                                        '&:hover': {
                                            backgroundColor: '#3C5AA0',
                                            color: 'white',
                                            border: 'none',
                                            boxShadow: 'none',
                                        },
                                    }}
                                >
                                    Send Request
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Box>
        </>
    );
};
export default Bookings;
