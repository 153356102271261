import { Card, CardMedia, Paper, Stack, Typography } from '@mui/material';

interface IAlternative {
  image?: string;
  title?: string;
}
export const AlternativePage: React.FC<IAlternative> = ({ image, title }) => {
  return (
    <>
      <Paper elevation={0} sx={{}}>
        <Stack
          spacing={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ paddingBottom: '10px', p: 2, height: '73vh' }}
        >
          <Card elevation={0}>
            <CardMedia
              component="img"
              alt="No posts"
              image={image}
              sx={{
                objectFit: 'cover',
                width: '100%',
                height: '30vh',
              }}
            />
          </Card>
          <Typography
            sx={{
              color: '#777777',
              fontSize: { xs: '13px', md: '15px' },
              fontWeight: '500',
              fontFamily: 'Montserrat',
            }}
          >
            {title}
          </Typography>
        </Stack>
      </Paper>
    </>
  );
};
