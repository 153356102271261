
import Loader from '../Loader/Loader'

const NoAuthEmptyScreen = () => {
    return (
        <>

            <Loader />
        </>
    )
}

export default NoAuthEmptyScreen
