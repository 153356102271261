import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { useSubscription } from '../../../hooks/useSubscription';
import { useNavigate, useParams } from 'react-router-dom';
import { ISubscribers } from '../../../models/subscription.model';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import { getStaticValue } from '../../../utils/StaticValues';
import { Close } from '@mui/icons-material';
import BreadCrumbs from '../../AlternatePages/BreadCrumbs';
import Loader from '../../Loader/Loader';
import { SubNavbar } from '../../Layout/components/SubNavbar';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

enum Status {
  NOT_PAID = 'NOT_PAID',
  CANCELLED = 'CANCELLED',
  PAID_BY_CASH = 'PAID_BY_CASH',
  NA = 'NA'
}

interface Sequence {
  _id: string;
  startDate: string;
}



const SubscriptionNotify = () => {


  const navigate = useNavigate();


  const getBorderColor = (option: any) => {
    switch (option) {
      case 'PAID':
        return '#7CB92E';
      case 'PAID_BY_CASH':
        return '#7CB92E';
      case 'NOT_PAID':
        return '#3C5AA0';
      case 'NA':
        return '#3C5AA0';
      case 'EXPIRED':
        return '#DC8A8A33';
      case 'CANCELLED':
        return '#F4433633';
      default:
        return '#3C5AA0';
    }
  };

  const getBackgroundColor = (option: any) => {
    switch (option) {
      case 'PAID':
        return '#7CB92E33';
      case 'PAID_BY_CASH':
        return '#7CB92E33';
      case 'NOT_PAID':
        return '#EEF7FD';
      case 'NA':
        return '#EEF7FD';
      case 'EXPIRED':
        return '#DC8A8A33';
      case 'CANCELLED':
        return '#F4433633';
      default:
        return '#3C5AA0';
    }
  };


  const getTextColor = (option: any) => {
    switch (option) {
      case 'PAID':
        return '#7CB92E';
      case 'PAID_BY_CASH':
        return '#7CB92E';
      case 'NOT_PAID':
        return '#000000';
      case 'NA':
        return '#000000';
      case 'EXPIRED':
        return '#DC8A8A';
      case 'CANCELLED':
        return '#F44336';
      default:
        return '#3C5AA0';
    }
  };

  const getStatusMessage = (status: string) => {
    switch (status) {
      case 'PAID_BY_CASH':
        return 'Are you sure the customer has made the payment via cash or any other mode?';
      case 'NOT_PAID':
        return 'Are you sure you want to mark this as Not Paid?';
      case 'NA':
        return 'Are you sure you want to mark this as NA?';
      case 'CANCELLED':
        return 'Are you sure you want to cancel the payment?';
      case 'EXPIRED':
        return 'Are you sure you want to mark this as Expired?';
      default:
        return 'Are you sure you want to change the status?';
    }
  };


  const getStatusEnumValue = (status: string): Status => {
    switch (status) {
      case 'CANCELLED':
        return Status.CANCELLED;
      case 'PAID_BY_CASH':
        return Status.PAID_BY_CASH;
      case 'NA':
        return Status.NA;

      default:
        return Status.NOT_PAID;
    }
  };

  const statuses = [Status.CANCELLED, Status.PAID_BY_CASH, Status.NA];

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [subscriptionList, setSubscriptionList] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedSequenceId, setSelectedSequenceId] = useState('');
  const [selectedSequence, setSelectedSequence] = useState<Sequence | null>(null);
  const [fullWidth] = useState(true);
  const [maxPopWidth] = useState<DialogProps['maxWidth']>('xs');
  const [loader, setLoader] = useState(false)

  const handleClick = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };

  const { subscriptionListByCommunityId, changePaymentStatusBySequenceId } = useSubscription();
  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );

  const { id } = useParams();
  // console.log(id, "id");

  const loadSubscription = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const subscription =
          await subscriptionListByCommunityId(selectedCommunityId);
        // console.log(subscription, "subscription");
        if (subscription) {
          setIsLoading(false);
          setSubscriptionList(subscription);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSubscription();
  }, [selectedCommunityId]);

  const [loadingStatus, setLoadingStatus] = useState(false);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const mobileViewHeight = 350;
  const desktopViewHeight = 400;

  const height = isMobileView ? mobileViewHeight : desktopViewHeight;

  const filteredSubscription = subscriptionList.filter(
    (subscription: ISubscribers) => String(subscription._id) === String(id)
  );
  const [subscriptions, setSubscriptions] = useState<ISubscribers[]>([]);

  useEffect(() => {
    if (subscriptionList && subscriptionList.length > 0) {
      const filteredSubscription = subscriptionList.filter(
        (subscription: ISubscribers) => String(subscription._id) === String(id)
      );
      if (filteredSubscription && filteredSubscription.length > 0) {
        setSubscriptions(filteredSubscription);
      } else {
        console.warn(
          'No matching subscription found or subscriptionList is empty.'
        );
      }
    }
  }, [subscriptionList, id]);

  const handleSelect = (status: string, sequence: Sequence) => {
    setSelectedStatus(status);
    setSelectedSequence(sequence);
    setSelectedSequenceId(sequence._id);
    setOpenDialog(true);
  };

  const handleConfirm = async () => {
    try {
      setLoader(true)
      const enumStatus = getStatusEnumValue(selectedStatus);
      const updatedSubscription = await changePaymentStatusBySequenceId(
        selectedSequenceId,
        enumStatus
      );
      if (updatedSubscription) {
        setSubscriptions((prevSubscriptions) => {
          return prevSubscriptions.map((subscription) => ({
            ...subscription,
            sequences: subscription.sequences.map((sequence) =>
              sequence._id === selectedSequenceId
                ? { ...sequence, status: updatedSubscription.data.status }
                : sequence
            ),
          }));
        });
        // loadSubscription();
        setOpenDialog(false);
        setLoadingStatus(true);
      }
    } catch (error) {
      console.error('Error changing payment status:', error);
    } finally {
      setLoadingStatus(false);
      setLoader(false)
      handleClose();
    }
  };

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.subscriberList}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <SubNavbar>
        <Stack sx={{ pb: 1 }}>
          <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'flex' }, }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
            <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => {
              navigate('/subscribers');
            }} />
            <Typography
              sx={{
                fontSize: { xs: '16px', md: '18px' },
                fontWeight: 600,
                color: '#000000',
                fontFamily: 'Montserrat',
              }}
            >
              Sequences
            </Typography>
          </Stack>
        </Stack>
      </SubNavbar>

      {/* {isLoading &&
        <Paper elevation={0}>
          <Loader />
        </Paper>
      } */}
      {!isLoading && filteredSubscription?.length > 0
        ? filteredSubscription.map((subscription: any) => (
          <Paper
            key={subscription._id}
            sx={{ p: { xs: 3, md: 3 }, mb: 2, boxShadow: 'none', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              gap={5}
            >
              <Stack
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
                gap={2}
              >
                <Avatar
                  src={subscription?.user?.avatar}
                  sx={{
                    height: '50px',
                    width: '50px',
                    fontSize: '11px',
                  }}
                  alt={`${subscription?.user?.firstName}`}
                />
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {'  '}
                  <Typography
                    component={'span'}
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {' '}
                    {subscription?.user?.firstName}
                  </Typography>
                  <br></br>
                  {subscription?.user?.emailId}
                  <br></br>
                  {subscription?.user?.phoneNumber}
                </Typography>
              </Stack>
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                      pt: { xs: 1, md: 0 },
                      textTransform: 'capitalize',
                    }}
                  >
                    Plan name : {subscription?.plan?.name}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                  gap={3}
                  mt={2}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: '15px', md: '20px' },
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                      color: '#3C5AA0',
                    }}
                  >
                    ₹
                    {subscription?.plan?.offerValue ||
                      subscription?.plan?.totalPlanValue}
                    /{subscription?.plan?.interval}
                    {subscription?.plan?.interval > '1'
                      ? `${getStaticValue(subscription?.plan?.duration)}s`
                      : getStaticValue(subscription?.plan?.duration)}
                  </Typography>
                  {subscription.active ? (
                    <Chip
                      icon={
                        <FiberManualRecordIcon
                          sx={{ color: '#7CB92E', fontSize: '15px' }}
                        />
                      }
                      label="Active"
                      sx={{
                        color: '#515151',
                        backgroundColor: '#7CB92E33',
                        '& .MuiChip-icon': {
                          color: '#7CB92E',
                        },
                      }}
                    />
                  ) : (
                    <Chip
                      icon={
                        <FiberManualRecordIcon
                          sx={{ color: '#7CB92E', fontSize: '15px' }}
                        />
                      }
                      label={
                        subscription?.subscription_status === 'ACTIVE'
                          ? 'Active'
                          : 'Inactive'
                      }
                      sx={{
                        color: '#515151',
                        backgroundColor:
                          subscription?.subscription_status === 'ACTIVE'
                            ? '#7CB92E33'
                            : '#ffa87d1a',
                        '& .MuiChip-icon': {
                          color:
                            subscription?.subscription_status === 'ACTIVE'
                              ? '#7CB92E'
                              : '#ffa87d',
                        },
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        ))
        :
        !isLoading && <Typography>No Subscriptions Found</Typography>}
      <Paper
        sx={{
          p: { xs: 1, md: 5 },
          mt: 2,
          height: { xs: '45vh', md: '65vh' },
          boxShadow: 'none',
          borderRadius: '12px !important', border: '1px solid #E7EBF1',
        }}
      >
        {isLoading ? (
          <Stack
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader />
          </Stack>
        ) : (
          <>
            {filteredSubscription.map((subscription: any) => (
              <Stack display={'flex'} flexDirection={'row'}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: { xs: '15px', md: '18px' },
                      fontWeight: '500',
                      fontFamily: 'Montserrat',
                      color: '#3C5AA0',
                      pt: { xs: 1, md: 0 },
                    }}
                  >
                    Start Date:{' '}
                    {new Date(subscription?.startDate).toLocaleString('en-GB', {
                      timeZone: 'Asia/Kolkata',
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                      hour12: false,
                    })}
                  </Typography>
                </Stack>
                <Stack></Stack>
              </Stack>
            ))}
            <Stack>
              <SimpleBar style={{ height: `${height}px` }}>
                <Grid container spacing={2} sx={{ padding: 2 }}>
                  {!isLoading &&
                    subscriptions.map((subscription: ISubscribers) =>
                      subscription?.sequences.map((sequence, index) => (
                        <Grid
                          item
                          key={sequence._id}
                          xs={4}
                          sm={6}
                          md={2}
                          lg={1.5}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: '10px', md: '12px' },
                              fontWeight: 400,
                              textAlign: 'center',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {new Date(sequence?.startDate).toLocaleString('en-GB', {
                              timeZone: 'Asia/Kolkata',
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit',
                              hour12: false,
                            })}
                          </Typography>

                          <Button
                            variant="outlined"
                            endIcon={<ArrowDropDownIcon />}
                            onClick={(e) => handleClick(e, sequence._id)}
                            disabled={
                              sequence?.status === 'PAID' ||
                              subscription?.subscription_status === 'STOP'
                            }
                            sx={{
                              backgroundColor: `${getBackgroundColor(
                                sequence.status
                              )}`,
                              borderRadius: '30px',
                              overflow: 'hidden',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontFamily: 'Montserrat',
                              color: '#515151',
                              border: `1px solid ${getBorderColor(sequence.status)}`,
                              width: { xs: '100%', md: '90%' },
                              mt: 1,
                            }}
                          >
                            ₹
                            {subscription?.plan?.offerValue ||
                              subscription?.plan?.totalPlanValue}
                          </Button>

                          <Menu
                            anchorEl={anchorEl}
                            open={
                              Boolean(anchorEl) && selectedItemId === sequence._id
                            }
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            PaperProps={{
                              sx: {
                                borderRadius: '15px',
                                backgroundColor: '#E6F0F9',
                                padding: '0px 5px',
                                boxShadow: 'none',
                                mt: 0.5,
                                fontFamily: 'Montserrat',
                              },
                            }}
                          >
                            {statuses
                              .filter((status) => {
                                if (sequence.status === Status.NOT_PAID || sequence.status === Status.CANCELLED || sequence.status === "EXPIRED") {
                                  return status === Status.PAID_BY_CASH || status === Status.NA;
                                } else if (sequence.status === Status.PAID_BY_CASH) {
                                  return status === Status.CANCELLED;
                                } else if (sequence.status === Status.NA) {
                                  return status === Status.PAID_BY_CASH;
                                }

                                return false;
                              })
                              .map((status, index) => (
                                <MenuItem
                                  key={index}
                                  onClick={() => handleSelect(status, sequence)}
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    padding: '3px 20px',
                                    color: '#000000',
                                    minHeight: '20px',
                                    fontFamily: 'Montserrat',
                                    '&:hover': {
                                      backgroundColor: '#D8EAF4',
                                      borderRadius: '5px',
                                    },
                                  }}
                                >
                                  {getStaticValue(status)}
                                </MenuItem>
                              ))}

                          </Menu>

                          <Typography
                            sx={{
                              fontSize: { xs: '10px', md: '12px' },
                              color: `${getTextColor(sequence?.status)}`,
                              fontWeight: 400,
                              mt: 1,
                              textAlign: 'center',
                              fontFamily: 'Montserrat',
                            }}
                          >
                            {getStaticValue(sequence?.status)}
                          </Typography>

                          {/* {sequence?.status === "CANCELLED" ? (
                            <>
                              <Typography
                                sx={{
                                  fontSize: { xs: '10px', md: '12px' },
                                  color: `#575757`,
                                  fontWeight: 400,
                                  // mt: 1,
                                  textAlign: 'center',
                                }}
                              >
                                🕗 Previously  : {getStaticValue(sequence?.previousStatus)}
                              </Typography>
                            </>
                          ) : (
                            <>

                              <Stack sx={{ mt: { xs: 3.5, md: 4.5 } }}>
                              </Stack>
                            </>
                          )
                          } */}
                        </Grid>
                      ))
                    )}
                </Grid>
              </SimpleBar>
            </Stack>
            <Divider />
          </>
        )}
      </Paper>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxPopWidth}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: '#F0F9FF' }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 500,
                textDecoration: 'none',
                color: '#3C5AA0',

                marginLeft: 2,
              }}
            >
              Confirm Status Change
            </Typography>
            <Box
              onClick={() => setOpenDialog(false)}
              sx={{
                backgroundColor: '#3C5AA0',
                padding: '4px',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '10px',
                mt: '0.5px',
                mr: '10px',
                cursor: 'pointer',
                '&:hover': {
                  cursor: 'pointer',
                  border: 'none',
                },
              }}
            >
              <Close sx={{ fontSize: '15px' }} />
            </Box>
          </Grid>
        </DialogActions>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: '13px' }}
          >
            {getStatusMessage(selectedStatus)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            sx={{
              textTransform: 'capitalize',
              // fontWeight:600,
              color: '#50A1CA',
              border: '1px solid #50A1CA',
              borderRadius: '10px',
              boxShadow: 'none',
              '&:hover': {
                cursor: 'pointer',
                border: '1px solid #50A1CA',
                boxShadow: 'none',
              },
            }}
          >
            No, cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            sx={{
              background:
                'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
              textTransform: 'capitalize',
              border: 'none',
              borderRadius: '10px',
              boxShadow: 'none',
              color: 'white',
              '&:hover': {
                background:
                  'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                cursor: 'pointer',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
              },
            }}
          >
            {loader ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "yes, I'm sure"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SubscriptionNotify;
