import axios from 'axios';
import { BASE_URL_V2 } from '../configurations/url.config';
import { ICreateCourse } from '../models/courses.model';

export const saveCourse = async (
  id: string,
  token: string,
  data: ICreateCourse,
  files: FileList | null,
  documents: FileList | null
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
      'Content-Type': 'multipart/json',
      Accept: '*',
    };

    const formData = new FormData();
    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('coverImage', file);
      }
    }
    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        const file = documents[i];
        formData.append('document', file);
      }
    }
    //const new_data :IAddPlan = data;
    for (const [field, value] of Object.entries(data)) {
      formData.append(field, value);
    }
    const response = await axios.post(
      `${BASE_URL_V2}/communities/${id}/courses`,
      formData,
      {
        headers,
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const fetchCourseByCommunityId = async (token: string, id: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/communities/${id}/courses`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const fetchCourseByCourseId = async (
  token: string,
  communityId: string,
  id: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/communities/${communityId}/courses/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const addCourseSection = async (
  token: string,
  comunityId: string,
  id: string,
  formatData: any
) => {
  try {
    const response = await axios.post(
      `${BASE_URL_V2}/communities/${comunityId}/courses/${id}/sections`,
      formatData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const updateCourseByCourseId = async (
  communityId: string,
  id: string,
  token: string,
  data: ICreateCourse,
  files: FileList | null,
  documents: FileList | null
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
      'Content-Type': 'multipart/json',
      Accept: '*',
    };

    const formData = new FormData();
    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('coverImage', file);
      }
    }
    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        const file = documents[i];
        formData.append('document', file);
      }
    }
    //const new_data :IAddPlan = data;
    for (const [field, value] of Object.entries(data)) {
      formData.append(field, value);
    }
    const response = await axios.put(
      `${BASE_URL_V2}/communities/${communityId}/courses/${id}`,
      formData,
      {
        headers,
      }
    );

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const updateCourseByCourse = async (
  token: string,
  coomunityId: string,
  id: string,
  formatData: any
) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/communities/${coomunityId}/courses/${id}/`,
      formatData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const deleteCourseSections = async (
  token: string,
  community: string,
  courseId: string,
  id: string
) => {
  try {
    const response = await axios.delete(
      `${BASE_URL_V2}/communities/${community}/courses/${courseId}/sections/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response;
    }

    return response;
  } catch (err) {
    console.error('Error:', err);
    return { status: 500, data: [] };
  }
};

export const updateCourseSections = async (
  token: string,
  coomunityId: string,
  courseId: string,
  id: string,
  formatData: any
) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/communities/${coomunityId}/courses/${courseId}/sections/${id}/`,
      formatData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const deleteCourse = async (
  token: string,
  communityId: string,
  courseId: string
) => {
  try {
    const response = await axios.delete(
      `${BASE_URL_V2}/communities/${communityId}/courses/${courseId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const updateDocument = async (
  token: string,
  communityId: string,
  id: string,
  documents: FileList | null
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
      'Content-Type': 'multipart/json',
      Accept: '*',
    };

    const formData = new FormData();

    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        const file = documents[i];
        formData.append('documents', file);
      }
    }
    //const new_data :IAddPlan = data;
    // for (const [field, value] of Object.entries(data)) {
    //   formData.append(field, value);
    // }
    const response = await axios.put(
      `${BASE_URL_V2}/communities/${communityId}/courses/${id}`,
      formData,
      {
        headers,
      }
    );

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const updateDocumentName = async (
  token: string,
  coomunityId: string,
  id: string,
  formatData: any
) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/communities/${coomunityId}/documents/${id}`,
      formatData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const deleteCourseDocumentById = async (
  token: string,
  communityId: string,
  courseId: string,
  docId: string
) => {
  try {
    const response = await axios.delete(
      `${BASE_URL_V2}/communities/${communityId}/courses/${courseId}/documents/${docId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const AddSectionDocument = async (
  token: string,
  communityId: string,
  courseId: string,
  id: string,
  documents: FileList | null
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/json',
      Accept: '*',
    };

    const formData = new FormData();

    if (documents != null) {
      for (let i = 0; i < documents.length; i++) {
        const file = documents[i];
        formData.append('documents', file);
      }
    }
    //const new_data :IAddPlan = data;
    // for (const [field, value] of Object.entries(data)) {
    //   formData.append(field, value);
    // }
    const response = await axios.put(
      `${BASE_URL_V2}/communities/${communityId}/courses/${courseId}/sections/${id}`,
      formData,
      {
        headers,
      }
    );

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
export const deleteSectionDocumentById = async (
  token: string,
  communityId: string,
  secId: string,
  id: string
) => {
  try {
    const response = await axios.delete(
      `${BASE_URL_V2}/communities/${communityId}/sections/${secId}/documents/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
