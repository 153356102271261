import { Box } from "@mui/material"
import NewHeader from "../ExploreCommunities/Layout/NewHeader"
import { Outlet } from "react-router-dom"
import { stylesNew } from "./Layout.styles"




const NewExploreLayout = () => {
    return (
        <>
            <Box>
                <NewHeader />
            </Box>
            <Box sx={stylesNew.contentSection}>
                <Outlet />
            </Box>
        </>
    )
}
export default NewExploreLayout