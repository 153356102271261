import { Typography } from "@mui/material";


interface ITypography {
    name?: string;
    fontFamily?: string;
    fontWeight?: number;
    fontSize?: string;
    lineHeight?: string;
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
}

const TypographyField = (props: ITypography) => {
    const {
        name,
        fontFamily = "Montserrat",
        fontWeight,
        fontSize = { xs: "12px", sm: "14px", md: "26px" },
        lineHeight,
        textAlign,
    } = props;

    return (
        <Typography
            sx={{
                fontFamily,
                fontWeight,
                fontSize,
                lineHeight,
                textAlign,
            }}
        >
            {name}
        </Typography>
    );
};

export default TypographyField;
