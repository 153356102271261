import axios from "axios";
import { BASE_URL_V2 } from "../configurations/url.config";
import { ICreateClass } from "../models/liveClasses.model";





export const generateAuth = async (token: string, communityId: string) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/generate-auth-url/${communityId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log('Class scheduled successfully:', response);
    return response;
  } catch (error) {
    console.error('Error scheduling class:',);
  }
};

export const scheduleClass = async (token: string, classData: ICreateClass) => {
  try {
    const response = await axios.post(
      `${BASE_URL_V2}/classes/schedule-class`,
      classData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response, "response");s
  return response;
  } catch (error) {
    console.error('Error scheduling class:',);
  }
};


export const updateScheduleClass = async (token: string, classData: ICreateClass, classId:string) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/classes/update-class/${classId}`,
      classData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response, "response");
    return response;
  } catch (error) {
    console.error('Error scheduling class:',);
  }
};




export const fetchAllLiveClasses = async (
    token: string,
    communityId: string
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL_V2}/classes/community/${communityId}/all-classes-admin`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    //   console.log(response?.data?.classes, "response");
      return response.data?.classes;
    } catch (err) {
      return { status: 500, data: [] };
    }
  };


  export const fetchAllPastClasses = async (
    token: string,
    communityId: string
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL_V2}/classes/community/${communityId}/past-classes-admin`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    //   console.log(response?.data?.classes, "response");
      return response.data?.pastClasses;
    } catch (err) {
      return { status: 500, data: [] };
    }
  };


  export const fetchClassesById = async (
    token: string,
    communityId: string,
    classId:string
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL_V2}/class/${classId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    //   console.log(response?.data?.classes, "response");
      return response.data?.classes;
    } catch (err) {
      return { status: 500, data: [] };
    }
  };