import { Grid } from '@mui/material';
import HomePost from './HomePost';
import { Homecarousel } from './HomeCarousel';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';


export const Home = () => {


  return (
    <>
      <Helmet>
        <title>{metaConfig.title.home}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>

      <Grid container>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
          <>
            <Homecarousel />

            <HomePost />
          </>
        </Grid>
       
      </Grid>

    </>
  );
};
