import { Box, Stack, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import ExploreCommunity from "./Components/ExploreCommunity"


const NewExplore = () => {
    return (
        <>
            <Box sx={{ mt: 1, }}>
                <Stack sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    pt: 2,
                    pb: 2,
                    m: { xs: 1, md: 2 },
                    borderRadius: '10px',
                    minHeight: '83vh'
                }}>

                    <Typography sx={{ color: '#000000', fontWeight: 600, fontSize: { xs: '24px', md: '40px' } }} > Explore Communities</Typography>
                    <Link to={'/create-community'} style={{ color: '#2952A2', fontWeight: 600 }}>
                        Create Your Own
                    </Link>
                    <ExploreCommunity />
                </Stack>

            </Box >
        </>
    )
}
export default NewExplore