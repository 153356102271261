import { useDispatch, useSelector } from "react-redux";
import store from "../../store";
import { useLiveClasses } from "../../hooks/useLiveClassess";
import { loadSelectedCommunity } from "../../store/slices/CommunitySlice";
import { ICommunity } from "../../models/communities.modal";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import GooglesIcon from "../../assets/icons/svg-icons/googlemeet.svg"
import { Helmet } from "react-helmet";
import { metaConfig } from "../../utils/constants";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";





const Integrations = () => {



    const dispatch = useDispatch();
    const communityState = store.getState();

    const selectedCommunityId = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity?._id
    );

    const { createGoogleAuto } = useLiveClasses()


    const currentCommunity = communityState?.selectedCommunity?.selectedCommunity;

    const community_id = currentCommunity?._id;


    const handleSubmitGenerateAuth = async () => {
        const communityId = currentCommunity?._id;
        try {
            const response = await createGoogleAuto(communityId || "");
            if (response?.data?.authUrl) {
                window.open(response.data.authUrl, "_blank");
            }
            if (response?.data?.authUrl) {
                if (currentCommunity !== null && currentCommunity !== undefined) {
                    const communityData: ICommunity = currentCommunity;
                    dispatch(loadSelectedCommunity(communityData));
                    // console.log(communityData, "communityData")
                }
            }
            return response;
        } catch (err) {
            console.log(err, "error");
        }
    };


    const navigate = useNavigate();


    return (
        <>
            <Helmet>
                <title>{metaConfig.title.addAppointments}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>
            <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'flex' }, pb: 1 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
                <Typography
                    sx={{
                        fontSize: { xs: '16px', md: '18px' },
                        fontWeight: 600,
                        color: '#000000',
                        fontFamily: 'Montserrat',
                    }}
                >
                    Integrations
                </Typography>
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={6} >
                    <Paper elevation={0} sx={{ border: '1px solid #E7EBF1', backgroundColor: '#ffffff', p: 3, borderRadius: '10px', }}>
                        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={2} sx={{ py: 1, mr: { xs: 0, md: 5 } }}>
                            <Stack>
                                <Box
                                    component={'img'}
                                    src={GooglesIcon}
                                    width={'40px'}
                                    height={'40px'}
                                />
                            </Stack>
                            <Stack>
                                <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                                    Google Calendar
                                </Typography>
                                <Typography sx={{ fontSize: { xs: '12px', md: '14px' }, fontFamily: 'Montserrat' }}>
                                    {currentCommunity?.googleAuth?.googleEmail ? (
                                        <Typography sx={{ fontSize: { xs: '12px', md: '13px' }, fontFamily: 'Montserrat' }}>
                                            {currentCommunity.googleAuth.googleEmail}
                                        </Typography>
                                    ) : (
                                        <Typography sx={{ fontSize: { xs: '12px', md: '13px' }, fontFamily: 'Montserrat' }}>
                                            We’ll keep your calendar free of conflicts and update it automatically
                                        </Typography>
                                    )}
                                </Typography>
                            </Stack>
                            <Stack>
                                {currentCommunity?.googleAuth?.googleEmail ? (
                                    <>
                                        <Button
                                            size='small'
                                            variant='outlined'
                                            sx={{
                                                color: "#3C5AA0",
                                                fontFamily: 'Montserrat',
                                                textTransform: 'capitalize',
                                                borderRadius: '8px',
                                                border: '1px solid #3C5AA0',

                                            }}
                                            onClick={handleSubmitGenerateAuth}
                                        >
                                            change
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            size='small'
                                            variant='outlined'
                                            sx={{
                                                color: "#3C5AA0",
                                                textTransform: 'capitalize',
                                                borderRadius: '8px',
                                                border: '1px solid #3C5AA0',
                                                fontFamily: 'Montserrat'
                                            }}
                                            onClick={handleSubmitGenerateAuth}
                                        >
                                            connect
                                        </Button>
                                    </>
                                )}

                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>

        </>
    )
}
export default Integrations;