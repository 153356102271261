import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, Divider, Grid, ListItem, Stack, Box, IconButton } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useLiveClasses } from '../../../hooks/useLiveClassess';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ILiveClass } from '../../../models/liveClasses.model';
import Loader from '../../Loader/Loader';
import { AlternativePage } from '../../AlternatePages/AlternativePage';
import NoClass from '../../../assets/icons/svg-icons/noPlans.png';
import { Link, useNavigate } from 'react-router-dom';
import { EditIcon } from '../../../assets/icons';
import { USER_APP_BASE_URL } from '../../../configurations/url.config';

const AllClass = () => {
  const singleCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  let community_id = singleCommunity?._id;
  const { getAllLiveClasses } = useLiveClasses();

  const [liveClass, setLiveClass] = React.useState<ILiveClass[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);


  // console.log(liveClass, "liveClass");

  const fetchLiveClasses = async () => {
    setIsLoading(true);
    try {
      const response = await getAllLiveClasses(community_id);
      if (Array.isArray(response)) {
        setLiveClass(response);
      } else {
        setLiveClass([]);
      }
    } catch (err) {
      console.error('Error fetching live classes:', err);
      setLiveClass([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLiveClasses();
  }, []);


  const navigate = useNavigate()


  return (
    <Box sx={{ position: 'relative', height: { xs: '100vh', md: '50vh' } }}>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Loader />
        </Box>
      ) : liveClass.length === 0 ? (
        <AlternativePage title="No Meetings Available" image={NoClass} />
      ) : (
        <Grid container gap={1.5} sx={{ paddingBottom: '50px' }}>
          {liveClass?.map((classData, index) => (
            <Grid item xs={12} md={3.9} key={index}>
              <Card elevation={0} sx={{ borderTop: '10px solid #3C5AA0' }}>
                <CardContent
                  sx={{
                    pb: 0,
                    "&:last-child": {
                      pb: 1,
                    },
                  }}>
                  <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack>
                      <Typography
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#000000',
                        }}
                      >

                        {classData?.name?.length > 20 ? classData?.name?.substring(0, 20) + '...' : classData?.name}
                      </Typography>
                    </Stack>
                    <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                      <Link
                        to={`/meeting/${classData?._id}`}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                          gap: '10px',
                        }}
                      >
                        <Typography sx={{ color: '#3C5AA0', fontSize: '12px', fontFamily: 'Montserrat' }}>
                          Read More
                        </Typography>
                      </Link>
                      <IconButton>
                        <EditIcon onClick={() => {
                          navigate(`/edit-meeting/${classData?._id}`)
                        }} sx={{ fontSize: '16px', color: '#3C5AA0' }} />
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Typography
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#B1B1B1',
                    }}
                  >
                    {classData?.description.length > 40 ? classData?.description.substring(0, 40) + '...' : classData?.description}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Stack>
                      {['Instructor', 'Plan', 'Date', 'Time'].map((label, idx) => (
                        <ListItem
                          key={idx}
                          sx={{
                            fontSize: '13px',
                            fontFamily: 'Montserrat',
                            fontWeight: 500,
                            pl: 0,
                          }}
                        >
                          {label}
                        </ListItem>
                      ))}
                    </Stack>
                    <Stack>
                      {['-', '-', '-', '-'].map((dash, idx) => (
                        <ListItem
                          key={idx}
                          sx={{
                            fontSize: '13px',
                            fontFamily: 'Montserrat',
                            fontWeight: 500,
                            pl: 0,
                          }}
                        >
                          {dash}
                        </ListItem>
                      ))}
                    </Stack>

                    <Stack sx={{ color: '#3C5AA0' }}>
                      {[
                        classData?.instructorName,
                        classData?.plan?.slice(0, 1).map(plan => plan.name).join(', ') + (classData?.plan?.length > 1 ? ` (+${classData?.plan?.length - 2 + 1})` : ''),
                        new Date(classData?.startDate).toLocaleString('en-GB', {
                          timeZone: 'Asia/Kolkata',
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          hour12: false,
                        }),
                        classData?.startTime + " - " + classData?.endTime,
                      ].map((value, idx) => (
                        <ListItem
                          key={idx}
                          sx={{
                            fontSize: '13px',
                            fontFamily: 'Montserrat',
                            fontWeight: 500,
                            textTransform: 'capitalize',
                          }}
                        >
                          {value}
                        </ListItem>
                      ))}


                    </Stack>
                  </Stack>

                  <Divider />
                  <Stack
                    direction="row"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ py: 1 }}
                  >
                    <Stack
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap={0.5}
                    >
                      <Link
                        to={classData?.googleMeetLink}
                        target="_blank"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                          gap: '10px',
                        }}
                      >
                        <PlayCircleIcon
                          sx={{ fontSize: '16px', color: '#3C5AA0' }}
                        />
                        <Typography sx={{ color: '#3C5AA0', fontSize: '12px', fontFamily: 'Montserrat' }}>
                          Start Meeting
                        </Typography>
                      </Link>
                    </Stack>
                    <Stack>
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{
                          borderRadius: '30px',
                          fontFamily: 'Montserrat',
                          textTransform: 'capitalize',
                          height: '3vh',
                          color: '#3C5AA0',
                          border: '1px solid #3C5AA0',
                          fontSize: '12px',
                          fontWeight: '400',
                        }}
                        component="a"
                        href={`https://wa.me/?text=${encodeURIComponent(
                          `Join the class using this link: ${`${USER_APP_BASE_URL}/live-class/${classData?._id}`}`
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Share
                      </Button>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default AllClass;
