import { Key, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
  IconButton,
  Grid,
  Tooltip,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Divider,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
} from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './About.css';
import AboutCarousel from './AboutCarousal';
import AboutCarouselTeem from './AboutCarouselTeem';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import store from "../../store";
import { ICommunity } from '../../models/communities.modal';
import { BASE_URL } from '../../configurations/url.config';
import axios from 'axios';
import Loader from '../Loader/Loader';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ImageGalleryCraft from '../ImageUploader/ImageGalleryCraft';
import { CommunityCard } from '../Home/Communitycard';
import { metaConfig } from '../../utils/constants';
import { Helmet } from 'react-helmet';
// import QrCode from "./QrCode";

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const About = () => {
  const { id } = useParams();
  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  const community_id = id ? id : selectedCommunity?._id;
  const [data, setData] = useState<ICommunity>();
  const [loading, setLoading] = useState(true);

  // const [expanded, setExpanded] = useState<string | false>(false);

  // const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/communities/${community_id}/home`
        );
        setData(response.data.community);
        setLoading(false);
      } catch (error) {
        // console.log("error");
        setLoading(false);
      }
    };
    fetchData();
  }, [community_id]);

  const handleViewMap = () => {
    if (data && data?.location) {
      window.open(data?.location, '_blank');
    }
  };

  const openSocialMediaProfile = (type: string) => {
    const socialLink = data?.socialLinks?.find((link) => link.type === type);
    if (socialLink) {
      const url = socialLink.value;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        const appUrls: Record<string, string> = {
          facebook: `fb://facewebmodal/f?href=${url}`,
          youtube: `vnd.youtube://${url.split('https://www.youtube.com/').pop()}`,
          twitter: `twitter://user?screen_name=${extractTwitterHandle(url)}`,
          linkedin: `linkedin://company/${extractLinkedInId(url)}`,
          instagram: `instagram://user?username=${extractInstagramHandle(url)}`,
        };
        const deepLink = appUrls[type];
        if (deepLink) {
          window.location.href = deepLink;
          setTimeout(() => {
            window.open(url, '_blank');
          }, 1000);
        } else {
          window.open(url, '_blank');
        }
      } else {
        window.open(url, '_blank');
      }
    }
  };

  const extractTwitterHandle = (url: string) =>
    url.split('https://twitter.com/').pop()?.split('/')[0] || '';

  const extractLinkedInId = (url: string) =>
    url.split('https://www.linkedin.com/company/').pop()?.split('/')[0] || '';

  const extractInstagramHandle = (url: string) =>
    url.split('https://www.instagram.com/').pop()?.split('/')[0] || '';


  const defaultImages = [
    'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_4.jpeg',
    'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png',
  ];

  const images = data?.gallery?.length ? data.gallery : defaultImages;

  // const faq = data?.faq
  // console.log("faq", data)
  // const communityId = `${community_id}`;
  // const communityUrl = `${USER_APP}/communities/${community_id}/about`;

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="60vh" // Set the height as needed
      >
        {loading && (
          <Grid item>
            <Stack>
              <Loader />
            </Stack>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.aboutCommunity}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Grid container>
        <Grid item sm={8} xs={12} md={12}>
          <>
            <Box
              sx={
                {
                  // display: "flex",
                  // justifyContent: "flex-end",
                  // direction: "row",
                  // mt: 2,
                }
              }
            >
              <Box sx={{ maxWidth: { xs: 380, md: 635, lg: 800, xl: 1200 } }}>
                <Stack spacing={1}>
                  <Box
                    component={'img'}
                    src={data?.banner}
                    alt=""
                    sx={{
                      width: '100%',
                      height: { xs: 200, md: 300 },
                      maxHeight: { xs: 200, md: 300 },
                      objectFit: 'cover',
                      borderRadius: '5px',
                    }}
                  />

                  <CommunityCard />

                  {data?.description && data.description.length > 0 && (
                    <Card
                      elevation={0}
                      sx={{
                        // maxWidth: { xs: 370, md: 800 },
                        maxHeight: { xs: 370, md: 800 },
                        padding: { xs: '0.1vw 4vw', md: '2vw 1vw' },
                        mt: 0.5,
                        borderRadius: '12px !important', border: '1px solid #E7EBF1',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: '15px', md: '20px' },
                          fontWeight: '500',
                          color: '#3C5AA0',
                          fontFamily: 'Montserrat',
                          marginBottom: '5px',
                        }}
                      >
                        Know more abouts {data?.name}
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '13px',
                          color: '#464646',
                          // Set max height and overflow for the scrolling effect
                          maxHeight: '170px',
                          overflowY: 'auto',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        {data?.description}
                      </Typography>
                    </Card>
                  )}

                  {data?.services && data.services.length > 0 && (
                    <Card elevation={0} sx={{ padding: '1vw 3vw', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
                      <Typography
                        sx={{
                          fontSize: { xs: '15px', md: '20px' },
                          fontWeight: '500',
                          color: '#3C5AA0',
                          fontFamily: 'Montserrat',
                          marginBottom: '5px',
                        }}
                      >
                        {data?.name}’s Services
                      </Typography>

                      <AboutCarousel productData={data?.services || []} />
                    </Card>
                  )}

                  {data?.gallery && data?.gallery.length > 0 && (
                    <Card elevation={0} sx={{ padding: '2vw 3vw', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: '16px', md: '20px' },
                            fontWeight: '500',
                            color: '#3C5AA0',
                            fontFamily: 'Montserrat',
                            marginBottom: '5px',
                          }}
                        >
                          Gallery
                        </Typography>
                        <Typography sx={{ fontSize: '13px', fontFamily: 'Montserrat' }}>
                          {data?.gallery?.length} Images
                        </Typography>
                      </Box>
                      <br />
                      <Stack
                        direction="row"
                        gap={1}
                        flexWrap="wrap"
                        m="auto"
                        sx={{ marginBottom: '10px', alignItems: 'flex-start' }}
                      >
                        <ImageGalleryCraft
                          images={images}
                          imageHeight={100}
                          imageWidth={100}
                        ></ImageGalleryCraft>
                      </Stack>
                    </Card>
                  )}
                  {data?.mission && data.mission.length > 0 && (
                    <Card
                      elevation={0}
                      sx={{
                        // maxWidth: { xs: 370, md: 800 },
                        maxHeight: { xs: 370, md: 800 },
                        padding: { xs: '0.1vw 4vw', md: '2vw 3vw' },
                        alignContent: 'center',
                        borderRadius: '12px !important', border: '1px solid #E7EBF1',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: '15px', md: '20px' },
                          fontWeight: '500',
                          color: '#3C5AA0',
                          fontFamily: 'Montserrat',
                          marginBottom: '5px',
                        }}
                      >
                        Mission
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '13px',
                          color: '#464646',
                          maxHeight: '170px',
                          overflowY: 'auto',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        {data?.mission && data.mission.length > 0 && (
                          <>{data.mission}</>
                        )}
                      </Typography>
                    </Card>
                  )}
                  {data?.vision && data.vision.length > 0 && (
                    <Card
                      elevation={0}
                      sx={{
                        // maxWidth: { xs: 370, md: 800 },
                        maxHeight: { xs: 370, md: 800 },
                        padding: { xs: '1.5vw 4vw', md: '1vw 3vw' },
                        borderRadius: '12px !important', border: '1px solid #E7EBF1',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: '15px', md: '20px' },
                          fontWeight: '500',
                          color: '#3C5AA0',
                          fontFamily: 'Montserrat',
                          marginBottom: '10px',
                        }}
                      >
                        Vision
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '13px',
                          color: '#464646',
                          maxHeight: '170px',
                          overflowY: 'auto',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        {data?.vision && data.vision.length > 0 && (
                          <>{data.vision}</>
                        )}
                      </Typography>
                    </Card>
                  )}

                  {data?.directorMessage && data.directorMessage.length > 0 && (
                    <Card
                      elevation={0}
                      sx={{
                        // maxWidth: { xs: 370, md: 800 },
                        maxHeight: { xs: 370, md: 800 },
                        padding: { xs: '1.5vw 4vw', md: '1vw 3vw' },
                        borderRadius: '12px !important', border: '1px solid #E7EBF1',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: '15px', md: '20px' },
                          fontWeight: '500',
                          color: '#3760A9',
                          fontFamily: 'Montserrat',
                          marginBottom: '10px',
                        }}
                      >
                        Director’s Message
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '13px',
                          color: '#464646',
                          maxHeight: '170px',
                          overflowY: 'auto',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        {data?.directorMessage}
                      </Typography>
                    </Card>
                  )}

                  {data?.teams && data.teams.length > 0 && (
                    <Card elevation={0} sx={{ padding: '2vw 3vw', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
                      <Typography
                        sx={{
                          fontSize: { xs: '15px', md: '20px' },
                          fontWeight: '500',
                          color: '#3C5AA0',
                          fontFamily: 'Montserrat',
                          marginBottom: '5px',
                        }}
                      >
                        Meet our Team
                      </Typography>

                      {/* <AboutCarouselTeem productData={data?.members || []} /> */}
                      <AboutCarouselTeem productData={data?.teams || []} />
                    </Card>
                  )}

                  {data?.faq && data.faq.length > 0 && (
                    <Card elevation={0} sx={{ padding: '2vw 3vw', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
                      <Typography
                        sx={{
                          fontSize: { xs: '15px', md: '20px' },
                          fontWeight: '500',
                          color: '#3C5AA0',
                          fontFamily: 'Montserrat',
                          marginBottom: '5px',
                        }}
                      >
                        FAQ's
                      </Typography>
                      <Stack
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '6px',
                          mt: 2,
                          display: {
                            xs: '0',
                            sm: '600',
                            md: '900',
                            lg: '1200',
                            xl: '1536',
                          },
                        }}
                      >
                        <Stack>
                          {data?.faq?.map(
                            (f: any, index: Key | null | undefined) => (
                              <Accordion
                                elevation={0}
                                disableGutters={true}
                                sx={{
                                  borderTop: '1px solid #00000030',
                                  minHeight: 'auto',
                                  flexShrink: 0,
                                }}
                                key={index}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  sx={{ borderTop: 'red', padding: '0px' }}
                                >
                                  <Typography
                                    sx={{
                                      top: 0,
                                      fontWeight: 500,
                                      fontSize: '13px',
                                      color: '#575757',
                                      fontFamily: 'Montserrat',
                                    }}
                                  >
                                    {f?.question}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ paddingBottom: '8px' }}>
                                  <Typography
                                    sx={{
                                      top: 0,
                                      fontSize: '13px',
                                      color: '#000000',
                                      fontFamily: 'Montserrat',
                                    }}
                                  >
                                    {f?.answer}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )
                          )}
                          <Divider
                            sx={{ height: '0.8px', backgroundColor: '#cccccc' }}
                          />
                        </Stack>
                      </Stack>
                    </Card>
                  )}

                  <Card elevation={0} sx={{ padding: '2vw 3vw', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
                    <Typography
                      sx={{
                        fontSize: { xs: '15px', md: '20px' },
                        fontWeight: '500',
                        color: '#3C5AA0',
                        fontFamily: 'Montserrat',
                        marginBottom: '5px',
                      }}
                    >
                      Get in Touch
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '13px',
                        textDecoration: 'underline',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      Coffee with us:
                    </Typography>
                    <>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: '#565656',
                          margin: '1vh 0vh',
                          fontSize: '13px',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        {data?.fullAddress}, {data?.city} - {data?.zipCode}
                      </Typography>
                    </>

                    <Button
                      sx={{
                        border: '1px solid black',
                        color: 'black',
                        fontWeight: 600,
                        fontSize: '12px',
                        fontFamily: 'Montserrat'
                      }}
                      onClick={handleViewMap}
                    >
                      View in Map
                    </Button>
                    <Box
                      sx={{
                        display: { xs: 'inline', md: 'flex' },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '2vw',
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: '14px',
                            color: '#000000',
                            margin: '1vh 0vh',
                            textDecoration: 'underline',
                            textDecorationColor: '#565656',
                            fontFamily: 'Montserrat'
                          }}
                        >
                          Call us:
                        </Typography>
                        {data?.phoneNumber && (
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '13px',
                              color: '#565656',
                              margin: '1vh 0vh',
                              fontFamily: 'Montserrat'
                            }}
                          >
                            <a
                              style={{
                                textDecoration: 'none',
                                color: '#565656',
                                fontFamily: 'Montserrat'
                              }}
                              href={`tel:${data?.phoneNumber}`}
                            >
                              {data?.phoneNumber}
                            </a>
                          </Typography>
                        )}
                        {data?.mobileNumber ? (
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '13px',
                              color: '#565656',
                              margin: '1vh 0vh',
                            }}
                          >
                            <a
                              style={{
                                textDecoration: 'none',
                                color: '#565656',
                              }}
                              href={`tel:${data?.mobileNumber}`}
                            >
                              {data?.mobileNumber}
                            </a>
                          </Typography>
                        ) : (
                          ' '
                        )}
                      </Box>

                      {data?.email && data.email.length > 0 && (
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: '15px',
                              color: '#000000',
                              margin: '1vh 0vh',
                              textDecoration: 'underline',
                              textDecorationColor: '#565656',
                              fontFamily: 'Montserrat'
                            }}
                          >
                            Write us:
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '13px',
                              color: '#565656',
                              margin: '1vh 0vh',
                              fontFamily: 'Montserrat'
                            }}
                          >
                            <a
                              style={{
                                textDecoration: 'none',
                                color: '#565656',
                                fontFamily: 'Montserrat'
                              }}
                              href={`mailto:${data?.email}`}
                            >
                              {data?.email}
                            </a>
                          </Typography>
                        </Box>
                      )}
                      {/* <Box>
                        {data?.status === "PUBLISHED" ? (
                          <QrCode id={communityId} url={communityUrl} />
                        ) : (
                          <>
                          </>
                        )}
                      </Box> */}
                    </Box>
                  </Card>

                  <Card elevation={0} sx={{ padding: '2vw 3vw', borderRadius: '12px !important', border: '1px solid #E7EBF1', }}>
                    <Typography
                      sx={{
                        fontSize: { xs: '15px', md: '20px' },
                        fontWeight: '500',
                        fontFamily: 'Montserrat',
                        marginBottom: { xs: '1vw', md: '.5vw' },
                      }}
                    >
                      We are social
                    </Typography>
                    <Tooltip title="Facebook">
                      <IconButton onClick={() => openSocialMediaProfile('facebook')}>
                        <FacebookIcon
                          className="facebook"
                          sx={{ color: '#464646' }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Youtube">
                      <IconButton onClick={() => openSocialMediaProfile('youtube')}>
                        <YouTubeIcon
                          className="youtube"
                          sx={{ color: '#464646' }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Twitter">
                      <IconButton onClick={() => openSocialMediaProfile('twitter')}>
                        <TwitterIcon
                          className="twitter"
                          sx={{ color: '#464646' }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Linkedin">
                      <IconButton onClick={() => openSocialMediaProfile('linkedin')}>
                        <LinkedInIcon
                          className="linkdin"
                          sx={{ color: '#464646' }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Instagram">
                      <IconButton onClick={() => openSocialMediaProfile('instagram')}>
                        <InstagramIcon
                          className="instagram"
                          sx={{ color: '#464646' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Card>
                </Stack>
              </Box>
            </Box>
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default About;
