import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

export const drawerWidth = 250;

export const styles = {
  main: {
    flexGrow: 1,
    // pt: 3,
    borderRadius: '30px 0px 0px',
    height: '100vh',
    backgroundColor: '#f0f9ff',
    overflow: 'hidden',
  
  },
  contentSection: {
    height: 'calc(100vh - 64px)',
    overflow: 'auto',
    px: 2,
    py: 0,
    backgroundColor: '#f0f9ff',
  },
};


export const stylesNew = {
  main: {
    flexGrow: 1,
    // pt: 3,
    borderRadius: '30px 0px 0px',
    height: '10vh',
    backgroundColor: '#f0f9ff',
    overflow: 'hidden',
  },
  contentSection: {
   height: '88vh',
    overflow: 'auto',
    py: 0,
    backgroundColor: '#f0f9ff',
  },
};


export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  height: '100vh',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  border: 'none',
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  border: 'none',
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  // boxShadow: "0px 3px 15px #f6f5f5",
  '& > .MuiIconButton-root': {
    padding: 0,
  },
}));

export const Drawer: any = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
