import { useEffect, useState } from 'react';
import { useCommunity } from '../../../hooks/useCommunity';
import { ICommunity } from '../../../models/communities.modal';
import { SuggestionsList } from './SuggestionsList';
import { Paper } from '@mui/material';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Stack } from '@mui/material';
import { RotatingLines } from "react-loader-spinner"

export const Suggestions = () => {
  const { getSuggestionCommunities } = useCommunity();
  const [trendingCommunities, setTrendingCommunities] = useState<ICommunity[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const trending = await getSuggestionCommunities();
        setTrendingCommunities(trending.communities);
      } catch (error) {
        console.error('Error fetching suggestion communities:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchData = async () => {
    const response = await getSuggestionCommunities();
    // console.log(response,"resulttt");

    if (response) {
      setTrendingCommunities(response.communities);
    }
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{ borderRadius: '10px', maxHeight: '34vh', boxShadow: 'none' }}
      >
        {loading ? ( 
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '33.5vh',
            }}
          >
      <Stack
      sx={{
        color: 'grey.500',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30vh',
      }}
      spacing={2}
      direction="row"
    >
      <RotatingLines
        visible={true}
        width="30"
        strokeWidth="5"
        strokeColor="#777777"
        ariaLabel="rotating-lines-loading"
      />
      </Stack>
          </div>
        ) : (
          <SimpleBar style={{ maxHeight: '33.5vh' }}>
            {trendingCommunities.map((community) => (
              <SuggestionsList
                key={community._id}
                {...community}
                refetchData={refetchData}
              />
            ))}
          </SimpleBar>
        )}
      </Paper>
    </>
  );
};
