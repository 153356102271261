import React, { useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate, useParams } from 'react-router-dom';
import store from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Loader from '../Loader/Loader';
import Close from '@mui/icons-material/Close';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import { usePlans } from '../../hooks/usePlans';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { metaConfig } from '../../utils/constants';
import { Helmet } from 'react-helmet';
import { useLiveClasses } from '../../hooks/useLiveClassess';
import GooglesIcon from "../../assets/icons/svg-icons/googlemeet.svg"
import { useWebBuilders } from '../../hooks/useWebBuilder';
// import { IMultiMedia } from '../../models/multi-media.model';
// import { DeleteIcon } from '../../assets/icons';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ICommunity } from '../../models/communities.modal';
import { loadSelectedCommunity } from '../../store/slices/CommunitySlice';
// import { nanoid } from '@reduxjs/toolkit';
import { useCommunity } from '../../hooks/useCommunity';
import { BASE_URL } from '../../configurations/url.config';
import axios from 'axios';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
dayjs.extend(utc);

interface Community {
  name: string;
  id: string;
}

export interface IAddLiveClass {
  name: string;
  description: string;
  instructorName: string;
  startDate: string;
  startTime: string;
  endTime: string;
  community: string;
  plan: any;
  coverImage: string | null;
  coverNote: string | null;
}



export interface ILiveClass {
  _id: string;
  name: string;
  description: string;
  instructorName: string;
  startDate: string;
  startTime: string;
  status: string;
  community: string;
  plan: string;
  coverImage: {
    _id: string;
    label: string;
    type: string;
    value: string;
  } | null;
  coverNote: {
    _id: string;
    label: string;
    type: string;
    value: string;
  } | null;

}

const AddLiveClass = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const communityState = store.getState();
  const currentCommunity = communityState?.selectedCommunity?.selectedCommunity;
  // console.log(currentCommunity, "currentCommunity")
  const { getCommunityById } = useCommunity();
  const [data, setData] = useState<ICommunity>();

  const community_id = currentCommunity?._id;



  useEffect(
    () => {
      const fetchData = async () => {
        try {
          let response;
          if (community_id) {
            response = await getCommunityById(community_id);
            setData(response?.data);
          } else if (community_id) {
            response = await axios.get(
              `${BASE_URL}/communities/${community_id}/home`
            );
            const { community } = response?.data;
            setData(community);
          }
        } catch (error) {
          console.log('error');
        }
      };

      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [community_id]
  )




  useEffect(() => {
    if (currentCommunity !== null && currentCommunity !== undefined) {
      const communityData: ICommunity = currentCommunity;
      dispatch(loadSelectedCommunity(communityData));
      // console.log(communityData, "communityData")
    }
  }, [currentCommunity, dispatch]);


  const [planDetail] = useState<any>();
  const [selectedPlan, setSelectedPlan] = useState<any>(planDetail);
  // console.log(selectedPlan);

  const [community] = React.useState(false);
  const [options] = React.useState<Community[]>([]);
  const loading = community && options.length === 0;




  // eslint-disable-next-line
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(dayjs());
  const [selectedStartTime, setSelectedStartTime] = useState<Dayjs | null>(dayjs().hour(9).minute(0));
  const [selectedEndTime, setSelectedEndTime] = useState<Dayjs | null>(dayjs().hour(10).minute(0));
  const [opensPublish, setOpensPublish] = React.useState(false);
  const [openConnect, setOpenConnect] = useState(true)
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line
  const [plans, setPlans] = useState<IAddLiveClass[]>([]);


  const { uploadImages } = useWebBuilders();

  const [liveClassValues, setLiveClassValues] = useState<IAddLiveClass>({
    name: '',
    description: '',
    instructorName: '',
    startDate: selectedStartDate?.format('YYYY-MM-DD') || '',
    startTime: selectedStartTime?.format('HH:mm') || '',
    endTime: selectedEndTime?.format('HH:mm') || '',
    community: currentCommunity?._id ?? '',
    plan: selectedPlan?._id,
    coverImage: null,
    coverNote: null
  });


  // console.log(liveClassValues, "liveClassValues");


  // console.log(selectedStartTime, selectedEndTime, "time");


  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  // const { name, value } = event.target;
  // setLiveClassValues((prevValues: any) => ({
  // ...prevValues,
  // [name]: value,
  // }));
  // };


  const { id } = useParams();

  const { createLiveClass, createGoogleAuto, getClassById, updateLiveClass } = useLiveClasses()

  const handleSubmitGenerateAuth = async () => {
    const communityId = currentCommunity?._id;
    try {
      const response = await createGoogleAuto(communityId || "");
      if (response?.data?.authUrl) {
        window.open(response.data.authUrl, "_blank");
      }
      if (response?.data?.authUrl) {
        if (currentCommunity !== null && currentCommunity !== undefined) {
          const communityData: ICommunity = currentCommunity;
          dispatch(loadSelectedCommunity(communityData));
          // console.log(communityData, "communityData")
        }
      }
      return response;
    } catch (err) {
      console.log(err, "error");
    }
  };


  useEffect(() => {
    if (!id) return;
    const loadLiveClass = async () => {
      const data = await getClassById(selectedCommunityId, id || '');
      const responseData = data[0];
      if (responseData) {
        setLiveClassValues((prev: any) => ({
          ...prev,
          name: responseData.name || '',
          description: responseData.description || '',
          instructorName: responseData.instructorName || '',
          startDate: formateStartDate(responseData.startDate) || '',
          startTime: responseData.startTime || '',
          endTime: responseData.endTime || '',
          community: responseData.community || currentCommunity?._id || '',
          coverImage: responseData?.coverImage?._id || '',
          coverNote: responseData?.coverNote?._id || '',
        }));
        const plans = responseData?.plan || [];
        setSelectedPlan(plans);
        if (responseData?.startTime) {
          const parsedStartTime = dayjs(responseData.startTime, 'HH:mm');
          setSelectedStartTime(parsedStartTime);
        }
        if (responseData?.endTime) {
          const parsedEndTime = dayjs(responseData.endTime, 'HH:mm');
          setSelectedEndTime(parsedEndTime);
        }
      }
    };
    loadLiveClass();
  }, [id]);




  const formateStartDate = (date: string) => {
    const startDate = date.slice(0, 10);
    setSelectedStartDate(dayjs.utc(startDate));
    return startDate;
  };

  const formatStartTime = (date: string) => {
    const formattedTime = dayjs.utc(date).format("HH:mm");
    setSelectedStartTime(dayjs.utc(date));
    return formattedTime;
  };

  const formatEndTime = (date: string) => {
    const formattedTime = dayjs.utc(date).format("HH:mm");
    setSelectedEndTime(dayjs.utc(date));
    return formattedTime;
  };


  const handleMyDateChange = (e: any) => {
    const selectedNewDate = new Date(e);
    const formattedDate = selectedNewDate.toISOString().split('T')[0];
    setLiveClassValues({ ...liveClassValues, startDate: formattedDate });
    formateStartDate(formattedDate);
  };

  const handleMyTimeChange = (time: any) => {
    const formattedTime = dayjs(time).format("HH:mm");
    setLiveClassValues({ ...liveClassValues, startTime: formattedTime });
    formatStartTime(formattedTime)
  };

  const handleEndTimeChange = (time: any) => {
    const formattedTime = dayjs(time).format("HH:mm");
    setLiveClassValues({ ...liveClassValues, endTime: formattedTime });
    formatEndTime(formattedTime)
  };


  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLiveClassValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
    setError(value === '');
  };
  // eslint-disable-next-line

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitClicked(true);

    // Safely filter and map the selected plans
    const filteredPlans = selectedPlan
      ?.filter((plan: any) => plan && typeof plan === 'object' && plan?._id) // Ensure valid objects with _id
      .map((plan: any) => plan._id) // Extract only the _id
      .filter((id: string) => id?.trim() !== ""); // Remove empty or whitespace-only IDs

    // console.log(filteredPlans, "filteredPlans"); // Log filtered plans for debugging

    const updatedLiveClassValues = {
      ...liveClassValues,
      plan: filteredPlans.length > 0 ? filteredPlans : selectedPlan, // Use default ID if no plans selected
      community: currentCommunity?._id || '',
      name: liveClassValues?.name || '',
      description: liveClassValues?.description || '',
      instructorName: liveClassValues?.instructorName || '',
      startDate: selectedStartDate?.format('YYYY-MM-DD') || '',
      startTime: liveClassValues?.startTime || '',
      endTime: liveClassValues?.endTime || '',
      coverImage: liveClassValues?.coverImage || "6767e29cc5d5b91398457c27",
      coverNote: liveClassValues?.coverNote || "6767e29cc5d5b91398457c27"
    };

    try {
      setLoader(true);
      let response;
      if (id) {
        response = await updateLiveClass(updatedLiveClassValues, id);
        if (response?.class?.data) {
          setLiveClassValues(response?.class?.data);
        } else {
          console.warn('API did not return updated class data.');
        }
        if (response?.class?.status === 200) {
          enqueueSnackbar('Meeting rescheduled successfully', { variant: 'success', autoHideDuration: 3000 });
          navigate('/meetings');
        } else {
          throw new Error('Failed to update Meeting: Invalid response');
        }
      } else {
        response = await createLiveClass(updatedLiveClassValues);
      }
      return response;
    } catch (error: any) {
      console.error('Submission error:', error);
    } finally {
      setLoader(false);
    }
  };





  const handleImageChange = async (files: FileList | null) => {
    if (!files?.length) return;
    setImageUploading(true);
    try {
      const file = files[0];
      const [response] = await uploadImages([file]);
      const uploadedImageUrl = response?._id;
      if (!uploadedImageUrl) {
        throw new Error('Image upload failed: No response value');
      }
      setLiveClassValues((prev) => ({
        ...prev,
        coverImage: uploadedImageUrl,
      }));
      enqueueSnackbar('Banner image uploaded successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error uploading image:', error);
      enqueueSnackbar('Failed to upload image. Please try again.', { variant: 'error' });
    } finally {
      setImageUploading(false);
    }
  };



  const handleNoteChange = async (files: FileList | null) => {
    if (!files?.length) return;
    setFileUploading(true);
    try {
      const file = files[0];
      const [response] = await uploadImages([file]);
      const uploadedFileUrl = response?._id;
      if (!uploadedFileUrl) {
        throw new Error('File upload failed: No response value');
      }
      setLiveClassValues((prev) => ({
        ...prev,
        coverNote: uploadedFileUrl
      }));
      enqueueSnackbar('Cover File uploaded successfully', { variant: 'success', autoHideDuration: 3000, });
    } catch (error) {
      console.error('Error uploading image:', error);
      enqueueSnackbar('Failed to upload File. Please try again.', { variant: 'error' });
    } finally {
      setFileUploading(false);
    }
  };



  //attach file new logic start
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('sm');

  const { getCommunityPlansList } = usePlans();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [plansList, setPlansList] = useState<ILiveClass[]>([]);
  const [imageUploading, setImageUploading] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);

  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );

  const loadPlansList = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const plans_data = await getCommunityPlansList(selectedCommunityId);
        if (plans_data) {
          setIsLoading(false);
          setPlansList(plans_data);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log(plansList);
  useEffect(() => {
    loadPlansList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId]);

  const plansTitles = plansList ? plansList.map((plan) => plan?.name) : [];

  const handleClickOpenPublish = () => {
    setOpensPublish(true);
  };

  const handleClickClosesPublish = () => {
    setOpensPublish(false);
  };



  // console.log(liveClassValues?.coverImage, "image")


  const isDataAvailable =
    liveClassValues.name?.trim() &&
    liveClassValues.description?.trim() &&
    liveClassValues.instructorName?.trim() &&
    liveClassValues.startDate &&
    liveClassValues.startTime &&
    liveClassValues.endTime &&
    selectedPlan


  // console.log("Live Class Values:", liveClassValues);
  // console.log("Is Data Available:", isDataAvailable);
  // console.log("Google Email:", currentCommunity?.googleAuth?.googleEmail);

  return (
    <>

      <Helmet>
        <title>{metaConfig.title.addLiveClass}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'flex' }, pb: 2 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => {
          navigate('/meetings');
        }} />
        <Typography
          sx={{
            fontSize: { xs: '16px', md: '18px' },
            fontWeight: 600,
            color: '#000000',
            fontFamily: 'Montserrat',
          }}
        >
          {id ? "Edit Meeting" : "Create Meeting"}
        </Typography>
      </Stack >
      <div>
        {loading ? (
          <Loader />
        ) : (
          <Stack direction={'column'} spacing={1}>
            <Box
              sx={{

                pl: 1,
                pr: 1,
                pb: 1,
                pt: 1,
                borderRadius: '12px !important', border: '1px solid #E7EBF1', backgroundColor: '#F9F9F9',
                // height: { xs: 'fit-content', md: '50vh' },
              }}
            >
              {!id &&
                <>
                  <Stack display={'flex'} flexDirection={'row'} justifyContent={{ xs: 'center', md: 'flex-end' }} alignItems={'center'} gap={2} sx={{ py: 1, mr: { xs: 0, md: 5 } }}>
                    <Box
                      component={'img'}
                      src={GooglesIcon}
                      width={'25px'}
                      height={'25px'}
                    />
                    <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, fontFamily: 'Montserrat' }}>
                      {currentCommunity?.googleAuth?.googleEmail ? (
                        <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, fontFamily: 'Montserrat' }}>
                          {currentCommunity.googleAuth.googleEmail}
                        </Typography>
                      ) : (
                        <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, fontFamily: 'Montserrat' }}>
                          Connect your Gmail account
                        </Typography>
                      )}
                    </Typography>
                    {currentCommunity?.googleAuth?.googleEmail ? (
                      <>
                        <Button
                          size='small'
                          variant='outlined'
                          sx={{
                            color: "#3C5AA0",
                            fontFamily: 'Montserrat',
                            textTransform: 'capitalize',
                            borderRadius: '8px',
                            border: '1px solid #3C5AA0'
                          }}
                          onClick={handleSubmitGenerateAuth}
                        >
                          change
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          size='small'
                          variant='outlined'
                          sx={{
                            color: "#3C5AA0",
                            textTransform: 'capitalize',
                            borderRadius: '30px',
                            border: '1px solid #3C5AA0'
                          }}
                          onClick={handleSubmitGenerateAuth}
                        >
                          connect
                        </Button>
                      </>
                    )}
                  </Stack>
                  <Divider />
                </>
              }

              <>
                <Box
                  sx={{
                    borderRadius: '10px',
                  }}
                >
                  <Grid
                    container
                    sx={{ borderRadius: '10px', padding: '5px' }}
                    gap={1}
                  >
                    <Grid item xs={12} md={8}>
                      <Stack spacing={1} sx={{ m: 1 }}>
                        <Stack>
                          <Stack>
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: '13px',
                                color: '#2653A3',
                                fontWeight: '500',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              Name
                            </Typography>
                            <TextField
                              required
                              name="name"
                              label="Name"
                              size="small"
                              onChange={handleChanges}
                              value={liveClassValues?.name}
                              InputLabelProps={{
                                style: { fontSize: '13px', color: '#565656' },
                              }}
                              sx={{
                                mt: 1,

                                mb: 1,
                                fontSize: '13px',
                                backgroundColor: 'white',
                                '& .MuiInputLabel-root': {
                                  fontFamily: 'Montserrat',
                                },
                              }}
                            />
                          </Stack>
                        </Stack>
                        <Stack>
                          <Stack>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                color: '#2653A3',
                                fontWeight: '500',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              Description
                            </Typography>
                            <TextField
                              required
                              name="description"
                              id="description"
                              label="Description"
                              size="medium"
                              value={liveClassValues?.description}
                              onChange={handleChanges}
                              multiline
                              rows={2}
                              InputLabelProps={{
                                style: { fontSize: 13, color: '#565656' },
                              }}
                              sx={{
                                mt: 1, backgroundColor: 'white', '& .MuiInputLabel-root': {
                                  fontFamily: 'Montserrat',
                                },
                              }}
                            />
                          </Stack>
                        </Stack>
                        <Stack>
                          <Stack>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                color: '#2653A3',
                                fontWeight: '500',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              Instructor Name
                            </Typography>
                            <TextField
                              required
                              name="instructorName"
                              id="instructor"
                              label="Instructor Name"
                              size="small"
                              value={liveClassValues?.instructorName}
                              onChange={handleChanges}
                              InputLabelProps={{
                                style: { fontSize: 13, color: '#565656' },
                              }}
                              sx={{
                                mt: 1, backgroundColor: 'white',
                                '& .MuiInputLabel-root': {
                                  fontFamily: 'Montserrat',
                                },
                              }}
                            ></TextField>
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack sx={{ m: 1 }}>

                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Stack>
                              <Stack sx={{}}>
                                <Typography
                                  sx={{
                                    mt: 1,
                                    fontSize: '13px',
                                    color: '#2653A3',
                                    fontWeight: '500',
                                    fontFamily: 'Montserrat',
                                  }}
                                >
                                  Select Date
                                </Typography>
                              </Stack>
                              <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer
                                  components={['DesktopDatePicker']}
                                  sx={{
                                    overflow: 'hidden',
                                    backgroundColor: 'white',
                                    mt: 1,
                                    p: 0,
                                    boxShadow: 'none'
                                  }}
                                >
                                  <DatePicker
                                    value={selectedStartDate}
                                    format="DD/MM/YYYY"
                                    // label="Select Date"
                                    disablePast
                                    onChange={handleMyDateChange}
                                    sx={{
                                      fontSize: '12px',
                                      borderRadius: '10px',
                                      backgroundColor: 'white',
                                      boxShadow: 'none',
                                      '& .css-q9e13y-MuiStack-root>.MuiTextField-root':
                                      {
                                        backgroundColor: 'white',
                                      },
                                    }}
                                    slotProps={{
                                      textField: {
                                        size: 'small',
                                        fullWidth: true,
                                        sx: {
                                          '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error':
                                          {
                                            color: '#00000061',
                                          },
                                          '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                                          {
                                            borderColor: 'green',
                                            border: '1px solid',
                                          },
                                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                                          {
                                            color: '#92929D',
                                            fontSize: '13px',
                                          },
                                          '& .MuiInputLabel-root': {
                                            fontSize: '13px',
                                          },
                                          '& .MuiInputLabel-root.Mui-focused': {
                                            fontSize: '13px',
                                          },

                                        },
                                      },
                                      layout: {
                                        sx: {
                                          color: '#2653A3',
                                          borderRadius: '20px',
                                          borderWidth: '0px',
                                          borderColor: '#2653A3',
                                          border: '0px solid',
                                          backgroundColor: 'white',
                                          fontFamily: 'Montserrat',
                                          boxShadow: 'none'
                                        }
                                      }
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>

                              {/* <FormControl>
 <FormLabel
 id="demo-row-radio-buttons-group-label"
 sx={{
 mt: 1,
 fontSize: '13px',
 color: '#2653A3',
 fontWeight: '500',
 fontFamily: 'Montserrat',
 }}
 >
 Select Privacy
 </FormLabel>
 <RadioGroup
 row
 aria-labelledby="demo-row-radio-buttons-group-label"
 name="pricing"
 value={value}
 onChange={handleChange}
 >
 <FormControlLabel
 value="YES"
 control={<Radio />}
 label="Yes"
 />
 <FormControlLabel
 value="NO"
 control={<Radio />}
 label="No"
 />
 </RadioGroup>
 </FormControl> */}
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack sx={{}}>
                              <Typography
                                sx={{
                                  ml: 1,
                                  mt: 1,
                                  fontSize: '13px',
                                  color: '#2653A3',
                                  fontWeight: '500',
                                  fontFamily: 'Montserrat',
                                }}
                              >
                                Select Plan
                              </Typography>
                            </Stack>{' '}
                            <Stack sx={{}}>
                              <Autocomplete
                                disabled={!!id}
                                options={['Select All', ...plansTitles]}
                                size="small"
                                disablePortal
                                disableCloseOnSelect
                                id="combo-box-demo"
                                multiple
                                loading={isLoading}
                                sx={{ mt: 1, fontSize: '12px', backgroundColor: 'white', fontFamily: 'Montserrat', }}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label="Select Plans"
                                    sx={{
                                      '& .MuiInputLabel-root': { fontSize: '13px' },
                                      '& .MuiInputLabel-root.Mui-focused': { fontSize: '13px' },
                                    }}
                                  />
                                )}
                                ListboxProps={{
                                  sx: { fontSize: '13px', font: 'Roboto', borderRadius: '30px' },
                                }}
                                noOptionsText="No Plans"
                                value={selectedPlan?.map((plan: any) => plan?.name) || []}
                                onChange={(event, newValue) => {
                                  if (newValue.includes('Select All')) {
                                    if ((selectedPlan?.length || 0) === (plansList?.length || 0)) {
                                      setSelectedPlan([]);
                                    } else {
                                      setSelectedPlan(plansList || []);
                                    }
                                  } else {
                                    const selectedPlansObjects = plansList?.filter((plan) =>
                                      newValue.includes(plan?.name)
                                    );
                                    setSelectedPlan(selectedPlansObjects || []);
                                  }
                                }}
                                renderOption={(props, option, { selected }) => {
                                  const isSelectAll = option === 'Select All';
                                  const isAllSelected = (selectedPlan?.length || 0) === (plansList?.length || 0);
                                  const isSomeSelected =
                                    selectedPlan?.length > 0 && selectedPlan?.length < (plansList?.length || 0);

                                  return (
                                    <li {...props}>
                                      <Checkbox
                                        checked={isSelectAll ? isAllSelected : selected}
                                        indeterminate={isSelectAll ? isSomeSelected : false}
                                        sx={{
                                          marginRight: 1,
                                          padding: 0,
                                          color: '#646464',
                                          borderRadius: '0px',
                                          '& .MuiSvgIcon-root': {
                                            fontSize: 18,
                                            border: '1px solid #000000',
                                            borderRadius: '4px',
                                            color: isSelectAll && isAllSelected ? '#2653A3' : selected ? '#2653A3' : 'transparent',
                                            backgroundColor: isSelectAll && isAllSelected ? '#e3f2fd' : selected ? '#e3f2fd' : 'transparent',
                                          },
                                          '&:hover .MuiSvgIcon-root': {
                                            borderColor: '#1976d2',
                                          },
                                        }}
                                      />
                                      {option}
                                    </li>
                                  );
                                }}
                                renderTags={(value, getTagProps) => {
                                  const visiblePlans = value.slice(0, 1);
                                  const remainingPlansCount = value.length - 1;
                                  return [
                                    ...visiblePlans.map((option, index) => (
                                      <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                        sx={{ fontSize: '12px', height: '3vh' }}
                                      />
                                    )),
                                    remainingPlansCount > 0 && (
                                      <Chip
                                        label={`+${remainingPlansCount}`}
                                        sx={{ fontSize: '12px', height: '3vh' }}
                                      />
                                    ),
                                  ];
                                }}
                              />
                            </Stack>
                          </Grid>

                        </Grid>
                        <Grid container spacing={{ xs: 1, md: 2 }}>
                          <Grid item xs={12} md={6}>
                            <Typography
                              sx={{
                                mt: 1,
                                fontSize: '13px',
                                color: '#2653A3',
                                fontWeight: '500',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              Start Time
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={['TimePicker']}
                                sx={{
                                  overflow: 'hidden',
                                  backgroundColor: 'white',
                                  mt: 1,
                                  p: 0,
                                }}
                              >
                                <MobileTimePicker
                                  value={selectedStartTime}
                                  // label="Start Time"
                                  onChange={handleMyTimeChange}
                                  sx={{
                                    fontSize: '12px',
                                    backgroundColor: 'white',
                                    '& .MuiClock-clock': {
                                      backgroundColor: '',
                                      borderRadius: '50%',
                                      border: '2px solid #ad1457',
                                    },
                                    '& .MuiClock-pin': {
                                      backgroundColor: '#ad1457',
                                    },
                                    '& .MuiInputLabel-root': {
                                      fontSize: '13px',
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      fontSize: '13px',
                                    },
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: 'small',
                                      required: true,
                                      fullWidth: true,
                                      InputProps: {
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <AccessTimeIcon sx={{ color: '#575757' }} />
                                          </InputAdornment>
                                        ),
                                      },
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography
                              sx={{
                                mt: 1,
                                fontSize: '13px',
                                color: '#2653A3',
                                fontWeight: '500',
                                fontFamily: 'Montserrat',
                              }}
                            >
                              End Time
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={['TimePicker']}
                                sx={{
                                  overflow: 'hidden',
                                  backgroundColor: 'white',
                                  mt: 1,
                                  p: 0,
                                }}
                              >
                                <MobileTimePicker
                                  value={selectedEndTime}
                                  // label="End Time"
                                  onChange={handleEndTimeChange}
                                  sx={{
                                    fontSize: '12px',
                                    backgroundColor: 'white',
                                    '& .MuiClock-clock': {
                                      backgroundColor: '',
                                      borderRadius: '50%',
                                      border: '2px solid #ad1457',
                                    },
                                    '& .MuiClock-pin': {
                                      backgroundColor: '#ad1457',
                                    },
                                    '& .MuiInputLabel-root': {
                                      fontSize: '13px',
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                      fontSize: '13px',
                                    },
                                  }}
                                  slotProps={{
                                    textField: {
                                      size: 'small',
                                      fullWidth: true,
                                      required: true,
                                      InputProps: {
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <AccessTimeIcon sx={{ color: '#575757' }} />
                                          </InputAdornment>
                                        ),
                                      },
                                    },
                                  }}
                                />

                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                        </Grid>

                      </Stack>
                      <Stack>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}></Grid>
                          <Grid item xs={12} md={5.9}></Grid>
                        </Grid>
                      </Stack>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3.5}
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '5px',
                      }}
                    >
                      <Typography
                        sx={{
                          ml: 1,
                          mt: 1,
                          fontSize: '13px',
                          color: '#2653A3',
                          fontWeight: '500',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        Upload Cover Image
                      </Typography>

                      <Stack
                        sx={{
                          p: 1.5,
                          m: 1,
                          borderRadius: '5px',
                          border: '1px dashed #000000',
                          height: { xs: '20vh', md: '25vh' },
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundImage: liveClassValues?.coverImage
                            ? `url(${liveClassValues?.coverImage})`
                            : 'none',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundColor: '#F5F5F5'
                        }}
                      >
                        {imageUploading ? (
                          <Loader />
                        ) : liveClassValues?.coverImage ? (
                          <></>
                        ) : (
                          <>
                          </>
                        )}
                        {!id && (
                          <>
                            <AddPhotoAlternateOutlinedIcon
                              sx={{ fontSize: '40px', color: '#5E6C84', pb: 1 }}
                            />
                            <Typography sx={{ fontSize: '13px', color: '#5E6C84', pb: 1, fontFamily: 'Montserrat', }}>
                              Upload Cover Image
                            </Typography>
                          </>
                        )}
                        <input
                          type="file"
                          name="images"
                          accept="image/*"
                          onChange={(e) => handleImageChange(e.target.files)}
                          style={{
                            border: '2px solid #5E6C84',
                            borderRadius: '5px',
                            padding: '8px',
                            backgroundColor: '#ffffff',
                            color: '#5E6C84',
                            fontSize: '10px',
                            fontWeight: '500',
                            cursor: 'pointer',
                            width: '70%',
                            marginTop: '10px',
                            fontFamily: 'Montserrat',
                          }}
                        />
                      </Stack>

                      <Typography
                        sx={{
                          ml: 1,
                          mt: 3,
                          fontSize: '13px',
                          color: '#2653A3',
                          fontWeight: '500',
                          fontFamily: 'Montserrat',
                        }}
                      >
                        Upload Notes
                      </Typography>
                      <Stack
                        sx={{
                          backgroundColor: '#F5F5F5',
                          p: 1.5,
                          m: 1,
                          borderRadius: '5px',
                          border: '1px dashed #000000',
                          height: { xs: '20vh', md: '25vh' },
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',

                          backgroundPosition: 'center',
                        }}
                      >
                        <AddPhotoAlternateOutlinedIcon
                          sx={{ fontSize: '40px', color: '#5E6C84', pb: 1 }}
                        />
                        <Typography
                          sx={{ fontSize: '13px', color: '#5E6C84', pb: 1, fontFamily: 'Montserrat', }}
                        >
                          Upload Document
                        </Typography>
                        {fileUploading && <Loader />}
                        <input
                          type="file"
                          onChange={(e) => handleNoteChange(e.target.files)}
                          style={{
                            border: '2px solid #5E6C84',
                            borderRadius: '5px',
                            padding: '8px',
                            backgroundColor: '#ffffff',
                            color: '#5E6C84',
                            fontSize: '10px',
                            fontWeight: '500',
                            cursor: 'pointer',
                            width: '70%',
                            marginTop: '10px',
                            fontFamily: 'Montserrat',
                          }}
                        />
                      </Stack>

                    </Grid>
                  </Grid>
                </Box>
                <Grid container sx={{ mt: 1, pb: { xs: 5, md: 0 }, p: '8px', ml: 1 }}>
                  <Grid item xs={12} md={12}>
                    <Stack
                      direction="row"
                      spacing={1}
                      display={'flex'}
                      alignItems={'center'}
                    >

                      {!id ? (
                        <>
                          <Button
                            size="large"
                            disabled={!currentCommunity?.googleAuth?.googleEmail || !isDataAvailable}
                            variant="contained"
                            sx={{
                              background:
                                'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                              textTransform: 'capitalize',
                              border: 'none',
                              fontFamily: 'Montserrat',
                              borderRadius: '8px',
                              boxShadow: 'none',
                              color: 'white',
                              '&:hover': {
                                background:
                                  'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                                cursor: 'pointer',
                                color: 'white',
                                border: 'none',
                                boxShadow: 'none',
                              },
                            }}
                            onClick={handleClickOpenPublish}

                          >
                            Schedule
                          </Button>
                        </>

                      ) : (
                        <>
                          <Button
                            size="large"
                            variant="contained"
                            sx={{
                              background:
                                'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                              textTransform: 'capitalize',
                              border: 'none',
                              borderRadius: '8px',
                              boxShadow: 'none',
                              fontFamily: 'Montserrat',
                              color: 'white',
                              '&:hover': {
                                background:
                                  'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                                cursor: 'pointer',
                                color: 'white',
                                border: 'none',
                                boxShadow: 'none',
                              },
                            }}
                            onClick={handleClickOpenPublish}

                          >
                            Reschedule
                          </Button>
                        </>
                      )}


                      <Typography sx={{ color: 'red' }}>
                        {' '}
                        {errorMessage}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        // ml: 1,
                        mt: 1,
                        fontSize: { xs: '12px', md: '15px' },
                        color: '#B92C0B',
                        fontWeight: '500',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      * Your meeting will be scheduled in Google meet
                    </Typography>


                    {/* Pop Up for About us Content */}
                    <Dialog
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}

                      open={opensPublish}
                      onClose={handleClickClosesPublish}
                    >
                      <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ backgroundColor: '#F0F9FF' }}
                        >
                          <Typography
                            sx={{
                              fontSize: '20px',
                              fontWeight: 500,
                              textDecoration: 'none',
                              color: '#3C5AA0',
                              fontFamily: 'Montserrat',

                              marginLeft: 2,
                            }}
                          >
                            {'Schedule the Meeting?'}
                          </Typography>
                          <Box
                            onClick={handleClickClosesPublish}
                            sx={{
                              backgroundColor: '#3C5AA0',
                              padding: '4px',
                              borderRadius: '50%',
                              width: '20px',
                              height: '20px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: 'white',
                              fontSize: '10px',
                              mt: '0.5px',
                              mr: '10px',
                              cursor: 'pointer',
                              '&:hover': {
                                cursor: 'pointer',
                                border: 'none',
                              },
                            }}
                          >
                            <Close sx={{ fontSize: '15px' }} />
                          </Box>
                        </Grid>
                      </DialogActions>

                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          sx={{ fontSize: '13px', textAlign: 'center', fontFamily: 'Montserrat', }}
                        >
                          Are you sure you want to schedule the meeting?
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button
                          onChange={(e: any) => setLiveClassValues(e.target.value)}
                          onClick={handleSubmit}
                          size="large"
                          variant="contained"
                          sx={{
                            background:
                              'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                            textTransform: 'capitalize',
                            border: 'none',
                            borderRadius: '8px',
                            boxShadow: 'none',
                            color: 'white',
                            fontFamily: 'Montserrat',
                            '&:hover': {
                              background:
                                'linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)',
                              cursor: 'pointer',
                              color: 'white',
                              border: 'none',
                              boxShadow: 'none',
                            },
                          }}
                        >
                          {loader ? (
                            <CircularProgress
                              size={24}
                              sx={{ color: 'white' }}
                            />
                          ) : (
                            <>
                              {id ? "Reschedule" : "Schedule"}
                            </>
                          )}
                        </Button>
                        <Button
                          size="large"
                          sx={{
                            textTransform: 'capitalize',
                            // fontWeight:600,
                            color: '#50A1CA',
                            fontFamily: 'Montserrat',
                            border: '1px solid #50A1CA',
                            borderRadius: '8px',
                            boxShadow: 'none',
                            '&:hover': {
                              cursor: 'pointer',
                              border: '1px solid #50A1CA',
                              boxShadow: 'none',
                            },
                          }}
                          onClick={handleClickClosesPublish}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              </>
            </Box>
          </Stack>
        )}
      </div>
      {
        !currentCommunity?.googleAuth?.googleEmail &&
        <Dialog open={openConnect}
          fullWidth
          maxWidth="sm"
          // sx={{
          // '& .MuiDialog-paper': {
          // height: 'auto',
          // // minHeight: { xs: '100px', md: '200px' },
          // },
          // }}
          BackdropProps={{
            sx: {
              backdropFilter: 'blur(4px)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}>
          <DialogContent sx={{ textAlign: 'center', fontSize: { xs: "12px", md: '16px', fontWeight: 'bold' } }}>
            Sync your calendar for automatic 1-on-1 scheduling
          </DialogContent>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={{ xs: 1, md: 3 }} sx={{ py: { xs: 4, md: 4 } }}>
            <Box
              component={'img'}
              src={GooglesIcon}
              width={'25px'}
              height={'25px'}
            />
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
              {currentCommunity?.googleAuth?.googleEmail ? (
                <Typography>
                  {currentCommunity.googleAuth.googleEmail}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Montserrat' }}>
                  Connect your Gmail account
                </Typography>
              )}
            </Typography>
            {currentCommunity?.googleAuth?.googleEmail ? (
              <>
                <Button
                  size='small'
                  variant='outlined'
                  sx={{
                    color: "#3C5AA0",
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    border: '1px solid #3C5AA0',
                    fontFamily: 'Montserrat',
                    width: { xs: 110, md: 100 },
                  }}
                  onClick={handleSubmitGenerateAuth}
                >
                  change
                </Button>
              </>
            ) : (
              <>
                <Button
                  size='small'
                  variant='outlined'
                  sx={{
                    color: "#3C5AA0",
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    border: '1px solid #3C5AA0',
                    fontFamily: 'Montserrat',
                    width: { xs: 110, md: 100 },
                  }}
                  onClick={handleSubmitGenerateAuth}
                >
                  connect
                </Button>
              </>
            )}
          </Stack>

        </Dialog>
      }

    </>
  );
};
export default AddLiveClass;