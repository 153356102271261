import { Stack, Typography } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TrendingAllCommunities from '../AllCommunities/TrendingAllCommunities';
import { useCommunity } from '../../../hooks/useCommunity';
import { ICommunity } from '../../../models/communities.modal';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SmallLoader from '../../Loader/SmallLoader';

const TrendingCommunities = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 6,
      slidesToSlide: 3,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 2.5,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const { getTrendingCommunities } = useCommunity();
  const [trendingCommunities, setTrendingCommunities] = useState<ICommunity[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const trending = await getTrendingCommunities();
      // console.log(trending?.data,'Hii');
      setIsLoading(false);
      setTrendingCommunities(trending?.data);
    }
    fetchData();
  }, []);

  return (
    <>
      <Stack>
        <Stack
          direction={'row'}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            mt: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '14px', md: '18px' },
              fontWeight: 700,
              textDecoration: 'none',
              color: '#000000',
              marginLeft: 2,
            }}
          >
            Trending Communities
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              color: '#50A1CA',
              textAlign: 'right',
              alignItems: 'end',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => navigate(`/trending-communities`)}
          >
            {trendingCommunities?.length} Communities
          </Typography>
        </Stack>
        {isLoading ? (
          <Stack
            sx={{
              color: 'grey.500',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
            }}
            spacing={2}
            direction="row"
          >
            <SmallLoader />
          </Stack>
        ) : (
          <Carousel showDots={false} arrows={true} responsive={responsive}>
            {trendingCommunities?.map((item) => (
              <div key={item._id}>
                <TrendingAllCommunities {...item} />
              </div>
            ))}
          </Carousel>
        )}
      </Stack>
    </>
  );
};

export default TrendingCommunities;
