// import { red } from "@mui/material/colors";

export const styles = {
  '& .expanded': {
    padding: '6px',
    marginRight: '15px',
    marginLeft: '15px',
    backgroundColor: '#ffffff',
  },
  '& .css-356bzd': {
    backgroundColor: 'white',
  },
  '& .collapsed': {
    '& .menu-item>*': {
      flexGrow: 1,
    },
    '& .nav-link': {
      border: 'none',
      borderRadius: '0px',
    },
  },
  '&  .collapsed': {
    margin: '8px',
    backgroundColor: 'white',
  },
  '& .nav-link': {
    // border: "1px solid #E0E0E0",
    borderRadius: '4px',
    padding: '8px',
    color: '#515151',
    fontSize: '12px',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight:500,

    '&.active': {
      backgroundColor: '#2952A20A',
      color: '#3760A9',
      fontWeight: '600',
      borderRadius: '8px',
      '& svg': {
        //  color: "#50A1CA ",
        fill: '#3760A9',
      },
    },
    '&:hover': {
      backgroundColor: '#2952A20A',
      color: '#3760A9',
      borderRadius: '8px',
      '& svg': {
        color: '#3760A9',
      },
    },
    '& svg': {
      color: 'gray',
      marginRight: 1,
      marginLeft:0.5,
    },
    '& ul': {
      fontSize: '1px',
      lineHeight: 2,
    },
    '& menu-item': {
      backgroundColor: 'red',
    },
  },
};
