import {
    Box,
    InputAdornment,
    Stack,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import { useState } from "react";
import TrendingAllCommunitiesNew from "./TrendingCommunitiesNew";
import AdminCommunitiesNew from "./AdminCommunitiesNew";
import JoinedCommunitiesNew from "./JoinCommunitiesNew";
import { SearchIcon } from "../../../assets/icons";
import InvitesCommunitiesNew from "./InvitesCommuntiesNew";
import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';

const ExploreCommunity = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [value, setValue] = useState<number>(0);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const TabPanel = ({
        children,
        value,
        index,
    }: {
        children: React.ReactNode;
        value: number;
        index: number;
    }) => (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            sx={{ px: 2 }}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );

    return (
        <>
            {/* Search Bar */}
            <Stack
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    pt: 2,
                    borderRadius: '10px',
                }}
            >
                <TextField
                    size="small"
                    placeholder="Search & join community"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearch}
                    sx={{
                        display: { xs: 'block', md: 'block' },
                        width: { xs: '150%', md: '300%' },
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '30px',
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#575757', mt: 1 }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            {/* Tabs Section */}
            <Stack
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pt: 2,
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            gap: { xs: '5px', md: '10px' },
                        },
                        '& .MuiTab-root': {
                            borderRadius: '30px',
                            textTransform: 'none',
                            color: '#565656',
                            transition: 'all 0.3s',
                            fontSize: { xs: '10px', md: '12px' },
                            border: '1px solid #E0E0E0',
                            height: { xs: '25px', md: '40px' },
                            minHeight: 'unset',
                            width: { xs: 'auto', md: 'fit-content' },
                        },
                        '& .MuiTab-root.Mui-selected': {
                            backgroundColor: 'white',
                            color: '#2952A2',
                            border: '1px solid #2952A2',
                        },
                        '& .MuiTab-root:hover': {
                            backgroundColor: '#2952A2',
                            color: 'white',
                        },
                        '& .MuiTabs-indicator': {
                            display: 'none',
                        },
                    }}
                >
                    <Tab label="Created" />
                    <Tab label="Joined" />
                    <Tab label="Trending" />
                    <Tab label="Invites" />
                </Tabs>
            </Stack>

            {/* Tab Content Section */}
            <Stack
                sx={{
                    mx: 2,
                    px: 2,
                    borderRadius: '10px',
                    width: '100vw',

                }}
            >
                {/* Tab Panels */}
                <TabPanel value={value} index={0}>
                    <SimpleBar
                        style={{
                            maxHeight: '50vh',
                            borderRadius: '10px',
                            backgroundColor: '#F4F4F4',
                        }}
                    >
                        <Stack
                            sx={{
                                p: 1,
                                backgroundColor: '#F4F4F4',
                                minHeight: '50vh'
                            }}
                        >
                            <AdminCommunitiesNew searchQuery={searchQuery} data={[]} />
                        </Stack>
                    </SimpleBar>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <SimpleBar
                        style={{
                            maxHeight: '50vh',
                            borderRadius: '10px',
                            backgroundColor: '#F4F4F4',
                        }}
                    >
                        <Stack
                            sx={{
                                p: 1,
                                backgroundColor: '#F4F4F4',
                                minHeight: '50vh'
                            }}
                        >
                            <JoinedCommunitiesNew searchQuery={searchQuery} />
                        </Stack>
                    </SimpleBar>
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <SimpleBar
                        style={{
                            maxHeight: '50vh',
                            borderRadius: '10px',
                            backgroundColor: '#F4F4F4',
                        }}
                    >
                        <Stack
                            sx={{
                                p: 1,
                                backgroundColor: '#F4F4F4',
                                minHeight: '50vh'
                            }}
                        >
                            <TrendingAllCommunitiesNew searchQuery={searchQuery} />
                        </Stack>
                    </SimpleBar>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <SimpleBar
                        style={{
                            maxHeight: '50vh',
                            borderRadius: '10px',
                            backgroundColor: '#F4F4F4',
                        }}
                    >
                        <Stack
                            sx={{
                                p: 1,
                                backgroundColor: '#F4F4F4',
                                minHeight: '50vh'
                            }}
                        >
                            <InvitesCommunitiesNew searchQuery={searchQuery} />
                        </Stack>
                    </SimpleBar>
                </TabPanel>
            </Stack>
        </>
    );
};

export default ExploreCommunity;
