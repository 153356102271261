import { useNavigate, useParams } from "react-router-dom";
import { ICreateCommunity } from "../../../models/communities.modal";
import { updateCommunityService } from "../../../services/communities.service";
import { useContext, useState } from "react";
import { AuthContext, IAuthContext } from "../../../contexts/Auth.context";
import { useCommunity } from "../../../hooks/useCommunity";
import { useDispatch } from "react-redux";
import React from "react";
import { useSnackbar } from "notistack";
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import TypographyField from "../../AppComponents/Buttons/Typography";
import Congrats from "./CreateCommunityFinal";
import Logo from '../../../assets/images/Communn-Logo.png'
import Logo2 from '../../../assets/images/Communn-favicon.png'


interface IProps {
    CommunityData: ICreateCommunity | null;
    updateCommunityData: (data: Partial<ICreateCommunity>) => void;
    setCommunityData: any;
}


export interface IUpdateCommunity {
    community: string;
    zipCode: number;
    fullAddress: string;
    description: string;
    vision: string;
    mission: string;
    adminMessage: string;
    directorMessage: string;
    phoneNumber: number;
    email: string;
    location: string;
    mobileNumber: number;
    logo: string;
    banner: string;
    gallery: string[];

}




const CreateCommunityNew2 = ({ CommunityData,
    updateCommunityData,
    setCommunityData,
}: IProps) => {

    const { id } = useParams();
    const { getAccessToken } = useContext<IAuthContext>(AuthContext);
    const { createNewCommunity } = useCommunity();
    const navigate = useNavigate();
    const [page, setPage] = useState(2);
    const dispatch = useDispatch();

    const [selectedValue, setSelectedValue] = React.useState('PRIVATE');
    const [selectedSubscription, setSelectedSubscription] = React.useState('YES');
    // eslint-disable-next-line
    // const [isSelectionMade, setIsSelectionMade] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { getCommunityById } = useCommunity();
    const [errorMessage, setErrorMessage] = useState('');

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        updateCommunityData({ [name]: value });
    };

    // console.log(CommunityData);
    const handleButtonChanges = (event: any) => {
        updateCommunityData({ type: event });
    };
    const handleButtonSubscription = (event: any) => {
        updateCommunityData({ collectSubscription: event });
    };


    const handleSubmit = async () => {
        if (
            CommunityData &&
            CommunityData.maxMembers &&
            CommunityData.maxMembers < 0
        ) {
            setErrorMessage('Max members must be a positive value');
            return;
        }
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        try {
            const communityObject = { community: CommunityData };
            console.log(communityObject, 'cccc');
            if (id) {
                const response = await updateCommunityService(
                    getAccessToken(),
                    id,
                    CommunityData
                );
                if (response && response.status === 200) {
                    navigate('/communities');
                    enqueueSnackbar('Community updated successfully', {
                        variant: 'success',
                    });
                } else {
                    enqueueSnackbar('Community updated failed', { variant: 'error' });
                }
            } else {
                const response = await createNewCommunity(CommunityData);
                // // if (response && response.status === 201) {
                // //     await getCommunityById(response.data._id);
                // //     navigate('/activeStep=ONBOARDING_STEP_COPY_LINK_PAGE', {
                // //         state: { id: response?.data._id },
                // //     });
                // //     dispatch(loadSelectedCommunity(response.data));
                // } 
                return response
            }
        } catch (error) {
            console.log('An error occurred:', error);
        }
    };

    // Toolpit text
    //     const subscription = `
    //    <strong>Subscription:</strong>
    //    Offer exclusive content or benefits.
    //     Manage business memberships and track payments through
    //      Communn and generate recurring revenue<br/>
    // <strong>No Subscription:</strong>
    // Keep your community open and accessible to all without paid restrictions.
    //  `;

    //     const type = `
    //  Consider your community's nature and intended audience to make the right choice<br />
    //  <strong>Private Community:</strong> A group only for those with an invitation, like a secret club.<br />
    //  <strong>Public Community:</strong> A community that's open for everyone, like a public park.
    // `;

    // const locations = useLocation();
    // const isExploreCreateCommunityPage =
    //     locations.pathname === '/explore-create-community';

    const isSaveButtonEnabled = () => {
        const val = selectedValue && selectedSubscription !== '' ? true : false;

        return val;
    };
    return (
        <>
            {page === 2 && (
                <>
                    <Stack direction={'column'} spacing={1} height={'100%'}>
                        <Grid container sx={{ height: '100vh', p: 2 }} gap={2}>
                            <Grid
                                item
                                xs={12}
                                md={5.9}
                                sx={{
                                    backgroundColor: '#F9F9F9',
                                    display: { xs: 'none', md: 'flex' },
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    p: 10,
                                    borderRadius: '10px'
                                }}
                            >
                                <Box
                                    component={'img'}
                                    src={Logo}
                                    width={220}

                                />

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={5.9}
                                sx={{
                                    display: 'flex',
                                    justifyContent: { xs: 'flex-start', md: 'center' },
                                    flexDirection: 'column',
                                    p: { xs: 1, md: 5 },
                                    pt: { xs: 5, md: 0 }
                                }}
                            >
                                <Stack sx={{ p: 2, display: { xs: 'block', md: 'none' } }}>
                                    <Box
                                        component={'img'}
                                        src={Logo2}
                                        width={50}

                                    />
                                </Stack>
                                <Stack>
                                    <TypographyField
                                        name="Create Community"
                                        fontSize="22px"
                                        fontWeight={600}
                                        textAlign="justify"
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", md: "14px", },
                                            fontFamily: "Montserrat",
                                            fontWeight: '400',
                                            textAlign: 'justify'
                                        }}>
                                        Create a vibrant space for like-minded individuals.
                                    </Typography>
                                </Stack>
                                <Stack sx={{ mt: 5 }}>

                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", md: "14px", },
                                            fontFamily: "Montserrat",
                                            fontWeight: '500',
                                            textAlign: 'justify'
                                        }}>
                                        Community Name
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        fullWidth
                                        sx={{
                                            mt: 1,
                                            color: '#646464',
                                            fontWeight: '500',
                                            borderRadius: '8px',
                                            backgroundColor: 'white',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '8px',
                                                '& fieldset': {
                                                    borderColor: '#E7EBF1',
                                                    borderRadius: '8px',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#E7EBF1',
                                                },
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#E7EBF1',
                                            },
                                        }}
                                        placeholder="Community or business type"
                                        inputProps={{
                                            style: {
                                                padding: 11,
                                                fontSize: '12px',
                                                color: '#646464',
                                                fontWeight: 400,
                                                fontFamily: 'Montserrat',
                                            },
                                        }}
                                        size="small"
                                        variant="outlined"
                                        onChange={handleChanges}
                                        value={`onecommunn.com/${CommunityData?.name}`}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { zIndex: 0 },
                                        }}
                                    />
                                </Stack>
                                <Stack sx={{ mt: 2 }}>
                                    <Typography
                                        sx={{
                                            fontSize: { xs: "12px", md: "14px", },
                                            fontFamily: "Montserrat",
                                            fontWeight: '500',
                                            textAlign: 'justify'
                                        }}>
                                        Selected Category
                                    </Typography>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        fullWidth
                                        sx={{
                                            mt: 1,
                                            color: '#646464',
                                            fontWeight: '500',
                                            borderRadius: '8px',
                                            backgroundColor: 'white',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '8px',
                                                '& fieldset': {
                                                    borderColor: '#E7EBF1',
                                                    borderRadius: '8px',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#E7EBF1',
                                                },
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#E7EBF1',
                                            },
                                        }}
                                        placeholder="Selected Category"
                                        inputProps={{
                                            style: {
                                                padding: 11,
                                                fontSize: '12px',
                                                color: '#646464',
                                                fontWeight: 400,
                                                fontFamily: 'Montserrat',
                                            },
                                        }}
                                        size="small"
                                        variant="outlined"
                                        onChange={handleChanges}
                                        value={
                                            (CommunityData?.category === 'Other'
                                                ? CommunityData?.otherCategory
                                                : CommunityData?.category) ||
                                            (CommunityData?.otherCategory === ''
                                                ? CommunityData?.category
                                                : CommunityData?.otherCategory)
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { zIndex: 0 },
                                        }}
                                    />
                                </Stack>

                                <Stack sx={{ mt: 2 }} display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'space-between'}>
                                    <Stack>

                                        <Typography
                                            sx={{
                                                fontSize: { xs: "12px", md: "14px", },
                                                fontFamily: "Montserrat",
                                                fontWeight: '500',
                                                textAlign: 'justify'
                                            }}>
                                            Privacy ?
                                        </Typography>
                                        <RadioGroup
                                            row
                                            value={selectedValue}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                setSelectedValue(value);
                                                handleButtonChanges(value);
                                                // setIsSelectionMade(true);
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderRadius: '4px',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="PUBLIC"
                                                    control={
                                                        <Radio

                                                        />
                                                    }
                                                    label="Public"
                                                    sx={{
                                                        '& .MuiFormControlLabel-label': {
                                                            fontFamily: 'Montserrat',
                                                            fontSize: '12px'
                                                        },
                                                    }}

                                                />
                                                <FormControlLabel
                                                    value="PRIVATE"
                                                    control={
                                                        <Radio
                                                        />
                                                    }
                                                    label="Private"
                                                    sx={{
                                                        '& .MuiFormControlLabel-label': {
                                                            fontFamily: 'Montserrat',
                                                            fontSize: '12px'
                                                        },
                                                    }}

                                                />
                                            </Box>
                                        </RadioGroup>
                                    </Stack>
                                    <Stack>

                                        <Typography
                                            sx={{
                                                fontSize: { xs: "12px", md: "14px", },
                                                fontFamily: "Montserrat",
                                                fontWeight: '500',
                                                textAlign: 'justify'
                                            }}>
                                            Do you collect subscription ?
                                        </Typography>
                                        <RadioGroup
                                            row
                                            value={selectedSubscription}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                setSelectedSubscription(value);
                                                handleButtonSubscription(value);
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderRadius: '4px',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="YES"
                                                    control={
                                                        <Radio
                                                        />
                                                    }
                                                    label="Yes"
                                                    sx={{
                                                        '& .MuiFormControlLabel-label': {
                                                            fontFamily: 'Montserrat',
                                                            fontSize: '12px'
                                                        },
                                                    }}
                                                />
                                                <FormControlLabel
                                                    value="NO"
                                                    control={
                                                        <Radio
                                                        />
                                                    }
                                                    label="No"
                                                    sx={{
                                                        '& .MuiFormControlLabel-label': {
                                                            fontFamily: 'Montserrat',
                                                            fontSize: '12px'
                                                        },
                                                    }}

                                                />
                                            </Box>
                                        </RadioGroup>
                                    </Stack>

                                </Stack>

                                <Stack sx={{ pt: 2 }}>
                                    <Button
                                        fullWidth
                                        onClick={async () => {
                                            const success = await handleSubmit();
                                            if (success) {
                                                setPage(3);
                                            }
                                        }}
                                        variant="contained"
                                        // disabled={!isSaveButtonEnabled()}
                                        sx={{
                                            boxShadow: 'none',
                                            backgroundColor: '#2653A3',
                                            textTransform: 'capitalize',
                                            borderRadius: '8px',
                                            fontFamily: 'Montserrat',
                                            border: '1px solid #2653A3',
                                            fontWeight: 500,
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'white',
                                                cursor: 'pointer',
                                                boxShadow: 'none',
                                                color: '#2653A3',
                                            },
                                        }}
                                    >
                                        Create Community
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </>
            )}
            {page === 3 && (
                <Congrats
                    CommunityData={CommunityData}
                    updateCommunityData={updateCommunityData}
                    setCommunityData={setCommunityData}
                />
            )}

        </>
    )
}
export default CreateCommunityNew2;