import React from 'react';
import { Avatar, Divider, Grid, Stack, Typography } from '@mui/material';
import { Message } from '../../../models/BankAcc.models';

interface PopUpProps {
  data: Message[] | undefined;
}
const CommentsPopUp: React.FC<PopUpProps> = ({ data }) => {
  const convertToIST = (utcTimestamp: string) => {
    const istTimestamp = new Date(utcTimestamp);
    istTimestamp.setHours(istTimestamp.getHours() + 5); // Add 5 hours for IST
    istTimestamp.setMinutes(istTimestamp.getMinutes() + 30); // Add 30 minutes for IST

    return istTimestamp.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };
  return (
    <Grid container spacing={2} sx={{ pt: 0 }}>
      <Grid item xl={12} md={12} xs={12} sx={{ pt: 0 }}>
        {data?.map((comment) => (
          <>
            <Stack sx={{ display: 'flex', flexDirection: 'row', pt: 1.5 }}>
              <Avatar
                src={comment?.createdBy?.avatar}
                sx={{ height: '30px', width: '30px' }}
                alt={`${comment?.createdBy?.firstName}`}
              />
              <Stack sx={{ pl: '20px' }}>
                <Typography
                  sx={{
                    fontSize: '11px',
                    color: '#575757',
                    fontWeight: '500',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {comment?.createdBy?.firstName} {comment?.createdBy?.lastName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '11px',
                    color: '#575757',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {comment?.message ? comment.message : 'No Message'}
                </Typography>
              </Stack>
            </Stack>
            <Typography
              sx={{
                fontSize: '10px',
                color: '#575757',
                textAlign: 'end',
                p: 0,
                fontFamily: 'Montserrat',
              }}
            >
              {convertToIST(comment?.createdAt)}
            </Typography>

            <Divider
              sx={{
                border: '0.5px dashed  #AAA',
                color: '#000',
                fontFamily: 'Montserrat',
              }}
            />
          </>
        ))}
      </Grid>
    </Grid>
  );
};

export default CommentsPopUp;
