import { Stack } from '@mui/material';
import { RotatingLines } from "react-loader-spinner"

export const Loader = () => {


  return (
    <Stack
      sx={{
        color: 'grey.500',
        display: 'flex',
        // backgroundColor: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
      }}
      spacing={2}
      direction="row"
    >
      {/* <CircularProgress color="inherit" /> */}
      {/* <Box
        component={'img'}
        src={require('../../assets/images/loader.gif')}
        alt=""
        sx={{ width: '50%' }}
      /> */}

      <RotatingLines
        visible={true}
        width="30"
        strokeWidth="5"
        strokeColor="#777777"
        ariaLabel="rotating-lines-loading"
      />
    </Stack>
  );
};

export default Loader;
