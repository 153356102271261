import { useContext } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { IAddAppointment } from "../components/Appointments/AddAppointment";
import { bookAppointment, bookAvailability, cancelAppointment, fetchAllAppointmentBookings, fetchAllAppointments, fetchAllPastAppointments, fetchAppointmentById, fetchAttendeesById, updateAppointment, updateAppointmentStatus } from "../services/appointment.service";
import { IMeetingPayload } from "../models/appointments.models";




export const useAppointments = ()=> {


    const { getAccessToken } = useContext<IAuthContext>(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();



    const createAppointment = async (details: IAddAppointment) => {
        try {
          const response = await bookAppointment(getAccessToken(), details);
          if (response?.status === 201) {
            enqueueSnackbar('Appointment added successfully.', { variant: 'success' });
            navigate('/appointments');
          } else {
            enqueueSnackbar("Couldn't create appointment", {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
          return response
        } catch (error) {
          enqueueSnackbar("Couldn't create appointment", {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      };


      const updateAppointmentById = async (details: IAddAppointment, appointmentId:string, communityId:string ) => {
        try {
          const response = await updateAppointment(getAccessToken(), details, appointmentId, communityId );
          if(response?.status===200){
            enqueueSnackbar('Appointment updated successfully.', { variant: 'success' });
            navigate('/appointments');
          }else {
            enqueueSnackbar("Couldn't updated appointment", {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
          return { class: response };
        } catch (error) {
          enqueueSnackbar("Couldn't updated appointment", {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      };

      const updateAppointmentStatusById = async (status:string, appointmentId:string, communityId:string ) => {
        try {
          const response = await updateAppointmentStatus(getAccessToken(), status, appointmentId, communityId );
          if(response?.status===200){
            enqueueSnackbar('Appointment updated successfully.', { variant: 'success' });
            navigate('/appointments');
          }else {
            enqueueSnackbar("Couldn't updated appointment", {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
          return { class: response };
        } catch (error) {
          enqueueSnackbar("Couldn't updated appointment", {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      };



      const cancelAppointmentById = async (
        appointmentId: string,
        communityId: string,
        availabilityId: string,
        timeSlotId: string
      ) => {
        try {
          const response = await cancelAppointment(
            getAccessToken(),
            appointmentId,
            communityId,
            availabilityId,
            timeSlotId
          );
          if (response?.status === 200) {
            enqueueSnackbar('Appointment cancelled successfully.', {
              variant: 'success',
            });
            // navigate('/appointments');
          } else {
            enqueueSnackbar("Couldn't updated appointment", {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
          return { class: response };
        } catch (error) {
          enqueueSnackbar("Couldn't updated appointment", {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      };
    
      

      const createAvailability = async (details: IMeetingPayload) => {
        try {
          const response = await bookAvailability(getAccessToken(), details);
          return response
        } catch (error) {
          enqueueSnackbar("Couldn't create appointment", {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      };


      const getAllAppointments = async (id: string) => {
        try {
          const response = await fetchAllAppointments(getAccessToken(), id);
          if (response.status === 200) {
            return response;
          }
          return response;
        } catch (error) {
        }
      };


      const getAttendeesById = async (id: string) => {
        try {
          const response = await fetchAttendeesById(getAccessToken(), id);
          if (response.status === 200) {
            return response;
          }
          return response;
        } catch (error) {
        }
      };


      const getAllBookings = async (id: string) => {
        try {
          const response = await fetchAllAppointmentBookings(getAccessToken(), id);
          if (response.status === 200) {
            return response;
          }
          return response;
        } catch (error) {
        }
      };


      const getAllPastAppointments = async (id: string) => {
        try {
          const response = await fetchAllPastAppointments(getAccessToken(), id);
          if (response.status === 200) {
            return response;
          }
          return response;
        } catch (error) {
    
        }
      };
    


      const getAppointmentById = async (appointmentId: string) => {
        try {
          const response = await fetchAppointmentById(getAccessToken(), appointmentId);
          if (response.status === 200) {
            return response;
          }
          return response;
        } catch (error) {
          console.log(error, "error")
        }
      };
    


    return {
        createAppointment,
        getAllAppointments,
        createAvailability,
        getAppointmentById,
        updateAppointmentById,
        getAllPastAppointments,
        getAllBookings,
        getAttendeesById,
        updateAppointmentStatusById,
        cancelAppointmentById
    }

}