import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Stack } from '@mui/system';
import { ICommunityList } from '../../models/communities.modal';
import { CustomTableHeader } from './components/CustomTableHeader';
import { IColumn } from '../../models/table.model';
import { UserInfoCell } from './components/UserInfoCell';
import { TextContentCell } from './components/TextContentCell';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {
    IconButton,
    Paper,
    TableContainer,
    TablePagination,
    Tooltip,
    Typography,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { getStaticValue } from '../../utils/StaticValues';
import { AttachementIcon, EditIcon, Group2Icon } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { GetColor } from './GetColor';
import { useDispatch, useSelector } from 'react-redux';
import { loadSelectedCommunity } from '../../store/slices/CommunitySlice';
// import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';

export interface ISortingState {
    [key: string]: 'asc' | 'desc';
}

interface ITableProps {
    data: ICommunityList[];
    columns: IColumn[];
    onActionClick: (a: any, e?: React.MouseEvent<HTMLButtonElement>) => void;
    checkboxes: { [key: string]: boolean };
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    markedAsFilter: string;
    categoryFilter: string;
    statusFilter: string[];
    membershipFilter: string[];
    onRowClick?: (selectedCommunity: ICommunityList) => void;
}

export const SuperAdminCommunities = ({ columns, data }: ITableProps) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortDirections, setSortDirections] = useState<{
        [key: string]: 'asc' | 'desc' | undefined;
    }>({});
    const [sortedColumns, setSortedColumns] = useState<string[]>([]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };
    const selectedCommunity = useSelector(
        (state: any) => state?.selectedCommunity
    );

    const selectedCommunityId = selectedCommunity.selectedCommunity._id;
    useEffect(() => {
        handleChangePage(null, 0);
    }, [data, selectedCommunity]);
    const dispatch = useDispatch();
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (columnTitle: string) => {
        setSortDirections((prevSortDirections) => {
            let newSortDirections: { [key: string]: 'asc' | 'desc' | undefined } = {};
            let newSortedColumns: string[] = [];

            // Toggle the sorting direction for the clicked column
            if (prevSortDirections[columnTitle] === 'asc') {
                newSortDirections[columnTitle] = 'desc';
            } else {
                newSortDirections[columnTitle] = 'asc';
            }

            // Include the clicked column in the list of sorted columns
            newSortedColumns.push(columnTitle);

            setSortedColumns(newSortedColumns);

            return newSortDirections;
        });
    };

    const navigate = useNavigate();

    // Sorting logic using the sorting state
    const sortedData = [...data];

    sortedData.sort((a: any, b: any) => {
        if (a._id === selectedCommunityId) return -1; // Move selected community to the beginning
        if (b._id === selectedCommunityId) return 1;
        return 0; // Maintain the original order for other items
    });
    const sortedColumnTitles = sortedColumns.filter(
        (columnTitle) => sortDirections[columnTitle] !== undefined
    );

    if (sortedColumnTitles.length > 0) {
        sortedData.sort((a: ICommunityList, b: ICommunityList) => {
            for (const columnTitle of sortedColumnTitles) {
                const sortDirection = sortDirections[columnTitle];
                const column = columns.find((col) => col.title === columnTitle);

                if (column) {
                    if (column.dataKey === 'admin') {
                        // Sorting for the "admin" column based on the "name" property
                        const aValue = `${a?.createdBy?.firstName} ${a?.createdBy?.lastName}`;
                        const bValue = `${b?.createdBy?.firstName} ${b?.createdBy?.lastName}`;

                        if (sortDirection === 'asc') {
                            return aValue?.localeCompare(bValue);
                        } else {
                            return bValue?.localeCompare(aValue);
                        }
                    } else if (column.dataKey === 'category') {
                        // Sorting for the "category" column
                        const aValue = a[column.dataKey];
                        const bValue = b[column.dataKey];

                        if (sortDirection === 'asc') {
                            return aValue.localeCompare(bValue);
                        } else {
                            return bValue.localeCompare(aValue);
                        }
                    } else if (column.dataKey === 'earnings') {
                        // Sorting for the "category" column
                        const aValue = a[column.dataKey];
                        const bValue = b[column.dataKey];

                        if (sortDirection === 'asc') {
                            return aValue < bValue;
                        } else {
                            return bValue > aValue;
                        }
                    } else if (column.dataKey === 'created/updated') {
                        // Sorting for the "created/updated" column based on date and time

                        const aValueDate = new Date(a?.updated?.date);
                        const bValueDate = new Date(b?.updated?.date);

                        if (aValueDate.getTime() !== bValueDate.getTime()) {
                            // If the dates are not the same, sort based on date
                            if (sortDirection === 'asc') {
                                return aValueDate.getTime() - bValueDate.getTime();
                            } else {
                                return bValueDate.getTime() - aValueDate.getTime();
                            }
                        } else {
                            // If dates are the same, include time for sorting
                            const aValueTime = new Date(
                                `${a?.updated?.date}T${a?.updated?.time}`
                            );
                            const bValueTime = new Date(
                                `${b?.updated?.date}T${b?.updated?.time}`
                            );

                            if (sortDirection === 'asc') {
                                return aValueTime.getTime() - bValueTime.getTime();
                            } else {
                                return bValueTime.getTime() - aValueTime.getTime();
                            }
                        }
                    } else {
                        // Default sorting for other columns
                        const aValue = a[column.dataKey];
                        const bValue = b[column.dataKey];

                        if (sortDirection === 'asc') {
                            return aValue.localeCompare(bValue);
                        } else {
                            return bValue.localeCompare(aValue);
                        }
                    }
                }
            }
            return 0;
        });
    }

    // Pagination logic
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    const paginatedData = sortedData.slice(startIndex, endIndex);





    return (
        <>
            <PerfectScrollbar>
                {/* <SimpleBar style={{ height: "75vh" }}> */}
                <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{
                        height: { xs: 'calc(100vh - 45vh)', md: 'calc(100vh - 25vh)' },
                    }}
                >
                    <Table
                        sx={{
                            '& th': {
                                fontSize: '12px',
                                fontWeight: 700,
                                color: '#565656',
                                p: 1,
                            },
                            '& td': {
                                fontSize: '15px',
                                fontWeight: 500,

                                color: '#1A1A1A',
                                p: '5px 10px',
                                '& .MuiTypography-root': { m: 0, fontSize: '11px' },
                                '& .MuiTypography-root:nth-child(2)': {
                                    m: 0,
                                    fontSize: '6px',
                                    color: '#56565680',
                                    fontWeight: 600,
                                },
                            },
                        }}
                    >
                        <TableHead
                            style={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                background: '#ffffff',
                            }}
                        >
                            <TableRow sx={{ ml: 0 }}>
                                {columns.map((column: IColumn, i: number) => (
                                    <TableCell key={i} onClick={() => handleSort(column.title)}>
                                        <CustomTableHeader
                                            title={column.title}
                                            sortDirection={sortDirections[column.title]}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData?.map((item: ICommunityList, i: number) => (
                                <TableRow hover key={i} onClick={() => {
                                    navigate(`/home`);
                                    dispatch(loadSelectedCommunity(item));
                                }}
                                    sx={{ cursor: 'pointer' }}>
                                    <TableCell>
                                        {columns[0].dataKey === 'title' && (
                                            <>
                                                <Tooltip title={item?.name} placement="top" >
                                                    <Stack>
                                                        <UserInfoCell
                                                            name={
                                                                item?.name?.length > 9
                                                                    ? `${item?.name?.substring(0, 9)}..`
                                                                    : item?.name

                                                            }
                                                            avatar={item?.logo}
                                                            avatarStyles={{
                                                                height: '35px',
                                                                width: '35px',
                                                                fontSize: '11px',
                                                            }}

                                                        >

                                                        </UserInfoCell>


                                                    </Stack>
                                                </Tooltip>{' '}
                                            </>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        {columns[1].dataKey === 'category' && (
                                            <TextContentCell
                                                title={getStaticValue(item?.category) || item?.category}
                                                subTitle={item?.members?.length + " " + "Members"}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {columns[2].dataKey === 'admin' && (
                                            <>
                                                <Tooltip
                                                    title={
                                                        item?.createdBy?.firstName +
                                                        '' +
                                                        item?.createdBy?.lastName
                                                    }
                                                >
                                                    <Stack>
                                                        <UserInfoCell
                                                            name={`${(
                                                                item?.createdBy?.firstName +
                                                                ' ' +
                                                                item?.createdBy?.lastName
                                                            ).length > 11
                                                                ? `${(
                                                                    item?.createdBy?.firstName +
                                                                    ' ' +
                                                                    item?.createdBy?.lastName
                                                                ).substring(0, 10)}...`
                                                                : item?.createdBy?.firstName +
                                                                ' ' +
                                                                item?.createdBy?.lastName
                                                                }`}
                                                            // name={item.createdBy.firstName}
                                                            avatar={item?.createdBy?.avatar}
                                                            avatarStyles={{
                                                                height: '24px',
                                                                width: '24px',
                                                                fontSize: '11px',
                                                            }}
                                                            nameProps={{
                                                                sx: {
                                                                    fontWeight: 400,
                                                                    fontSize: '11px !important',
                                                                    color: '#1A1A1A',
                                                                },
                                                            }}
                                                        />
                                                    </Stack>
                                                </Tooltip>
                                            </>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {columns[3].dataKey === 'email/phone' && (
                                            <TextContentCell
                                                title={item?.createdBy?.emailId}
                                                subTitle={item?.createdBy?.phoneNumber}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {columns[4].dataKey === 'markedAs' && (
                                            <TextContentCell title={getStaticValue(item?.type)}
                                                subTitle={item.membership} />
                                        )}
                                    </TableCell>


                                    <TableCell>
                                        {columns[5].dataKey === "earnings" && (
                                            <Typography sx={{ fontSize: '12px' }}>
                                                {item?.totalEarnings}
                                            </Typography>
                                        )}

                                    </TableCell>
                                    <TableCell>
                                        {columns[6].dataKey === 'created/updated' && (
                                            <TextContentCell
                                                title={new Date(item?.updatedAt).toLocaleString('en-GB', {
                                                    timeZone: 'Asia/Kolkata',
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: false,
                                                })}

                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {columns[7].dataKey === 'status' && (
                                            <Stack
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    fontSize: '11px',
                                                    color: '#000000',
                                                    fontWeight: '400',
                                                }}
                                                direction={'row'}
                                            >
                                                <FiberManualRecordIcon
                                                    key={i}
                                                    sx={{
                                                        hieght: '10px',
                                                        width: '10px',
                                                        pb: 1,
                                                        color: GetColor(item.status),
                                                    }}
                                                />
                                                {getStaticValue(item.status)}
                                            </Stack>
                                        )}
                                    </TableCell>
                                    <TableCell sx={{ alignItems: 'center' }}>
                                        <Tooltip title={'About Community'} placement="left">
                                            <Group2Icon
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontSize: '20px',
                                                    color: '#898989',
                                                    marginRight: '15px',
                                                    transition: 'all 0.3s',
                                                    '&:hover': { transform: 'scale3d(1.5, 1.5, 1.5)' },
                                                }}
                                                onClick={() => {
                                                    navigate(`/community/${item?._id}/about`);
                                                    dispatch(loadSelectedCommunity(item));
                                                }}
                                            />
                                        </Tooltip>
                                        {/* <Tooltip title={'Community Getway'} placement="bottom">
                                            <WidgetsOutlinedIcon
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontSize: '20px',
                                                    color: '#898989',
                                                    marginRight: '15px',
                                                    transition: 'all 0.3s',
                                                    '&:hover': { transform: 'scale3d(1.5, 1.5, 1.5)' },
                                                }}
                                                onClick={() => {
                                                    navigate(`/communities/${item?._id}`);

                                                    dispatch(loadSelectedCommunity(item));
                                                }}
                                            />
                                        </Tooltip> */}
                                        {/* <Tooltip title={'Edit Community'} placement="top">
                                            <EditIcon
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontSize: '20px',
                                                    color: '#898989',
                                                    marginRight: '15px',
                                                    transition: 'all 0.3s',
                                                    '&:hover': { transform: 'scale3d(1.5, 1.5, 1.5)' },
                                                    pointerEvents:
                                                        item.status === 'PUBLISHED' ? 'none' : 'auto',
                                                    opacity: item.status === 'PUBLISHED' ? 0.5 : 1,
                                                }}
                                                onClick={() => {
                                                    navigate(`/communities/edit/${item?._id}`);
                                                }}
                                            />
                                        </Tooltip> */}
                                        <Tooltip title="Build Community" placement="top">
                                            <AttachementIcon
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontSize: '18px',
                                                    border: '2px solid #4578F980',
                                                    borderRadius: '5px',

                                                    padding: '1px',
                                                    transition: 'all 0.3s',
                                                    '&:hover': { transform: 'scale3d(1.5, 1.5, 1.5)' },
                                                }}
                                                onClick={() =>
                                                    navigate(`/all-communities/${item?._id}/build`)
                                                }
                                            />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* </SimpleBar> */}
            </PerfectScrollbar>

            <Stack
                direction={'row-reverse'}
                alignItems={'center'}
                sx={{
                    color: '#A5A5A5',
                    pl: 2,
                    fontSize: { xs: '10px', md: '1.5vh' },
                    pr: { xs: '0px', md: 4 },
                }}
            >
                <Typography
                    sx={{
                        color: '#A5A5A5',

                        fontSize: { xs: '10px', md: '13PX' },
                        display: { xs: 'none', md: 'block' },
                    }}
                >
                    Showing {Math.min(rowsPerPage, data.length - page * rowsPerPage)} of{' '}
                    {data.length} entries
                </Typography>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="View"
                    labelDisplayedRows={() => null}
                    SelectProps={{
                        SelectDisplayProps: {
                            style: { border: '1px solid #A5A5A5', borderRadius: '5px' },
                        },
                        // You can also customize the style of the menu options if desired
                        MenuProps: {
                            PaperProps: { style: { border: '1px solid #A5A5A5' } },
                        },
                    }}
                    ActionsComponent={({ onPageChange, page }) => {
                        const totalPages = Math.ceil(data.length / rowsPerPage);
                        return (
                            <Stack
                                direction={'row'}
                                display="flex"
                                alignItems="center"
                                justifyContent={'space-between'}
                            >
                                {/* <IconButton
                  onClick={(event) => onPageChange(event, 0)} // Go to the first page
                  disabled={page === 0}
                  aria-label="go to first page"
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton> */}

                                <IconButton
                                    onClick={(event) => onPageChange(event, page - 1)} // Go to the previous page
                                    disabled={page === 0}
                                    aria-label="go to previous page"
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>

                                {[...Array(3)].map((_, index) => {
                                    const pageNumber = page + index;
                                    if (pageNumber < totalPages) {
                                        return (
                                            <IconButton
                                                key={pageNumber}
                                                onClick={(event) => onPageChange(event, pageNumber)}
                                                style={{
                                                    fontSize: '1.9vh',
                                                    border:
                                                        page === pageNumber ? '1px solid #3C5AA0' : 'none', // Highlight the active page with a border
                                                    color: page === pageNumber ? '#3C5AA0' : '#000000', // Highlight the active page with a color
                                                    width: page === pageNumber ? '30px' : '30px',
                                                    height: page === pageNumber ? '30px' : '30px',
                                                }}
                                            >
                                                {pageNumber + 1}
                                            </IconButton>
                                        );
                                    } else {
                                        return null; // Don't render the button if it's out of bounds
                                    }
                                })}

                                <IconButton
                                    onClick={(event) => onPageChange(event, page + 1)} // Go to the next page
                                    disabled={page >= totalPages - 1}
                                    aria-label="next page"
                                >
                                    <KeyboardArrowRight />
                                </IconButton>

                                {/* <IconButton
                  onClick={(event) => onPageChange(event, totalPages - 1)} // Go to the last page
                  aria-label="go to last page"
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton> */}
                            </Stack>
                        );
                    }}
                />
            </Stack>
        </>
    );
};
