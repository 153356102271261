import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useWebBuilders } from '../../hooks/useWebBuilder';
import { useSelector } from 'react-redux';
import { IWebService } from '../../models/builder.model';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { AI_APP_BASE_URL } from '../../configurations/url.config';

export interface AddServicesProps {
  services?: IWebService[];
  refreshData: () => void;
}

const AddServices: React.FC<AddServicesProps> = ({ services, refreshData }) => {
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity.selectedCommunity
  );

  const { updateServices, uploadImages, deleteEntityHandler } = useWebBuilders();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoading] = useState(false);
  const [loaderImg, setLoadingImg] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);

  const [newService, setNewService] = useState<IWebService>({
    title: '',
    description: '',
    images: '',
    status: 'ACTIVE',
  });

  const [serviceData, setServiceData] = useState<IWebService[]>([]);

  useEffect(() => {
    if (services) {
      setServiceData(services);
    }
  }, [services]);

  const handleInputChange = (name: string, value: string) => {
    setNewService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };

  const handleImageChange = async (files: FileList | null, index?: number): Promise<void> => {
    if (!files) return;
    const selectedFiles = Array.from(files);
    setLoadingImg(true);
    try {
      const responses = await uploadImages(selectedFiles);
      const uploadedImageUrl = responses[0].value;
      // console.log("uploadedImageUrl123", uploadedImageUrl);
      if (index !== undefined) {
        setServiceData((prevData) => {
          const updatedServiceData = [...prevData];
          updatedServiceData[index].images = uploadedImageUrl;
          return updatedServiceData;
        });
      } else {
        setNewService((prevService) => ({
          ...prevService,
          images: uploadedImageUrl,
        }));
      }
      enqueueSnackbar("Service image updated successfully", { variant: "success" });
    } catch (error) {
      console.error('Error during upload:', error);
      enqueueSnackbar('Failed to upload image. Please try again.', { variant: 'error' });
    } finally {
      setLoadingImg(false);
    }
  };


  const handleRemoveImage = (index?: number) => {
    if (index !== undefined) {
      setServiceData((prevData) => {
        const updatedServiceData = [...prevData];
        updatedServiceData[index].images = '';
        return updatedServiceData;
      });
    } else {
      setNewService((prevService) => ({
        ...prevService,
        images: '',
      }));
    }
  };


  const handleServiceChange = (
    index: number,
    field: keyof IWebService,
    value: string
  ) => {
    setServiceData((prevFaqs) =>
      prevFaqs.map((service, i) =>
        i === index ? { ...service, [field]: value } : service
      )
    );
  };

  const addNewService = () => {
    if (newService.title && newService.description) {
      setServiceData((prevData) => [...prevData, newService]);
      setNewService({ title: '', description: '', images: '', status: '' });
    } else {
      enqueueSnackbar('Please fill in both title and description fields.', { variant: 'warning' });
    }
  };

  const handleGenerateWithAI = async () => {
    setAiLoading(true);
    try {
      const communityId = selectedCommunity?._id;
      const response = await axios.get(`${AI_APP_BASE_URL}/website/services/${communityId}`);
      // console.log(response, "response")
      if (response?.status === 200) {
        const fetchedServices = response.data;
        setServiceData(fetchedServices);
        enqueueSnackbar('FAQs generated successfully!', { variant: 'success' });
      }
    } catch (error) {
      console.error('Error fetching AI-generated FAQs:', error);
      enqueueSnackbar('Failed to generate FAQs. Please try again.', { variant: 'error' });
    } finally {
      setAiLoading(false);
    }
  };

  const handleSubmit = async () => {
    const servicesToSubmit = serviceData.filter(service => service.title !== '' || service.description !== '');
    console.log("Submitting Services:", servicesToSubmit);
    setLoading(true)
    try {
      const communityId = selectedCommunity?._id;
      const response = await updateServices(servicesToSubmit, communityId);
      if (response?.status === 200) {
        enqueueSnackbar("Services updated successfully", { variant: "success" });
        refreshData();
      }
    } catch (error) {
      console.error('Error updating Services:', error);
      enqueueSnackbar('Failed to update services. Please try again.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const deleteSingleServiceForm = async (index: number) => {
    const serviceToDelete = serviceData[index];
    // console.log('Deleting Service with _id:', serviceToDelete._id);
    const serviceDeletingId = serviceToDelete._id;

    setLoading(true);
    try {
      const communityId = selectedCommunity?._id;
      if (!communityId) {
        enqueueSnackbar('Community ID is missing.', { variant: 'error' });
        return;
      }

      const response = await deleteEntityHandler(serviceDeletingId, communityId, 'services');
      if (response?.status === 200) {
        enqueueSnackbar('Service deleted successfully', { variant: 'success' });
        setServiceData((prevData) => prevData.filter((_, i) => i !== index));
        refreshData();
      }
    } catch (error) {
      console.error('Error deleting Service:', error);
      enqueueSnackbar('Failed to delete Service. Please try again.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      <Stack spacing={2}>
        <Stack sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
          <Button
            onClick={handleGenerateWithAI}
            variant="outlined"
            sx={{
              textTransform: 'capitalize',
              width: 'fit-content',
              fontSize: '13px',
              color: '#2952A2',
              border: 'none',
              textDecoration: 'underline',
              "&:hover": {
                border: 'none',
                backgroundColor: 'white',
                textDecoration: 'underline',

              }
            }}
            disabled={aiLoading}
          >
            {aiLoading ? <CircularProgress size={24} sx={{ color: '#2952A2' }} /> : 'Generate with AI'}
          </Button>
        </Stack>
        {serviceData?.map((service, index) => (
          <Stack
            key={index}
            sx={{
              border: '1px solid #E0E0E0',
              padding: '30px',
              margin: '15px 0px',
              position: 'relative',
            }}
          >
            <IconButton
              style={{
                position: 'absolute',
                top: '1px',
                right: '1px',
                backgroundColor: 'white',
              }}
              onClick={() => deleteSingleServiceForm(index)}
            >
              <DeleteIcon />
            </IconButton>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  gap={5}
                  alignItems={'center'}
                >
                  <Stack sx={{ width: '75%' }}>
                    <TextField
                      label="Service Title"
                      variant="outlined"
                      fullWidth
                      value={service?.title}
                      onChange={(e) =>
                        handleServiceChange(index, 'title', e.target.value)
                      }
                      sx={{ marginBottom: '15px' }}
                    />
                    <TextField
                      label="Service Description"
                      variant="outlined"
                      fullWidth
                      value={service?.description}
                      onChange={(e) =>
                        handleServiceChange(index, 'description', e.target.value)
                      }
                    />
                  </Stack>
                  <Stack
                    sx={{
                      backgroundColor: service.images ? '#ffffff' : '#F5F5F5',
                      p: 1.5,
                      m: 1,
                      borderRadius: '5px',
                      border: service.images ? 'none' : '1px dashed #000000',
                      height: { xs: '20vh', md: '15vh' },
                      width: '25%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: service.images ? `url(${service.images})` : 'none',
                      position: 'relative',
                    }}
                  >
                    {service.images ? (
                      <>
                        <IconButton
                          onClick={() => handleRemoveImage(index)}
                          sx={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                            backgroundColor: 'white',
                            '&:hover': { backgroundColor: '#f2f2f2' },
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Typography sx={{ fontSize: '13px', color: '#5E6C84', pb: 1 }}>
                          Upload Service Image
                        </Typography>
                        {loaderImg ? <CircularProgress size={24} sx={{ color: '#3C5AA0' }} /> :
                          <input
                            type="file"
                            name="images"
                            onChange={(e) => handleImageChange(e.target.files, index)}
                            style={{
                              border: '2px solid #5E6C84',
                              borderRadius: '5px',
                              padding: '8px',
                              backgroundColor: '#ffffff',
                              color: '#5E6C84',
                              fontSize: '10px',
                              fontWeight: '500',
                              cursor: 'pointer',
                              width: '70%',
                              marginTop: '10px',
                            }}
                          />
                        }
                      </>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        ))}

        <Stack
          sx={{
            border: '1px solid #E0E0E0',
            padding: '24px',
            margin: '15px 0px',
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                gap={5}
                alignItems={'center'}
              >
                <Stack sx={{ width: '75%' }}>
                  <TextField
                    label="Service Title"
                    variant="outlined"
                    fullWidth
                    value={newService?.title}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                    sx={{ marginBottom: '15px' }}
                  />
                  <TextField
                    label="Service Description"
                    variant="outlined"
                    fullWidth
                    value={newService?.description}
                    onChange={(e) =>
                      handleInputChange('description', e.target.value)
                    }
                  />
                </Stack>
                <Stack
                  sx={{
                    backgroundColor: '#F5F5F5',
                    p: 1.5,
                    m: 1,
                    borderRadius: '5px',
                    border: '1px dashed #000000',
                    height: { xs: '20vh', md: '15vh' },
                    display: 'flex',
                    width: '25%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundPosition: 'center',
                  }}
                >
                  <Typography
                    sx={{ fontSize: '13px', color: '#5E6C84', pb: 1 }}
                  >
                    Upload Service Image
                  </Typography>
                  <input
                    type="file"
                    name="images"
                    onChange={(e) => handleImageChange(e.target.files)}
                    style={{
                      border: '2px solid #5E6C84',
                      borderRadius: '5px',
                      padding: '8px',
                      backgroundColor: '#ffffff',
                      color: '#5E6C84',
                      fontSize: '10px',
                      fontWeight: '500',
                      cursor: 'pointer',
                      width: '70%',
                      marginTop: '10px',
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        <Button
          variant="outlined"
          sx={{
            textTransform: 'capitalize',
            width: 'fit-content',
            fontSize: '13px',
            color: '#2952A2',
            border: '1px solid #2952A2',
            ':hover': {
              border: '1px solid #2952A2',
              color: '#2952A2',
            },
          }}
          onClick={addNewService}
        >
          Add New
        </Button>

        <Button
          variant="contained"
          size="large"
          sx={{
            textTransform: 'capitalize',
            borderRadius: '10px',
            mt: 2,
            mb: 2,
            color: '#ffffff',
            width: '100px',
            boxShadow: 'none',
            background:
              'linear-gradient(140deg, #3C5AA0 13.54%, #467DB4 68.75%, #50A1CA 100%)',
            '&:hover': {
              backgroundColor: '#50A1CA',
              cursor: 'pointer',
            },
          }}
          onClick={handleSubmit}
          disabled={loader}
        >
          {loader ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Update'}
        </Button>
      </Stack>
    </>
  );
};

export default AddServices;
