// usePaymentModes.ts

import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import { useSnackbar } from 'notistack';
import { getAllSuperAdminsBankAccounts } from '../services/super.service';
import { ICommunity } from '../models/communities.modal';
import { useDispatch } from 'react-redux';
import { loadCommunities } from '../store/slices/CommunitySlice';
import { getAllCommunities } from '../services/superAdmin.service';

export const useSuperAdmin = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const [bankDetails, setBankDetails] = useState<string[]>([]);
  const [communities, setCommunities] = useState<ICommunity[]>([]);
  const dispatch = useDispatch();

  const getAllCommunitiesList = async () => {
    setIsLoading(true);
    const response = await getAllCommunities(getAccessToken());
    console.log(response, "response");
    if (response) {
      setCommunities(response);
      dispatch(loadCommunities(response.data));
      setIsLoading(false);
      return response;
    } else {
      enqueueSnackbar('Fetching communities failed', { variant: 'error' });
      setIsLoading(false);
      return [];
    }
  };

  const getAllSuperAdminBanks = async () => {
    try {
      setIsLoading(true);
      const response = await getAllSuperAdminsBankAccounts(getAccessToken());
      if (response?.status === 200) {
        setBankDetails(response?.data);
        return response.data;
      } else {
        enqueueSnackbar('Failed to fetch bank accounts', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch {
      enqueueSnackbar('Failed to fetch bank accounts', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  return {
    isLoading,
    bankDetails,
    communities,
    getAllSuperAdminBanks,
    getAllCommunitiesList,
  };
};
