import React, { useState } from 'react';
import {
  Card,
  CardMedia,
  Button,
  Stack,
  Dialog,
  Grid,
  Box,
  DialogActions,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface ImageGalleryProps {
  images: string[];
  imageHeight: number;
  imageWidth: number;
}

const PostImages: React.FC<ImageGalleryProps> = ({ images }) => {
  const [showCarousel, setShowCarousel] = useState(false);

  // const theme = useTheme();

  const openCarousel = () => {
    setShowCarousel(true);
  };

  const closeCarousel = () => {
    setShowCarousel(false);
  };

  return (
    <Stack
      sx={{
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {images.length > 0 && (
        <Grid
          container
          spacing={1}
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          {images.slice(0, 1).map((image, index) => (
            <Grid key={index} item xs={images.length === 1 ? 12 : 6}>
              <Card
                elevation={0}
                sx={{
                  position: 'relative',
                  paddingTop: '100%',
                  width: '100%',
                  borderRadius: '5px',
                  overflow: 'hidden',
                }}
                onClick={openCarousel}
              >
                <CardMedia
                  component="img"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                  image={image}
                />
              </Card>
            </Grid>
          ))}

          {images.length > 1 && (
            <Grid item xs={6}>
              <Card
                elevation={0}
                sx={{
                  position: 'relative',
                  paddingTop: '100%',
                  width: '100%',
                  borderRadius: '5px',
                  overflow: 'hidden',
                }}
                onClick={openCarousel}
              >
                <CardMedia
                  component="img"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                  image={images[1]}
                />
                {images.length > 2 && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      background:
                        'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
                      backgroundColor: '#50A1CA',
                      color: '#ffffff',
                      fontSize: '15px',
                      fontFamily: 'Montserrat',
                      position: 'absolute',
                      bottom: '0px',
                      boxShadow: 'none',
                      left: { xs: '150px', md: '92.5%' },
                      textTransform: 'capitalize',
                      transform: 'translateX(-50%)',
                      '&:hover': {
                        backgroundColor: '#50A1CA',
                      },
                    }}
                    onClick={openCarousel}
                  >
                    + {images.length - 2}
                  </Button>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      )}

      <Box sx={{ display: { xs: 'block', md: 'none', }, height: "100%", mb: -5 }}>
        <Carousel showStatus={false} showArrows autoPlay={true}>
          {images.map((image, index) => (
            <Box key={index}>
              <CardMedia
                component="img"
                sx={{
                  width: '364px',
                  height: '364px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                  borderRadius: '5px',
                }}
                src={image}
              />
            </Box>
          ))}
        </Carousel>
      </Box>

      <Dialog open={showCarousel} onClose={closeCarousel} maxWidth="sm">
        <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ backgroundColor: '#F0F9FF' }}
          >
            <Stack></Stack>
            <Stack></Stack>
            <Stack sx={{ backgroundColor: '#F0F9FF' }}>
              <Box
                onClick={closeCarousel}
                sx={{
                  backgroundColor: '#2653A3',
                  padding: '4px',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '10px',
                  mt: '0.5px',
                  mr: '10px',
                  cursor: 'pointer',
                  // "&:hover": {
                  //   backgroundColor: "#50A1CA", // Background color on hover
                  //   cursor: "pointer",
                  //   border: "none",
                  // },
                }}
              >
                <Close sx={{ fontSize: '15px' }} />
              </Box>
            </Stack>
          </Stack>
        </DialogActions>
        <Carousel
          showThumbs={false}
          infiniteLoop={false}
          width={'fit-content'}
          showStatus={false}
          showArrows={true}
        >
          {images.map((image, index) => (
            <Box key={index}>
              <CardMedia
                component="img"
                sx={{
                  width: '100%',
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                src={image}
              />
            </Box>
          ))}
        </Carousel>
      </Dialog>
    </Stack>
  );
};

export default PostImages;
