import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, IconButton, Stack, TextField } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { IWebFaqs } from '../../models/builder.model';
import { useSelector } from 'react-redux';
import { useWebBuilders } from '../../hooks/useWebBuilder';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { AI_APP_BASE_URL } from '../../configurations/url.config';

export interface AddFaqsProps {
  faq: IWebFaqs[];
  refreshData: () => void;
}

const AddFaqs: React.FC<AddFaqsProps> = ({ faq, refreshData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateFaqs, deleteEntityHandler } = useWebBuilders();

  const selectedCommunity = useSelector((state: any) => state?.selectedCommunity.selectedCommunity);
  const [loading, setLoading] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);



  const [faqs, setFaqs] = useState<IWebFaqs[]>(faq.length > 0 ? faq : [{ question: '', answer: '' }]);

  useEffect(() => {
    setFaqs(faq.length > 0 ? faq : [{ question: '', answer: '' }]);
  }, [faq]);

  const handleFaqChange = (index: number, field: keyof IWebFaqs, value: string) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) => (i === index ? { ...faq, [field]: value } : faq))
    );
  };

  const addNewFaq = () => {
    setFaqs((prevFaqs) => [...prevFaqs, { question: '', answer: '' }]);
  };

  const deleteFaq = async (index: number) => {
    const faqToDelete = faqs[index];
    // console.log('Deleting FAQ with _id:', faqToDelete._id);
    const faqDeletingId = faqToDelete._id;
    setLoading(true);
    try {
      const communityId = selectedCommunity?._id;
      if (!communityId) {
        enqueueSnackbar('Community ID is missing.', { variant: 'error' });
        return;
      }
      const response = await deleteEntityHandler(faqDeletingId, communityId, 'faq');
      if (response?.status === 200) {
        enqueueSnackbar('FAQ deleted successfully', { variant: 'success' });
        setFaqs((prevFaqs) => prevFaqs.filter((_, i) => i !== index));
        refreshData();
      }
    } catch (error) {
      console.error('Error deleting FAQ:', error);
      enqueueSnackbar('Failed to delete FAQ. Please try again.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };


  const handleGenerateWithAI = async () => {
    setAiLoading(true);
    try {
      const communityId = selectedCommunity?._id;
      const response = await axios.get(`${AI_APP_BASE_URL}/website/faq/${communityId}`);
      if (response?.status === 200) {
        const fetchedFaqs = response.data;
        setFaqs(fetchedFaqs);
        enqueueSnackbar('FAQs generated successfully!', { variant: 'success' });
      }
    } catch (error) {
      console.error('Error fetching AI-generated FAQs:', error);
      enqueueSnackbar('Failed to generate FAQs. Please try again.', { variant: 'error' });
    } finally {
      setAiLoading(false);
    }
  };

  const handleSubmit = async () => {
    const faqsToSubmit = faqs.filter(faq => faq.question !== '' || faq.answer !== '');
    const hasEmptyFields = faqs.some(faq => faq.question === '' || faq.answer === '');
    if (hasEmptyFields) {
      enqueueSnackbar('Please fill in both question and answer for all FAQs.', { variant: 'warning' });
      return;
    }

    setLoading(true);
    try {
      const communityId = selectedCommunity?._id;
      const response = await updateFaqs(faqsToSubmit, communityId);
      if (response?.status === 200) {
        enqueueSnackbar("FAQ updated successfully", { variant: "success" });
        refreshData();
      }
    } catch (error) {
      console.error('Error updating FAQs:', error);
      enqueueSnackbar('Failed to update FAQs. Please try again.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={2}>
      <Stack sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
        <Button
          onClick={handleGenerateWithAI}
          variant="outlined"
          sx={{
            textTransform: 'capitalize',
            width: 'fit-content',
            fontSize: '13px',
            color: '#2952A2',
            border: 'none',
            textDecoration: 'underline',
            "&:hover": {
              border: 'none',
              backgroundColor: 'white',
              textDecoration: 'underline',

            }
          }}
          disabled={aiLoading}
        >
          {aiLoading ? <CircularProgress size={24} sx={{ color: '#2952A2' }} /> : 'Generate with AI'}
        </Button>
      </Stack>
      {faqs.map((faq, index) => (
        <Stack
          key={index}
          sx={{
            border: '1px solid #E0E0E0',
            padding: '24px',
            margin: '15px 0px',
            position: 'relative',
          }}
        >
          <IconButton
            style={{
              position: 'absolute',
              top: '1px',
              right: '1px',
              backgroundColor: 'white',
            }}
            onClick={() => deleteFaq(index)}
          >
            <DeleteIcon />
          </IconButton>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <TextField
                label="Question"
                variant="outlined"
                fullWidth
                value={faq.question}
                onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
                sx={{ marginBottom: '15px' }}
              />
              <TextField
                fullWidth
                label="Answer"
                variant="outlined"
                value={faq.answer}
                onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
                sx={{ marginBottom: '15px' }}
              />
            </Grid>
          </Grid>
        </Stack>
      ))}

      <Button
        variant="outlined"
        sx={{
          textTransform: 'capitalize',
          width: 'fit-content',
          fontSize: '13px',
          color: '#2952A2',
          border: '1px solid #2952A2',
          ':hover': {
            border: '1px solid #2952A2',
            color: '#2952A2',
          },
        }}
        onClick={addNewFaq}
      >
        Add FAQ
      </Button>

      <Button
        variant="contained"
        size="large"
        sx={{
          textTransform: 'capitalize',
          borderRadius: '10px',
          mt: 2,
          mb: 2,
          color: '#ffffff',
          width: '100px',
          boxShadow: 'none',
          background:
            'linear-gradient(140deg, #3C5AA0 13.54%, #467DB4 68.75%, #50A1CA 100%)',
          '&:hover': {
            backgroundColor: '#50A1CA',
            cursor: 'pointer',
          },
        }}
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Update'}
      </Button>
    </Stack>
  );
};

export default AddFaqs;
