import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useWebBuilders } from '../../hooks/useWebBuilder';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IWebBanners } from '../../models/builder.model';
import { AI_APP_BASE_URL } from '../../configurations/url.config';
import axios from 'axios';

export interface AddBannersProps {
  banners?: IWebBanners[];
  refreshData: () => void;
}

const AddBanners: React.FC<AddBannersProps> = ({ banners = [], refreshData }) => {
  const { updateBanners, uploadImages } = useWebBuilders();
  const { enqueueSnackbar } = useSnackbar();
  const [bannerData, setBannerData] = useState<IWebBanners[]>([{ heading: '', subheading: '', image: '' }]);
  const [loading, setLoading] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity?.selectedCommunity
  );

  // console.log("bannerData:", bannerData)


  useEffect(() => {
    if (banners?.length > 0) {
      setBannerData(banners);
    }
  }, [banners]);

  const handleImageChange = async (files: FileList | null, index: number) => {
    if (!files?.length) return;
    setImageUploading(true);
    try {
      const [file] = files;
      const [response] = await uploadImages([file]);
      const uploadedImageUrl = response?.value;
      setBannerData((prev) =>
        prev.map((banner, i) =>
          i === index ? { ...banner, image: uploadedImageUrl } : banner
        )
      );
      enqueueSnackbar('Banner image uploaded successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error uploading image:', error);
      enqueueSnackbar('Failed to upload image. Please try again.', { variant: 'error' });
    } finally {
      setImageUploading(false);
    }
  };

  const handleBannerChange = (index: number, field: keyof IWebBanners, value: string) => {
    setBannerData((prev) =>
      prev.map((banner, i) => (i === index ? { ...banner, [field]: value } : banner))
    );
  };

  const addNewBanner = () => {
    setBannerData((prev) => [...prev, { heading: '', subheading: '', image: '' }]);
  };

  const handleRemoveImage = (index: number) => {
    setBannerData((prev) =>
      prev.map((banner, i) => (i === index ? { ...banner, image: '' } : banner))
    );
  };

  const deleteSingleBannerForm = (index: number) => {
    setBannerData((prev) => prev.filter((_, i) => i !== index));
  };


  const handleGenerateWithAI = async () => {
    setAiLoading(true);
    try {
      const communityId = selectedCommunity?._id;
      const response = await axios.get(`${AI_APP_BASE_URL}/website/services/${communityId}`);
      // console.log(response, "response")
      if (response?.status === 200) {
        const fetchedServices = response.data;
        setBannerData(fetchedServices);
        enqueueSnackbar('FAQs generated successfully!', { variant: 'success' });
      }
    } catch (error) {
      console.error('Error fetching AI-generated FAQs:', error);
      enqueueSnackbar('Failed to generate FAQs. Please try again.', { variant: 'error' });
    } finally {
      setAiLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const communityId = selectedCommunity?._id;
      if (!communityId) throw new Error('Community ID is missing.');
      await updateBanners(bannerData, communityId);
      enqueueSnackbar('Banners updated successfully', { variant: 'success' });
      refreshData();
    } catch (error) {
      console.error('Error updating banners:', error);
      enqueueSnackbar('Failed to update banners. Please try again.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={2}>
      <Stack sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
        <Button
          onClick={handleGenerateWithAI}
          variant="outlined"
          sx={{
            textTransform: 'capitalize',
            width: 'fit-content',
            fontSize: '13px',
            color: '#2952A2',
            border: 'none',
            textDecoration: 'underline',
            "&:hover": {
              border: 'none',
              backgroundColor: 'white',
              textDecoration: 'underline',

            }
          }}
          disabled={aiLoading}
        >
          {aiLoading ? <CircularProgress size={24} sx={{ color: '#2952A2' }} /> : 'Generate with AI'}
        </Button>
      </Stack>
      {bannerData.map((banner, index) => (
        <Stack
          key={index}
          sx={{
            border: '1px solid #E0E0E0',
            padding: '30px',
            margin: '15px 0px',
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: '1px',
              right: '1px',
              backgroundColor: 'white',
            }}
            onClick={() => deleteSingleBannerForm(index)}
          >
            <DeleteIcon />
          </IconButton>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} alignItems="center">
                <Stack sx={{ flex: 1 }}>
                  <TextField
                    label="Heading"
                    variant="outlined"
                    fullWidth
                    value={banner.heading}
                    onChange={(e) => handleBannerChange(index, 'heading', e.target.value)}
                    sx={{ marginBottom: '15px' }}
                  />
                  <TextField
                    label="Sub Heading"
                    variant="outlined"
                    fullWidth
                    value={banner.subheading}
                    onChange={(e) => handleBannerChange(index, 'subheading', e.target.value)}
                  />
                </Stack>
                <Stack
                  sx={{
                    backgroundColor: banner.image ? '#ffffff' : '#F5F5F5',
                    width: '25%',
                    height: '150px',
                    border: banner.image ? 'none' : '1px dashed #000000',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundImage: `url(${banner.image})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                  }}
                >
                  {banner.image ? (
                    <IconButton
                      onClick={() => handleRemoveImage(index)}
                      sx={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        backgroundColor: 'white',
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <>
                      {imageUploading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <input
                          type="file"
                          onChange={(e) => handleImageChange(e.target.files, index)}
                          style={{
                            border: '2px solid #5E6C84',
                            borderRadius: '5px',
                            padding: '8px',
                            backgroundColor: '#ffffff',
                            color: '#5E6C84',
                            fontSize: '10px',
                            fontWeight: '500',
                            cursor: 'pointer',
                            width: '70%',
                            marginTop: '10px',
                          }}
                        />
                      )}
                    </>
                  )}
                </Stack>


              </Stack>
            </Grid>
          </Grid>
        </Stack>
      ))}

      <Button
        onClick={addNewBanner}
        variant="outlined"
        sx={{
          textTransform: 'capitalize',
          width: 'fit-content',
          fontSize: '13px',
          color: '#2952A2',
          border: '1px solid #2952A2',
          ':hover': {
            border: '1px solid #2952A2',
            color: '#2952A2',
          },
        }}
      >
        Add New Banner
      </Button>

      <Button
        onClick={handleSubmit}
        variant="contained"
        disabled={loading}
        sx={{
          textTransform: 'capitalize',
          borderRadius: '10px',
          mt: 2,
          mb: 2,
          color: '#ffffff',
          width: '100px',
          boxShadow: 'none',
          background:
            'linear-gradient(140deg, #3C5AA0 13.54%, #467DB4 68.75%, #50A1CA 100%)',
          '&:hover': {
            backgroundColor: '#50A1CA',
            cursor: 'pointer',
          },
        }}
      >
        {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Update'}
      </Button>
    </Stack>
  );


};

export default AddBanners;
