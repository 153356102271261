import React, { useEffect, useState } from 'react';
import { Skeleton, Stack } from '@mui/material';
import { useQrCode } from '../../hooks/useQrCode';
import OutlineButton from '../AppComponents/Buttons/OutlineButton';
import { DownloadOutlined } from '@mui/icons-material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { USER_APP_BASE_URL } from '../../configurations/url.config';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
interface UrlProps {
  id: string;
  url: string;
}

const QrCode: React.FC<UrlProps> = ({ id, url }) => {

  const { getQrCode } = useQrCode();
  const [qrCode, setQrCode] = useState('');
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [, setUrlCopy] = useState(false);
  const [, setCopiedUrl] = useState(false);

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity
  );

  useEffect(() => {
    const fetchQrCode = async () => {
      try {
        const response = await getQrCode(id, url);
        if (response && response.status === 200) {
          setQrCode(response.data.qr_code);
        } else {
          console.error('Failed to fetch QR code');
        }
      } catch (error) {
        console.error('Error fetching QR code:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchQrCode();
  }, [id, url]);


  const community_id = selectedCommunity?.selectedCommunity?._id
  const handleCopyLinkUrl = () => {
    const labelContent = `${USER_APP_BASE_URL}/${selectedCommunity?.selectedCommunity?.name
      ?.trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${community_id}`;

    navigator.clipboard
      .writeText(labelContent)
      .then(() => {
        setUrlCopy(true);
        setTimeout(() => setCopiedUrl(false), 3000);
        enqueueSnackbar('Link copied successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };


  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = qrCode;
    link.download = `${selectedCommunity?.selectedCommunity?.name}_QR.png`;;
    link.click();
  };

  return (
    <>
      {loading ? (
        <Skeleton animation="wave" width="40%" sx={{ height: 215 }} />
      ) : (
        <>
          <img src={qrCode} alt="QR Code" width="45%" style={{ border: '1px solid black', borderRadius: '10px', margin: '20px' }} />
          <Stack display={'flex'} direction={'row'} spacing={3} alignItems={""}>
            <OutlineButton
              name="Copy"
              onClick={handleCopyLinkUrl}
              icon={
                <ContentCopyOutlinedIcon
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />
              }
            />
            <OutlineButton
              name="Download"
              onClick={handleDownload}
              icon={
                <DownloadOutlined
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />
              }
            />
            <OutlineButton
              name="Share"
              onClick={() => {
                const url = `${USER_APP_BASE_URL}/community/${selectedCommunity?.selectedCommunity?.name
                  .trim()
                  .toLocaleLowerCase()
                  .replace(/\s+g/, '-')}/${community_id}`;
                const encodedUrl = encodeURIComponent(url);
                window.open(
                  `https://api.whatsapp.com/send?text=${encodedUrl}`,
                  '_blank'
                );
              }}
              icon={
                <ShareOutlinedIcon
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />
              }
            />
          </Stack>
        </>
      )}
    </>
  );
};

export default QrCode;
