import { Button, CircularProgress, Paper, Stack, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from '../../contexts/Auth.context';
import { deployWeb } from '../../services/webBuilder.service';
import { useSnackbar } from 'notistack';

export interface IBuildData {
    appName: string;
    emailId: string;
    domain: string;
    repoUrl: string;
    branch: string;
}

const Deployment = () => {
    const [buildData, setBuildData] = useState<IBuildData>({
        appName: '',
        emailId: '',
        domain: '',
        repoUrl: '',
        branch: '',
    });


    const { getAccessToken } = useContext<IAuthContext>(AuthContext);


    const { enqueueSnackbar } = useSnackbar();

    const [loader, setLoader] = useState<boolean>(false)


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setBuildData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDeploy = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoader(true);
        try {
            const response = await deployWeb(getAccessToken(), buildData);
            if (response?.status === 200) {
                enqueueSnackbar('App is deployed successfully', {
                    variant: 'success',
                    autoHideDuration: 3000,
                });
                return response;
            } else if (response?.status === 500) {
                enqueueSnackbar('Error: Server encountered an issue. Please try again later.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            } else {
                enqueueSnackbar('Unexpected error occurred. Please try again.', {
                    variant: 'warning',
                    autoHideDuration: 3000,
                });
            }
        } catch (err) {
            enqueueSnackbar('An error occurred while deploying. Please check your connection and try again.', {
                variant: 'error',
                autoHideDuration: 3000,
            });
        } finally {
            setLoader(false);
        }
    };



    const isDataAvailable = buildData?.appName && buildData?.domain && buildData?.emailId && buildData?.repoUrl


    return (
        <>
            <Paper sx={{ py: 5, px: 2, boxShadow: 'none' }}>
                <Stack
                    sx={{
                        display: { xs: 'inline-block', sm: 'block', md: 'flex' },
                        flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                    }}
                    gap={1}
                    spacing={{ xs: 1, md: 0 }}
                >
                    <Stack>
                        <TextField
                            required
                            name="appName"
                            type="text"
                            label="App Name"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={buildData?.appName}
                            onChange={handleChange}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    fontSize: '13px',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    fontSize: '13px',
                                },
                            }}
                        />

                    </Stack>
                    <Stack>
                        <TextField
                            required
                            name="emailId"
                            type="text"
                            label="Email Id"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={buildData?.emailId}
                            onChange={handleChange}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    fontSize: '13px',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    fontSize: '13px',
                                },
                            }}
                        />
                    </Stack>
                    <Stack>
                        <TextField
                            required
                            name="domain"
                            type="text"
                            label="Domain"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={buildData?.domain}
                            onChange={handleChange}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    fontSize: '13px',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    fontSize: '13px',
                                },
                            }}
                        />

                    </Stack>
                    <Stack>
                        <TextField
                            required
                            name="repoUrl"
                            type="text"
                            label="Repo URL"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={buildData?.repoUrl}
                            onChange={handleChange}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    fontSize: '13px',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    fontSize: '13px',
                                },
                            }}
                        />

                    </Stack>
                    <Stack>

                    </Stack>
                    <Stack>
                        <TextField
                            name="branch"
                            type="text"
                            label="Branch Optional"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={buildData?.branch}
                            onChange={handleChange}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    fontSize: '13px',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    fontSize: '13px',
                                },
                            }}
                        />

                    </Stack>
                    <Button
                        disabled={!isDataAvailable}
                        variant="contained"
                        sx={{
                            backgroundColor: '#3C5AA0',
                            textTransform: 'capitalize',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: '#3C5AA0',
                                color: 'white',
                            },
                        }}
                        onClick={handleDeploy}
                    >
                        {loader ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Deploy"}
                    </Button>
                </Stack>
            </Paper>
        </>
    );
};
export default Deployment;
