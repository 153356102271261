import axios from 'axios';
import { BASE_URL_V2 } from '../configurations/url.config';
import { IMeetingPayload } from '../models/appointments.models';
import { IAddAppointment } from '../components/Appointments/AddAppointment';

export const bookAppointment = async (
  token: string,
  details: IAddAppointment
) => {
  try {
    const response = await axios.post(
      `${BASE_URL_V2}/appointment/schedule-appointment`,
      details,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response, "response");s
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};

export const bookAvailability = async (
  token: string,
  details: IMeetingPayload
) => {
  try {
    const response = await axios.post(
      `${BASE_URL_V2}/appointment/get-available-time`,
      details,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response, "response");s
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};

export const fetchAllAppointments = async (
  token: string,
  communityId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/appointment/all-admin-upcoming/community/${communityId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    //   console.log(response?.data?.appointments, "response");
    return response.data?.appointments;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const fetchAttendeesById = async (
  token: string,
  appointmentId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/appointment//${appointmentId}/attendees`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    //   console.log(response?.data?.appointments, "response");
    return response.data;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const fetchAllPastAppointments = async (
  token: string,
  communityId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/appointment/all-admin-past/community/${communityId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    //   console.log(response?.data?.appointments, "response");
    return response.data?.appointments;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const updateAppointment = async (
  token: string,
  details: IAddAppointment,
  appointmentId: string,
  communityId: string
) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/appointment/${appointmentId}/community/${communityId}`,
      details,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response, "response");
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};

export const updateAppointmentStatus = async (
  token: string,
  status: string,
  appointmentId: string,
  communityId: string
) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/appointment/${appointmentId}/community/${communityId}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};

export const fetchAppointmentById = async (
  token: string,
  appointmentId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/appointment/${appointmentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    //   console.log(response?.data?.appointments, "response");
    return response.data?.appointments;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const fetchAllAppointmentBookings = async (
  token: string,
  communityId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/appointment/admin-by-month/community/${communityId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    //   console.log(response?.data?.appointments, "response");
    return response.data?.data;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const cancelAppointment = async (
  token: string,
  appointmentId: string,
  communityId: string,
  availabilityId: string,
  timeSlotId: string
) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/appointment/${appointmentId}/community/${communityId}/user-cancel`,
      {
        availabilityId: availabilityId,
        timeSlotId: timeSlotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // console.log(response, "response");
    return response;
  } catch (error) {
    console.error('Error scheduling class:');
  }
};
