import React, { useState, useContext } from 'react';
import { Box, Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/Auth.context';
import { StyledLogin } from './Login.styles';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import { useSnackbar } from 'notistack';



interface IAddUser {
    firstName: string;
    lastName: string;
    phoneNumber: number;
    emailId: string;
}

export const AutoCreate = () => {
    const { enqueueSnackbar } = useSnackbar();
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)


    const [formValues, setFormValues] = useState<IAddUser>({
        firstName: "",
        lastName: "",
        phoneNumber: 0,
        emailId: "",
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };


    const handleLoginResponse = (response: any) => {
        if (response?.status === 200) {
            // console.log(response, 'response');
            const { user, adminCommunities } = response?.data;
            // console.log(adminCommunities, 'adminCommunities');
            if (adminCommunities?.length > 0) {
                navigate('/home', { state: { id: user?.community } });
            } else if (adminCommunities?.length === 0) {
                navigate('/explore-communities', {
                    state: { id: 2, data: user },
                });
            } else if (user?.community) {
                navigate('/dashboard', { state: { id: 3, data: user } });
            }
        } else if (response?.status === 403) {
            enqueueSnackbar('User not found', { variant: 'error' });
        } else if (response?.status === 404) {
            enqueueSnackbar('User not found', { variant: 'error' });
        }
    };

    const autoEmailLogin = async (): Promise<void> => {
        try {
            setLoader(true)
            const autoLoginResponse = await authContext.autoCreate(formValues);
            handleLoginResponse(autoLoginResponse);
        } catch (error) {
            console.error('Auto Email Login Error:', error);
            enqueueSnackbar('Something went wrong. Please try again.', {
                variant: 'error',
            });
        }
        finally {
            setLoader(false)
        }
    };

    return (
        <>
            <Helmet>
                <title>{metaConfig.title.loginPage}</title>
                <meta name="description" content={metaConfig.description} />
            </Helmet>
            <StyledLogin>
                <Grid container>
                    <Grid
                        item
                        md={7}
                        sx={{
                            background: 'linear-gradient(to right bottom, #50a1ca,#469EA7)',
                            display: { xs: 'none', md: 'block' },
                        }}
                    >
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: '100%', width: '100%' }}
                        >
                            <Box
                                component="img"
                                src={require('./../../../assets/images/registerbckg.png')}
                                alt=""
                            />
                            <Stack alignItems="center" sx={{ mt: 1 }}>
                                <Typography
                                    sx={{
                                        fontSize: '28px',
                                        fontWeight: '700',
                                        color: '#ffffff',
                                        fontFamily: 'Montserrat',
                                    }}
                                >
                                    Create or Join Communities
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Stack
                            sx={{
                                height: '100vh',
                                justifyContent: 'center',
                            }}
                            alignItems="center"
                        >
                            <Box
                                component="form"
                                sx={{
                                    width: '328px',
                                    textAlign: 'center',
                                }}
                                onSubmit={(e) => e.preventDefault()}
                            >
                                <Box
                                    component="img"
                                    src={require('./../../../assets/images/Communn-new-logo.png')}
                                    alt=""
                                    width="75%"
                                />

                                <Typography sx={{ color: '#000000', fontSize: { xs: '12px', md: '14px' }, fontFamily: 'Montserrat' }}>
                                    New user? Enter mobile number please.
                                </Typography>

                                <Stack spacing={2} sx={{ pt: 2, backgroundColor: 'white' }}>
                                    <TextField
                                        sx={{ backgroundColor: 'white', borderRadius: '30px' }}
                                        size="small"
                                        label="First name"
                                        name='firstName'
                                        fullWidth
                                        value={formValues?.firstName}
                                        onChange={handleChange}
                                        InputProps={{
                                            style: {
                                                borderRadius: '100px',
                                                backgroundColor: '#ffffff',
                                            },
                                        }}
                                    />
                                    <TextField
                                        sx={{ backgroundColor: 'white', borderRadius: '30px' }}
                                        size="small"
                                        label="Last name"
                                        name='lastName'
                                        fullWidth
                                        value={formValues?.lastName}
                                        onChange={handleChange}
                                        InputProps={{
                                            style: {
                                                borderRadius: '100px',
                                                backgroundColor: '#ffffff',
                                            },
                                        }}
                                    />
                                    <TextField
                                        sx={{ backgroundColor: 'white', borderRadius: '30px' }}
                                        size="small"
                                        label="Enter phone no"
                                        fullWidth
                                        name='phoneNumber'
                                        value={formValues?.phoneNumber}
                                        onChange={handleChange}
                                        InputProps={{
                                            style: {
                                                borderRadius: '100px',
                                                backgroundColor: '#ffffff',
                                            },
                                        }}
                                    />
                                    <TextField
                                        sx={{ backgroundColor: 'white', borderRadius: '30px' }}
                                        size="small"
                                        label="Enter email ID"
                                        fullWidth
                                        name='emailId'
                                        value={formValues?.emailId}
                                        onChange={handleChange}
                                        InputProps={{
                                            style: {
                                                borderRadius: '100px',
                                                backgroundColor: '#ffffff',
                                            },
                                        }}
                                    />
                                    <Button
                                        size="large"
                                        variant="contained"
                                        sx={{
                                            boxShadow: 'none',
                                            borderRadius: '30px',
                                            background:
                                                'linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)',
                                            textTransform: 'capitalize',
                                            ':hover': {
                                                background:
                                                    'linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)',
                                            },
                                        }}
                                        onClick={autoEmailLogin}
                                    >
                                        {loader ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "Create account"}
                                    </Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </StyledLogin>
        </>
    );
};

export default AutoCreate;
