import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useWebBuilders } from '../../hooks/useWebBuilder';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IWebAboutUs } from '../../models/builder.model';
import axios from 'axios';
import { AI_APP_BASE_URL } from '../../configurations/url.config';

export interface AddAboutUsProps {
  aboutUs: IWebAboutUs | undefined;
  refreshData: () => void;
}

const AddAboutUs: React.FC<AddAboutUsProps> = ({ aboutUs, refreshData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity.selectedCommunity
  );
  const { updateAboutUs } = useWebBuilders();

  const [aboutData, setAboutData] = useState<IWebAboutUs>({
    heading: '',
    about: '',
    vision: '',
    mission: '',
  });

  const [aiLoading, setAiLoading] = useState(false);

  const [loader, setLoader] = useState<boolean>(false);

  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    if (aboutUs) {
      setAboutData({
        heading: aboutUs.heading || '',
        about: aboutUs.about || '',
        vision: aboutUs.vision || '',
        mission: aboutUs.mission || '',
      });
    }
  }, [aboutUs]);


  const handleInputChange = (name: string, value: string) => {
    setAboutData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSetImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImage(URL.createObjectURL(file));
      setAboutData((prevData) => ({ ...prevData, image: file.name }));
    }
  };


  const handleGenerateWithAI = async () => {
    setAiLoading(true);
    try {
      const communityId = selectedCommunity?._id;
      const response = await axios.get(`${AI_APP_BASE_URL}/website/about-us/${communityId}`);
      if (response?.status === 200) {
        const fetchedData = response.data;
        // console.log('Fetched Contact Data:', fetchedData);
        setAboutData({
          heading: fetchedData?.heading || '',
          about: fetchedData?.content || '',
          vision: fetchedData?.vision || '',
          mission: fetchedData?.mission || '',
        });
        enqueueSnackbar('Contact us generated successfully!', { variant: 'success' });
      }
    } catch (error) {
      console.error('Error fetching AI-generated Contact us:', error);
      enqueueSnackbar('Failed to generate Contact us. Please try again.', { variant: 'error' });
    } finally {
      setAiLoading(false);
    }
  };


  const handleSubmit = async () => {
    setLoader(true);
    try {
      const communityId = selectedCommunity?._id;
      const response = await updateAboutUs(communityId, aboutData);
      if (response && response.status === 200) {
        const updatedAboutData = response.data;
        if (updatedAboutData) {
          enqueueSnackbar("About us updated successfully", { variant: "success" });
          setAboutData({
            heading: updatedAboutData?.heading,
            about: updatedAboutData?.about,
            vision: updatedAboutData?.vision,
            mission: updatedAboutData?.mission,
          });
        }
        refreshData()
      } else {
        enqueueSnackbar("Failed to update contact", { variant: "error" });
      }
    } catch (error) {
      console.error("Error updating contact:", error);
      enqueueSnackbar("An error occurred. Please try again.", { variant: "error" });
    }
    finally {
      setLoader(false)
    }
  };


  return (
    <>
      <Stack sx={{ border: '1px solid #E0E0E0', padding: '24px', margin: '15px 0' }}>
        <Stack sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
          <Button
            onClick={handleGenerateWithAI}
            variant="outlined"
            sx={{
              textTransform: 'capitalize',
              width: 'fit-content',
              fontSize: '13px',
              color: '#2952A2',
              border: 'none',
              textDecoration: 'underline',
              "&:hover": {
                border: 'none',
                backgroundColor: 'white',
                textDecoration: 'underline',

              }
            }}
            disabled={aiLoading}
          >
            {aiLoading ? <CircularProgress size={24} sx={{ color: '#2952A2' }} /> : 'Generate with AI'}
          </Button>
        </Stack>
        <Grid container>
          <Grid item xs={12}>
            <Stack display={'flex'} flexDirection={'row'} gap={5} alignItems={'center'}>
              <Stack sx={{ width: '100%' }}>
                <TextField
                  label="Heading"
                  variant="outlined"
                  fullWidth
                  value={aboutData.heading}
                  onChange={(e) => handleInputChange('heading', e.target.value)}
                  sx={{ marginBottom: '15px' }}
                />
                <TextField
                  label="Content"
                  variant="outlined"
                  fullWidth
                  value={aboutData.about}
                  onChange={(e) => handleInputChange('about', e.target.value)}
                  sx={{ marginBottom: '15px' }}
                />
                <TextField
                  label="Vision"
                  variant="outlined"
                  fullWidth
                  value={aboutData.vision}
                  onChange={(e) => handleInputChange('vision', e.target.value)}
                  sx={{ marginBottom: '15px' }}
                />
                <TextField
                  label="Mission"
                  variant="outlined"
                  fullWidth
                  value={aboutData.mission}
                  onChange={(e) => handleInputChange('mission', e.target.value)}
                  sx={{ marginBottom: '15px' }}
                />
              </Stack>
              <Stack>
                <Stack
                  sx={{
                    backgroundColor: '#F5F5F5',
                    p: 1.5,
                    m: 1,
                    borderRadius: '5px',
                    border: '1px dashed #000000',
                    height: { xs: '20vh', md: '15vh' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontSize: '13px', color: '#5E6C84', pb: 1 }}>
                    Upload Banner
                  </Typography>
                  <input
                    type="file"
                    name="image"
                    onChange={handleSetImage}
                    style={{
                      border: '2px solid #5E6C84',
                      borderRadius: '5px',
                      padding: '8px',
                      backgroundColor: '#ffffff',
                      color: '#5E6C84',
                      fontSize: '10px',
                      cursor: 'pointer',
                      width: '70%',
                      marginTop: '10px',
                    }}
                  />
                </Stack>
                {image && (
                  <img
                    src={image}
                    alt="Uploaded Banner"
                    style={{ width: '50px', height: '50px', objectFit: 'cover', marginTop: '10px' }}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Button
        variant="contained"
        size="large"
        sx={{
          textTransform: 'capitalize',
          borderRadius: '10px',
          mt: 2,
          mb: 2,
          color: '#ffffff',
          width: '100px',
          boxShadow: 'none',
          background:
            'linear-gradient(140deg, #3C5AA0 13.54%, #467DB4 68.75%, #50A1CA 100%)',
          '&:hover': {
            backgroundColor: '#50A1CA',
            cursor: 'pointer',
          },
        }}
        onClick={handleSubmit}
        disabled={loader}
      >
        {loader ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Update"}

      </Button>
    </>
  );
};

export default AddAboutUs;
