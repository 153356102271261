import { useNavigate, useParams } from "react-router-dom";
import { useAppointments } from "../../../hooks/useAppointments";
import React, { useEffect, useState } from "react";
import { IAttendees } from "../../../models/appointments.models";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../../utils/constants";
import { SubNavbar } from "../../Layout/components/SubNavbar";
import { Button, Card, CardContent, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { ContentWrapper } from "../../Layout/components/ContentWrapper";
import Loader from "../../Loader/Loader";
import { AttendeesTable } from "../../tables/AttendeesTable";
import { IColumn } from "../../../models/table.model";
import { NoMeetings } from "../../AlternatePages/NoMeetings";
import { SearchIcon1 } from "../../../assets/icons";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';


const columns: IColumn[] = [
    { title: 'Name', dataKey: 'name' },
    { title: 'Ticket Title', dataKey: 'title' },
    { title: 'Ticket Venue', dataKey: 'venue' },
    { title: 'Booked Date', dataKey: 'date' },
    { title: 'Check In', dataKey: 'check' },
    { title: '', dataKey: '' },
];



const Attendees = () => {



    const { id } = useParams()

    const { getAttendeesById } = useAppointments();


    const [appointments, setAppointments] = React.useState<IAttendees>();
    const [availability, setAvailability] = React.useState<IAttendees[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filteredAvailability, setFilteredAvailability] = useState<IAttendees[]>([]);

    // console.log(filteredAvailability, "appointments")


    // console.log(appointments, "appointments");


    const navigate = useNavigate()


    const appointmentsList = async () => {
        setIsLoading(true);
        try {
            const response = await getAttendeesById(id || '');
            // console.log(response, "response");
            setAppointments(response);
            setAvailability(response?.availability)

        } catch (err) {
            console.error('Error fetching live classes:', err);

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        appointmentsList();
    }, []);


    const Analytics = [
        {
            title: "Attendees",
            number: "50",
            id: '1',
            icon: <PeopleOutlinedIcon />
        },
        {
            title: "Check-ins",
            number: "12",
            id: '2',
            icon: <VerifiedUserOutlinedIcon />
        },
        {
            title: "Check-in rate",
            number: "26.7%",
            id: '3',
            icon: <PercentOutlinedIcon />
        },

    ]


    const handleSearch = (query: string) => {
        console.log("Search query:", query);
        setSearchQuery(query);

        if (query.trim() === '') {
            // Reset to original availability when the query is empty
            setFilteredAvailability(availability);
        } else {
            const lowerCaseQuery = query.toLowerCase();

            const filteredData = availability.filter((item) =>
                item?.availability?.some((attendee: any) => {
                    const name = attendee?.attendeeId?.firstName?.toLowerCase();
                    return name?.includes(lowerCaseQuery);
                })
            );

            console.log("Filtered data:", filteredData);
            setFilteredAvailability(filteredData);
        }
    };






    return (
        <>
            <Helmet>
                <title>{metaConfig.title.appointments}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>


            <Stack flexGrow={1} sx={{ display: { xs: 'flex', md: 'none' }, pb: 2 }} flexDirection={'row'} gap={1} display={'flex'} justifyItems={'center'} alignItems={'center'}>
                <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px' }} onClick={() => {
                    navigate('/appointments');
                }} />
                <Typography
                    sx={{
                        fontSize: { xs: '16px' },
                        fontWeight: 600,
                        color: '#000000',
                        fontFamily: 'Montserrat',
                    }}
                >
                    {appointments?.title}
                </Typography>
            </Stack>

            <SubNavbar>

                <Stack direction={'row'} flexGrow={1} alignItems={'center'} ml={0}>
                    <Stack direction={'row'} flexGrow={1} sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }} gap={1}>
                        <ArrowBackIosNewOutlinedIcon sx={{ fontSize: '18px', cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/appointments');
                            }} />
                        <Typography
                            sx={{
                                fontSize: { xs: '16px', md: '18px' },
                                fontWeight: 600,
                                color: '#000000',
                                fontFamily: 'Montserrat',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: '15px', md: '18px' },
                                    fontWeight: 600,
                                    color: '#000000',
                                    fontFamily: 'Montserrat',
                                }}
                            >
                                {appointments?.title}
                            </Typography>
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} flexGrow={1} sx={{ justifyContent: { xs: 'space-between', md: 'flex-end' } }} gap={2} >
                        <TextField
                            placeholder="Search "
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon1 sx={{ mt: 1, }} />
                                    </InputAdornment>
                                ),
                                sx: {
                                    borderRadius: '8px',
                                    backgroundColor: '#F9F9F9',
                                    fontSize: { xs: '12px', md: '15px' },
                                    height: '37px',
                                    width: { xs: '100%', md: '300px' },
                                    color: '#000000',
                                    fontFamily: 'Montserrat',
                                    ml: { xs: -1 },
                                },
                            }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            endIcon={<OpenInNewOutlinedIcon />}
                            sx={{
                                textTransform: 'capitalize',
                                borderRadius: '8px',
                                backgroundColor: '#3C5AA0',
                                fontFamily: 'Montserrat',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: '#3C5AA0',
                                    cursor: 'pointer',
                                    border: 'none',
                                },
                            }}
                            onClick={() => {
                                navigate('/appointments')
                            }}
                        >
                            Appointments
                        </Button>
                    </Stack>
                </Stack >
            </SubNavbar>


            <Grid container gap={1.5} py={1}>
                {Analytics?.map((classData, index) => (
                    <Grid item xs={12} md={3.9} key={index}>
                        <Card elevation={0} sx={{
                            p: 2,
                            borderLeft: '6px solid #D4DDED',
                            borderRight: '1px solid #D4DDED',
                            borderTop: '1px solid #D4DDED',
                            borderBottom: '1px solid #D4DDED',
                            borderRadius: '12px',
                            '&:hover': {
                                borderLeft: '6px solid #3C5AA0',
                                borderRight: '1px solid #3C5AA0',
                                borderTop: '1px solid #3C5AA0',
                                borderBottom: '1px solid #3C5AA0',
                            }
                        }}

                        >
                            <CardContent
                                sx={{
                                    pt: 1,
                                    pb: 0,
                                    "&:last-child": {
                                        pb: '1px',
                                    },
                                }}>
                                <Stack display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'} >
                                    <Stack>
                                        <IconButton sx={{ backgroundColor: '#F4F4F4' }}>
                                            {classData?.icon}
                                        </IconButton>
                                    </Stack>
                                    <Stack>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                fontSize: '15px',
                                                fontWeight: '400',
                                                color: '#646464',
                                            }}
                                        >

                                            {classData?.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Montserrat',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                color: '#000000',
                                            }}
                                        >
                                            {classData?.number}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Stack sx={{ pt: '5px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <ContentWrapper>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    {availability?.length === 0 ? (
                                        <NoMeetings />
                                    ) : (
                                        <AttendeesTable
                                            appointmentData={appointments || undefined}
                                            data={availability || []}
                                            columns={columns}
                                            appointmentsList={appointmentsList}
                                        />
                                    )}
                                </>
                            )}
                        </ContentWrapper>
                    </Grid>
                </Grid>
            </Stack>

        </>
    )
}
export default Attendees;