import { Button, CircularProgress, Grid, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWebBuilders } from '../../hooks/useWebBuilder';
import { useSnackbar } from 'notistack';
import { IWebContact } from '../../models/builder.model';
import axios from 'axios';
import { AI_APP_BASE_URL } from '../../configurations/url.config';

export interface AddContactProps {
    contactUs: IWebContact;
    refreshData: () => void;
}

const AddContactUs: React.FC<AddContactProps> = ({ contactUs, refreshData }) => {
    const { enqueueSnackbar } = useSnackbar();
    const selectedCommunity = useSelector(
        (state: any) => state?.selectedCommunity.selectedCommunity
    );
    const { updateContacts } = useWebBuilders();

    const [loader, setLoader] = useState<boolean>(false);
    const [contactData, setContactData] = useState<IWebContact>({
        email: '',
        phone: '',
        address: '',
        pinCode: '',
        city: '',
    });

    const [aiLoading, setAiLoading] = useState(false);

    useEffect(() => {
        setContactData({
            email: contactUs?.email || '',
            phone: contactUs?.phone || '',
            address: contactUs?.address || '',
            city: contactUs?.city || '',
            pinCode: contactUs?.pinCode || '',
        });
    }, [contactUs]);



    const handleInputChange = (name: string, value: string) => {
        setContactData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleGenerateWithAI = async () => {
        setAiLoading(true);
        try {
            const communityId = selectedCommunity?._id;
            const response = await axios.get(`${AI_APP_BASE_URL}/website/contact/${communityId}`);
            if (response?.status === 200) {
                const fetchedData = response.data;
                // console.log('Fetched Contact Data:', fetchedData);
                setContactData({
                    email: fetchedData?.Email || '',
                    phone: fetchedData?.Phone || '',
                    address: fetchedData?.Address || '',
                    city: fetchedData?.City || '',
                    pinCode: fetchedData?.Pincode || '',
                });
                enqueueSnackbar('Contact us generated successfully!', { variant: 'success' });
            }
        } catch (error) {
            console.error('Error fetching AI-generated Contact us:', error);
            enqueueSnackbar('Failed to generate Contact us. Please try again.', { variant: 'error' });
        } finally {
            setAiLoading(false);
        }
    };


    const handleSubmit = async () => {
        setLoader(true)
        try {
            const communityId = selectedCommunity?._id;
            const response = await updateContacts(communityId, contactData);
            if (response && response.status === 200) {
                const updatedContactData = response.data;
                if (updatedContactData) {
                    enqueueSnackbar("Contact updated successfully", { variant: "success" });
                    setContactData({
                        email: updatedContactData?.email,
                        phone: updatedContactData?.phone,
                        address: updatedContactData?.address,
                        city: updatedContactData?.city,
                        pinCode: updatedContactData?.pinCode
                    });
                }
                refreshData()
            } else {
                enqueueSnackbar("Failed to update contact", { variant: "error" });
            }
        } catch (error) {
            console.error("Error updating contact:", error);
            enqueueSnackbar("An error occurred. Please try again.", { variant: "error" });
        }
        finally {
            setLoader(false)
        }
    };




    return (
        <>
            <Stack
                sx={{
                    border: '1px solid #E0E0E0',
                    padding: '24px',
                    margin: '15px 0px',
                }}
            >
                <Stack sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                    <Button
                        onClick={handleGenerateWithAI}
                        variant="outlined"
                        sx={{
                            textTransform: 'capitalize',
                            width: 'fit-content',
                            fontSize: '13px',
                            color: '#2952A2',
                            border: 'none',
                            textDecoration: 'underline',
                            "&:hover": {
                                border: 'none',
                                backgroundColor: 'white',
                                textDecoration: 'underline',

                            }
                        }}
                        disabled={aiLoading}
                    >
                        {aiLoading ? <CircularProgress size={24} sx={{ color: '#2952A2' }} /> : 'Generate with AI'}
                    </Button>
                </Stack>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack
                            display={'flex'}
                            flexDirection={'row'}
                            gap={5}
                            alignItems={'center'}
                        >
                            <Stack sx={{ width: '100%' }}>
                                <TextField
                                    type='email'
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={contactData?.email}
                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                    sx={{ marginBottom: '15px' }}
                                />
                                <TextField
                                    type='tel'
                                    label="Phone"
                                    variant="outlined"
                                    fullWidth
                                    value={contactData?.phone}
                                    onChange={(e) => handleInputChange('phone', e.target.value)}
                                    sx={{ marginBottom: '15px' }}
                                    inputProps={{
                                        minLength: 10,
                                        maxLength: 10
                                    }}
                                />
                                <TextField
                                    type='text'
                                    label="Address"
                                    variant="outlined"
                                    fullWidth
                                    value={contactData?.address}
                                    onChange={(e) => handleInputChange('address', e.target.value)}
                                    sx={{ marginBottom: '15px' }}
                                />
                                <TextField
                                    type='text'
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    value={contactData?.city}
                                    onChange={(e) => handleInputChange('city', e.target.value)}
                                    sx={{ marginBottom: '15px' }}
                                />
                                <TextField
                                    type='text'
                                    label="Pin code"
                                    variant="outlined"
                                    fullWidth
                                    value={contactData?.pinCode}
                                    onChange={(e) => handleInputChange('pinCode', e.target.value)}
                                    sx={{ marginBottom: '15px' }}
                                    inputProps={{
                                        minLength: 6,
                                        maxLength: 6,
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
            <Button
                variant="contained"
                size="large"
                sx={{
                    textTransform: 'capitalize',
                    borderRadius: '10px',
                    mt: 2,
                    mb: 2,
                    color: '#ffffff',
                    width: '100px',
                    boxShadow: 'none',
                    background:
                        'linear-gradient(140deg, #3C5AA0 13.54%, #467DB4 68.75%, #50A1CA 100%)',
                    '&:hover': {
                        backgroundColor: '#50A1CA',
                        cursor: 'pointer',
                        border: 'none',
                    },
                }}
                onClick={handleSubmit}
                disabled={loader}

            >
                {loader ? <CircularProgress size={24} sx={{ color: '#ffffff' }} /> : "Update"}
            </Button>
        </>
    );
};

export default AddContactUs;
