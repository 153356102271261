
import { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Stack,
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import TrendingAllCommunities from '../AllCommunities/TrendingAllCommunities';
import { useCommunity } from '../../../hooks/useCommunity';
import { ICommunity } from '../../../models/communities.modal';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';
import { AlternativePage } from '../../AlternatePages/AlternativePage';
import NoInvites from "./../../../assets/icons/svg-icons/NoInvitation.png"
import { RotatingLines } from "react-loader-spinner"

interface ITableProps {
    searchQuery: string;
}


const TrendingAllCommunitiesNew = ({ searchQuery }: ITableProps) => {
    const { getTrendingCommunities } = useCommunity();
    const [trendingCommunities, setTrendingCommunities] = useState<ICommunity[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(true);

    useEffect(() => {
        async function fetchData() {
            const trending = await getTrendingCommunities();
            setTimeout(() => {
                setTrendingCommunities(trending?.data || []);
                setShowLoader(false);
            }, 1000);
        }
        fetchData();
    }, [searchQuery]);


    const filteredUsers = trendingCommunities.filter(
        (community) =>
            community?.name?.toLowerCase().includes(searchQuery) ||
            community?.location?.toLowerCase().includes(searchQuery)
    );



    return (
        <>
            <Helmet>
                <title>{metaConfig.title.trendingCommunities}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.author} />
                <meta name="keywords" content={metaConfig.keywords} />
            </Helmet>

            <Stack>
                {showLoader ? (
                    <Stack
                        sx={{
                            color: 'grey.500',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '40vh',
                        }}
                        spacing={2}
                        direction="row"
                    >
                        <RotatingLines
                            visible={true}
                            width="30"
                            strokeWidth="5"
                            strokeColor="#777777"
                            ariaLabel="rotating-lines-loading"
                        />
                    </Stack>
                ) : filteredUsers.length > 0 ? (
                    <Grid container spacing={0}>
                        {filteredUsers.map((item) => (
                            <Grid item key={item._id} xs={6} sm={6} md={1.5} lg={1.5} xl={1.5}>
                                <TrendingAllCommunities {...item} />
                            </Grid>
                        ))}
                    </Grid>

                ) : (
                    <Box sx={{ textAlign: 'center', }}>
                        <AlternativePage title='Not Found ' image={NoInvites} />
                    </Box>
                )}
            </Stack>

        </>
    );
};

export default TrendingAllCommunitiesNew;


