import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
// import CloseIcon from "@mui/icons-material/Close";

export interface IContactCellItem {
  item: string | number;
  isVerified: boolean;
}

export interface IContactCell {
  list: IContactCellItem[];
  [key: string]: any;
}
export const ContactCell = ({ list, ...restProps }: IContactCell) => {
  return (
    <>
      {/* {list.map((d: IContactCellItem, i: number) => (<Stack key={i} direction={"row"} alignItems={"center"} spacing={1}>
            {d.isVerified ? <CheckIcon
                sx={{
                    fontSize: "10px",
                    backgroundColor: "success.light",
                    color: "common.white",
                    borderRadius: "100px",
                    padding: "1px",
                }}
            /> : <CloseIcon
                sx={{
                    fontSize: "10px",
                    backgroundColor: "warning.dark",
                    color: "common.white",
                    borderRadius: "100px",
                    padding: "1px",
                }}
            />}
            <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                {d.item}
            </Typography>
        </Stack>)
        )} */}
      {list.map((d: IContactCellItem, i: number) => (
        <Stack key={i} direction={'row'} alignItems={'center'} spacing={1}>
          <CheckIcon
            sx={{
              fontSize: '10px',
              backgroundColor: 'success.light',
              color: 'common.white',
              borderRadius: '100px',
              padding: '1px',

            }}
          />
          <Typography sx={{ fontWeight: 400, fontSize: '10px', fontFamily: 'Montserrat' }}>
            {d.item}
          </Typography>
        </Stack>
      ))}
    </>
  );
};
